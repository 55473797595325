import React, { useState, useEffect, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useWalletConnection } from "../../../utility/hooks/useWalletConnection";
import { toBeHex } from "ethers";
import {
  setStorage,
  getStorage,
  getApiUser,
} from "../../../pages/store";
import { getLogsPrizeWon } from "../../../pages/Game/store";
import CloseButton from "../../CloseButton/CloseButton";
import Loading from "../../Loading/Loading";
import { formatAddress } from "../../../utility/Utils";
import { hexToArray } from "../../../utility/Utils";
import Spinner from "react-bootstrap/Spinner";
//images
import continuesBgImg from "../../../assets/img/continues-bg.png";
import logo from "../../../assets/img/logo.svg";
import defaultAvatar from "../../../assets/img/user-icon.svg";

const PrizesPopup = ({
  prizesPopupParams,
  setShowWinnerLoserPopup,
  onClose,
}) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const gameId = useSelector((state) => state.gameStore.gameId);

  const {
    isConnected,
    currentChain,
    connectedWalletInfo,
    address,
    chainId,
    wallet,
    signer,
    unSigner,
    contractAbi,
    error,
    errorMessage,
    clearError,
    open,
    connect,
    disConnect,
    fragments,
    getFragment,
    toastArgs,
    setToastArgs,
  } = useWalletConnection();
  const avatarImages = require.context("../../../assets/img/avatars");
  const [seconds, setSeconds] = useState(5);
  const [players, setPlayers] = useState([]);

  const claimPrize = async (claimGameId) => {
    try {
      const isPrizesClaimed = await unSigner.contract.prizesClaimed(
        claimGameId
      );
      console.log("isPrizesClaimed", isPrizesClaimed);
      if (!isPrizesClaimed) {
        const tx = await signer.contract.claimPrize(claimGameId);
        const receipt = await tx.wait();
        console.log("claimPrize (hash):", receipt.hash);
      } else {
        console.log("Game Prize Claimed");
      }
    } catch (error) {
      console.log("claimPrize (error):", error);
    }
    setShowWinnerLoserPopup(true);
    onClose();
  };

  const setPrizesStorage = async (prizeIndex, winnerDetails) => {
    let localWinners = await dispatch(
      getStorage({
        key: `prizes:${gameId}:${prizeIndex}`,
        type: "local",
        currentChain
      })
    ).then((result) => {
      if (result.payload) {
        return result.payload;
      }
    });

    // console.log("localWinners (b):", localWinners);

    if (!localWinners) {
      dispatch(
        setStorage({
          key: `prizes:${gameId}:${prizeIndex}`,
          value: [winnerDetails],
          type: "local",
          currentChain
        })
      );
    } else {
      if (!localWinners.find((item) => item.player === winnerDetails.player)) {
        localWinners.push(winnerDetails);
        dispatch(
          setStorage({
            key: `prizes:${gameId}:${prizeIndex}`,
            value: localWinners,
            type: "local",
            currentChain
          })
        );
      }
    }

    // await dispatch(
    //   getStorage({
    //     key: `prizes:${gameId}:${prizeIndex}`,
    //     type: "local",
    //     currentChain
    //   })
    // ).then((result) => {
    //   if (result.payload) {
    //     console.log("localWinners:", result.payload);
    //   }
    // });
  };

  const setJams = async (_winnerDetails) => {
    const allCards = await unSigner.contract.getCards();

    for (let i = 0; i < _winnerDetails.winners.length; i++) {
      let cardIndex = null;
      allCards.forEach((card, index) => {
        if (card === _winnerDetails.winners[i].card) {
          cardIndex = index;
        }
      });

      const apiUser = await dispatch(
        getApiUser({ address: _winnerDetails.winners[i].player })
      ).then((result) => {
        return result.payload;
      });

      const playerObj = {
        addr: _winnerDetails.winners[i].player,
        avatar: apiUser?.status === 200 ? apiUser?.avatar : defaultAvatar,
        userName:
          apiUser?.status === 200 ? apiUser?.userName : "User not registered",
        cardIndex,
        card: hexToArray(toBeHex(_winnerDetails.winners[i].card)),
        hash: _winnerDetails.winners[i].hash,
      };

      setPlayers((oldArry) => [...oldArry, playerObj]);
      await setPrizesStorage(Number(_winnerDetails.prizeIndex), {
        player: _winnerDetails.winners[i].player,
        card: _winnerDetails.winners[i].card,
        hash: _winnerDetails.winners[i].hash,
      });
    }
  };

  const setJammy = async () => {
    dispatch(
      getLogsPrizeWon({
        dispatch,
        unSigner,
        currentChain,
        gameId,
      })
    )
      .then(async (result) => {
        if (!result.payload.errors) {
          console.log(result.payload.eventLogs);
          const allCards = await unSigner.contract.getCards();

          const jammyPrizeWons = result.payload.eventLogs.filter(
            (eLog) =>
              eLog.eventName === "PrizeWon" &&
              Number(eLog.args.gameId) === gameId &&
              Number(eLog.args.prizeIndex) === 0
          );

          for (let i = 0; i < jammyPrizeWons.length; i++) {
            let cardIndex = null;
            allCards.forEach((card, index) => {
              if (card === jammyPrizeWons[i].args.winnerCard) {
                cardIndex = index;
              }
            });

            const apiUser = await dispatch(
              getApiUser({ address: jammyPrizeWons[i].args.winner })
            ).then((result) => {
              return result.payload;
            });

            const playerObj = {
              addr: jammyPrizeWons[i].args.winner,
              avatar: apiUser?.status === 200 ? apiUser?.avatar : defaultAvatar,
              userName:
                apiUser?.status === 200
                  ? apiUser?.userName
                  : "User not registered",
              cardIndex,
              card: hexToArray(toBeHex(jammyPrizeWons[i].args.winnerCard)),
              hash: jammyPrizeWons[i].transactionHash,
            };
            setPlayers((oldArry) => [...oldArry, playerObj]);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (!prizesPopupParams) return;
    if (!(Number(prizesPopupParams.prizeIndex) >= 0)) return;
    console.log("prizesPopupParams:", prizesPopupParams);

    if (Number(prizesPopupParams.prizeIndex) > 0) setJams(prizesPopupParams);
    if (Number(prizesPopupParams.prizeIndex) === 0) setJammy();
  }, [prizesPopupParams]);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds === 0) {
        clearInterval(countdown);
        if (prizesPopupParams && Number(prizesPopupParams.prizeIndex) !== 0) {
          onClose();
        }
        return;
      }
      setSeconds(seconds - 1);
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [seconds, prizesPopupParams]);

  return (
    <div className="popup-wrapper continues-wrapper">
      <CloseButton
        onClose={onClose}
        to={search}
        extraAction={{
          type: "claimPrize",
          action:
            Number(prizesPopupParams.prizeIndex) === 0
              ? () => claimPrize(gameId)
              : undefined,
        }}
      />
      <div className="in">
        <div className="text-top-area continues-title">
          The
          {prizesPopupParams
            ? Number(prizesPopupParams.prizeIndex) === 4
              ? " 1st Jam "
              : Number(prizesPopupParams.prizeIndex) === 3
              ? " 2nd Jam "
              : Number(prizesPopupParams.prizeIndex) === 2
              ? " 3rd Jam "
              : Number(prizesPopupParams.prizeIndex) === 1
              ? " 4th Jam "
              : Number(prizesPopupParams.prizeIndex) === 0
              ? " JAMMY "
              : null
            : null}
          Goes to
        </div>
        <div className="card-bottom-area">
          <div className="card-container">
            <div className="item-card-area">
              {players.length > 0 ? (
                players.map((player, index) => (
                  <Fragment key={index}>
                    <div className="info-box-wrapper">
                      <div className="profile-info-box">
                        <div className="img">
                          <img src={player.avatar} alt={player.userName} />
                        </div>
                        <Link
                          target="blank"
                          to={`${currentChain?.blockExplorers.default.url}/address/${player.addr}`}
                          title={`View ${player.userName} on ${currentChain?.blockExplorers.default.name}`}
                        >
                          <div className="right">
                            <span>{player.userName}</span>
                            <small>Wallet: {formatAddress(player.addr)}</small>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <Link
                      target="blank"
                      to={`${currentChain?.blockExplorers.default.url}/tx/${player.hash}`}
                      title={`View ${
                        Number(prizesPopupParams.prizeIndex) === 4
                          ? "1st Jam"
                          : Number(prizesPopupParams.prizeIndex) === 3
                          ? "2nd Jam"
                          : Number(prizesPopupParams.prizeIndex) === 2
                          ? "3rd Jam"
                          : Number(prizesPopupParams.prizeIndex) === 1
                          ? "4th Jam"
                          : Number(prizesPopupParams.prizeIndex) === 0 &&
                            "JAMMY"
                      } Txn on ${currentChain?.blockExplorers.default.name}`}
                    >
                      <div className="item-card" style={{ padding: "8px 32px" }}>
                        <span>{player.cardIndex}</span>
                        <div className="in" style={{ margin: "25px 0" }}>
                          {player.card.map((cardNum, cardNumIndex) => (
                            <div
                              className={
                                cardNum === 0 ? "item revealed" : "item"
                              }
                              key={cardNumIndex}
                            >
                              {cardNum !== 0 ? cardNum : null}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Link>
                  </Fragment>
                ))
              ) : (
                <div>
                  <div className="info-box-wrapper">
                    <div className="profile-info-box">
                      <Spinner animation="border"></Spinner>
                    </div>
                  </div>
                  <div
                    className="item-card"
                    style={{ marginRight: "15px", padding: "90px" }}
                  >
                    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                      <img src={logo} alt="" />
                      <Loading text="Loading card..." />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="right-area">
              {prizesPopupParams &&
              Number(prizesPopupParams.prizeIndex) !== 0 ? (
                <div className="text">
                  <span>The Game Continues!</span>
                  <small>Roll up your sleeves to be the next!</small>
                </div>
              ) : (
                <div className="text">
                  <span>Buddy, The Game is Over!</span>
                  <small>Let's see the bucks for the new game!</small>
                  <Link
                    to={search}
                    className="right-btn-o"
                    onClick={() => claimPrize(gameId)}
                  >
                    {" "}
                    OKAY
                  </Link>
                </div>
              )}
            </div>
          </div>
          {prizesPopupParams && Number(prizesPopupParams.prizeIndex) !== 0 ? (
            <span style={{ float: "right", paddingRight: "8%" }}>
              Close in ({seconds}) sec.
            </span>
          ) : null}
        </div>
        <img src={continuesBgImg} className="popup-bg" alt="" />
      </div>
    </div>
  );
};

export default PrizesPopup;
