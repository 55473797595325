import React, { useState, useEffect } from "react";
import CloseButton from "../CloseButton/CloseButton";
import { Link, useLocation } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useWalletConnection } from "../../utility/hooks/useWalletConnection";
import { formatAddress } from "../../utility/Utils";
import {
  toBigInt,
  formatEther,
  id,
  zeroPadValue,
  Interface,
  getBigInt,
  parseEther,
} from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { getEventLogs } from "../../pages/store";
// import { checkGamePrizes } from "../../pages/Game/store";
import Loading from "../Loading/Loading";
import AvatarSelect from "../AvatarSelect/AvatarSelect";
//images
import btnBgImg from "../../assets/img/btn-bg.png";
import btnBgActiveImg from "../../assets/img/btn-bg-active.png";
import profilePopupImg from "../../assets/img/ProfilePopup.png";
import twitter2Img from "../../assets/img/twitter-icon-2.svg";
import joinBtn2Img from "../../assets/img/join-btn-2.png";
import visitBtnImg from "../../assets/img/visit-btn.svg";
import inviteImg from "../../assets/img/invite.png";
import tooltipBgImg from "../../assets/img/tooltip-click-bg.png";
import staticsImg from "../../assets/img/statics-shape.png";
//TODO : imageler değişecek
import totalJams1Img from "../../assets/img/total-jams.png";
import totalJams2Img from "../../assets/img/total-jams2.png";
import cardImg from "../../assets/img/card-icon.png";
import maticWonImg from "../../assets/img/matic-won.png";
import winnigImg from "../../assets/img/winnig.png";
import completedImg from "../../assets/img/completed-icon.png";
import leaderboardImg from "../../assets/img/leaderboard-icon.png";
import gameShapeImg from "../../assets/img/game-shape.png";
import createdImg from "../../assets/img/created.png";
import potImg from "../../assets/img/pot.png";
import peopleImg from "../../assets/img/people.png";
import created2Img from "../../assets/img/created2.png";
import starImg from "../../assets/img/stars-icon.png";
import rankRightNewby from "../../assets/img/ranks-right-icon-1.png";
import rankRightJunior from "../../assets/img/ranks-right-icon-5.png";
import rankRightPro from "../../assets/img/ranks-right-icon-4.png";
import rankRightElite from "../../assets/img/ranks-right-icon-3.png";
import rankRightSenior from "../../assets/img/ranks-right-icon-2.png";
import rankRightMaster from "../../assets/img/ranks-right-icon-6.png";
import rankRightLegendary from "../../assets/img/ranks-right-icon-7.png";
import rankRightAce from "../../assets/img/ranks-right-icon-8.png";
import rankNewby from "../../assets/img/ranks-icon-1.png";
import rankJunior from "../../assets/img/ranks-icon-2.png";
import rankPro from "../../assets/img/ranks-icon-3.png";
import rankElite from "../../assets/img/ranks-icon-4.png";
import rankSenior from "../../assets/img/ranks-icon-5.png";
import rankMaster from "../../assets/img/ranks-icon-6.png";
import rankLegendary from "../../assets/img/ranks-icon-7.png";
import rankAce from "../../assets/img/ranks-icon-8.png";
import profileBgImg from "../../assets/img/profile-join-bg.png";
import menuArrowImg from "../../assets/img/connectwallet/menuArrow.png";
import menuArrowHoverImg from "../../assets/img/connectwallet/menuArrowHover.png";
import menuBgImg from "../../assets/img/connectwallet/menuBg.png";
import disconnectImg from "../../assets/img/connectwallet/disconnect.png";
import disconnectHoverImg from "../../assets/img/connectwallet/disconnectHover.png";
import profileDetailsImg from "../../assets/img/connectwallet/profileDetails.png";
import profileDetailsHoverImg from "../../assets/img/connectwallet/profileDetailsHover.png";
import twitterImg from "../../assets/img/connectwallet/twitter.png";
import twitterHoverImg from "../../assets/img/connectwallet/twitterHover.png";
import webImg from "../../assets/img/connectwallet/web.png";
import webHoverImg from "../../assets/img/connectwallet/webHover.png";
import discordImg from "../../assets/img/connectwallet/discord.png";
import discordHoverImg from "../../assets/img/connectwallet/discordHover.png";
import seperatorImg from "../../assets/img/connectwallet/separator.png";
import defaultAvatar from "../../assets/img/user-icon.svg";
import deployerAvatar from "../../assets/img/avatars/avatar3.png";
import adminAvatar from "../../assets/img/avatars/avatar2.png";
import hostAvatar from "../../assets/img/avatars/avatar1.png";

// css
import "./UserProfile.css";

const testRanksList = [
  {
    name: "NEWBY",
    minLevel: 1,
    maxLevel: 2000,
  },
  {
    name: "JUNIOR",
    minLevel: 2001,
    maxLevel: 4000,
  },
  {
    name: "PRO",
    minLevel: 4001,
    maxLevel: 6000,
  },
  {
    name: "ELITE",
    minLevel: 6001,
    maxLevel: 8000,
  },
  {
    name: "SENIOR",
    minLevel: 8001,
    maxLevel: 10000,
  },
  {
    name: "MASTER",
    minLevel: 10001,
    maxLevel: 12000,
  },
  {
    name: "LEGENDARY",
    minLevel: 12001,
    maxLevel: 15000,
  },
  {
    name: "ACE",
    minLevel: 15001,
    maxLevel: 20000,
  },
];

//TODO: açılmasada başlangıçta render oluyor engellenecek
const UserProfile = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const {
    isConnected,
    currentChain,
    connectedWalletInfo,
    address,
    chainId,
    wallet,
    setWallet,
    signer,
    unSigner,
    contractAbi,
    error,
    errorMessage,
    clearError,
    open,
    connect,
    disConnect,
    fragments,
    getFragment,
    toastArgs,
    setToastArgs,
  } = useWalletConnection();

  const gameTypes = useSelector((state) => state.rootStore.gameTypes);
  const apiCurrentUser = useSelector((state) => state.rootStore.apiCurrentUser);
  const [activeLink, setActiveLink] = useState(0);
  const [profileOpen, setProfileOpen] = useState(false);
  const [AvatarSelectOpen, setAvatarSelectOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const topLinks = ["PROFILE", "STATISTICS", "GAME HASH", "RANKS"];

  // const [userRank, setUserRank] = useState("");
  const [ranks, setRanks] = useState(testRanksList);

  const [gameHashList, setGameHashList] = useState([]); //oyuncunun katıldığı tüm oyunlar
  const [gameHashListLoading, setGameHashListLoading] = useState(false);
  const [gameDetail, setGameDetail] = useState([]);
  const [gameHashWinners, setGameHashWinners] = useState([]);
  const [gameHashDetailLoading, setgameHashDetailLoading] = useState(false);

  // istatisik state'leri
  const [totalJams, setTotalJams] = useState(0);
  const [totalJammys, setTotalJammys] = useState(0);
  const [cardsPlayed, setCardsPlayed] = useState(0);
  const [totalWon, setTotalWon] = useState(0);
  const [winningPerC, setWinningPerC] = useState(0);

  const claimRefund = async (refundGameId) => {
    try {
      const tx = await signer.contract.claimRefund(refundGameId);
      const receipt = await tx.wait();
      console.log("receipt-claimRefund:", receipt);
      const eLog = receipt.logs.find(
        (eventLog) => eventLog.eventName === "RefundSent"
      );
      alert(
        // `${formatEther(BigNumber.from(receipt.events[0].args.amount))} ${
        //   currentChain?.nativeCurrency.symbol
        // } refunded.` //TODO : Popup yapılacak
        `${formatEther(toBigInt(eLog.args.amount))} ${
          currentChain?.nativeCurrency.symbol
        } refunded.` //TODO : Popup yapılacak
      );
      if (fragments) fetchUserGameList();
    } catch (error) {
      console.log(error);
    }
  };

  const claimPrize = async (prizeGameId) => {
    try {
      const tx = await signer.contract.claimPrize(prizeGameId);
      const receipt = await tx.wait();
      console.log("receipt-claimPrize:", receipt);
      alert(
        `Prizes claimed.` //TODO : Popup yapılacak
      );
      if (fragments) fetchUserGameList();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserGameList = async () => {
    //TODO: Block aralıkları geldikçe render edilecek. bütün oyunların gelip render edilmesi oyun sayısı arttıkça bekleme süresi uzuyor.
    //cancelled, expired and ended games
    if (!unSigner.contract) return;
    if (!wallet.accounts.length > 0) return;

    setGameHashList([]);
    setGameDetail([]);
    setGameHashListLoading(true);
    setgameHashDetailLoading(true);

    //TODO: total oyun sayı üzerinden sayfalama yapılarak liste oluşturulacak
    //Notice: Topic-sets: https://docs.ethers.org/v5/concepts/events/#events--filters
    const filters = [
      "GameCreated",
      "PlayerJoined",
      "GameCancelled",
      "RefundSent",
      "GameEnds",
      "PrizeCollected",
    ];
    const iface = new Interface(contractAbi.abi);

    const res = (
      await dispatch(
        getEventLogs({ unSigner, currentChain, filters: [filters] })
      )
    ).payload;
    // console.log(res);

    if (!res.eventLogs.errors) {
      let joinedGameIds = [];
      res.eventLogs
        .filter(
          (item) =>
            item.eventName === "PlayerJoined" &&
            item.args.player.toLowerCase() === wallet.accounts[0].toLowerCase()
        )
        .forEach((event) => {
          joinedGameIds.push(Number(event.args.gameId));
        });
      // console.log("joinedGameIds:", joinedGameIds);

      const allEventJoinedFiltered = res.eventLogs.filter(({ args }) =>
        joinedGameIds.find((id) => id === Number(args.gameId))
      );
      const eventGameCreated = allEventJoinedFiltered.filter(
        (item) => item.eventName === "GameCreated"
      );
      const eventGameCancelled = allEventJoinedFiltered.filter(
        (item) => item.eventName === "GameCancelled"
      );
      const eventRefundSent = allEventJoinedFiltered.filter(
        (item) => item.eventName === "RefundSent"
      );
      const eventGameEnds = allEventJoinedFiltered.filter(
        (item) => item.eventName === "GameEnds"
      );
      const eventPrizeCollected = allEventJoinedFiltered.filter(
        (item) => item.eventName === "PrizeCollected"
      );

      // console.log({
      //   allEventJoinedFiltered,
      //   eventGameCreated,
      //   eventGameCancelled,
      //   eventRefundSent,
      //   eventGameEnds,
      //   eventPrizeCollected,
      // });

      let games = [];
      const expDuration = Number(await unSigner.contract.EXPIRATION_DURATION());
      for (let i in eventGameCreated) {
        const res = eventGameCreated[i];
        // console.log(res);

        try {
          const tx = await res.getTransaction();
          // console.log(tx);
          // const inputData = iface.decodeFunctionData("createGame(uint256,uint256,uint256,uint256,uint256[])", tx.data);
          const inputData = iface.parseTransaction({
            data: tx.data,
            value: tx.value,
          });
          if (inputData && inputData.name === "createGame") {
            //...
          } else {
            //TODO: ilgili event tx i farklı geldiği oldu istisna olabilir. durum takip edilecek
            console.error(">>> error tx - gameId", Number(res.args.gameId));
          }
        } catch (err) {
          console.log("inputData err:", err);
        }

        // if (inputData && inputData.name === "createGame") {
        let isRefundsOrClaimed = true;
        let gameStatus = eventGameEnds.find(
          ({ args }) => Number(args.gameId) === Number(res.args.gameId)
        )
          ? "Ended"
          : eventGameCancelled.find(
              ({ args }) => Number(args.gameId) === Number(res.args.gameId)
            )
          ? "Cancelled"
          : Math.floor(Date.now() / 1000) >
            // Number(inputData.args.startDate) + expDuration
            Number(res.args.startDate) + expDuration
          ? "Expired"
          : null;

        if (gameStatus === "Cancelled" || gameStatus === "Expired") {
          isRefundsOrClaimed = !eventRefundSent.find(
            ({ args }) => Number(args.gameId) === Number(res.args.gameId)
          )
            ? false
            : true;
        } else if (gameStatus === "Ended") {
          isRefundsOrClaimed = !eventPrizeCollected.find(
            ({ args }) => Number(args.gameId) === Number(res.args.gameId)
          )
            ? false
            : true;
        }

        if (gameStatus) {
          games.push({
            gameId: Number(res.args.gameId),
            gameStatus,
            isRefundsOrClaimed,
            eLog: res,
            transactionHash: res.transactionHash,
            startDate: res.args.startDate,
            cardPrice: res.args.cardPrice,
          });
        }
        // } else {
        //   console.error(">>> error tx - gameId", Number(res.args.gameId));
        // }
      }

      console.log(
        "games",
        games.sort((a, b) => b.gameId - a.gameId)
      );

      setGameHashList(games.sort((a, b) => b.gameId - a.gameId));
    }

    setGameHashListLoading(false);
    setgameHashDetailLoading(false);
  };

  //TODO: Eksik alanlar tamamlanacak
  const fetchGameDetail = async (game) => {
    setgameHashDetailLoading(true);
    setGameHashWinners([]);
    // const iface = new Interface(contractAbi.abi);
    // const tx = await game.log.getTransaction();
    // console.log(
    //   "asd:",
    //   iface.parseTransaction({ data: tx.data, value: tx.value })
    // );

    // dispatch(
    //   checkGamePrizes({
    //     dispatch,
    //     unSigner,
    //     gameId: game.gameId,
    //     user: wallet.accounts[0].toLowerCase(),
    //   })
    // )
    //   .then(async (result) => {
    //     if (result.payload) {
    //       console.log(result.payload);
    //       let winners = []; // tekrarı önlemek için
    //       result.payload.forEach((prize) => {
    //         // prize.winners &&
    //         //   prize.winners.forEach(async (winner) => {
    //         //     //tüm oyuncular tüm ödüller
    //         //     winners.push(winner);
    //         //     setGameHashWinners((oldArray) => [
    //         //       ...oldArray,
    //         //       { winner, prizeIndex: prize.prizeIndex },
    //         //     ]);
    //         //   });
    //         // prize.winners.forEach(async (winner) => {
    //         //   //sadece herhangi bir ödül kazanan oyuncular
    //         //   if (
    //         //     !winners.find(
    //         //       (arryWinner) =>
    //         //         arryWinner.toLowerCase() ===
    //         //         winner.toLowerCase()
    //         //     )
    //         //   ) {
    //         //     winners.push(winner);
    //         //     setGameHashWinners((oldArray) => [
    //         //       ...oldArray,
    //         //       { winner, prizeIndex: 5 },
    //         //     ]);
    //         //   }
    //         // });
    //         prize.winners.forEach(async (winner) => {
    //           //bağlı kullanıcının ilgili oyunda kazandığı ödüller
    //           if (winner.toLowerCase() === wallet.accounts[0].toLowerCase()) {
    //             winners.push(winner);
    //             setGameHashWinners((oldArray) => [
    //               ...oldArray,
    //               { winner, prizeIndex: prize.prizeIndex },
    //             ]);
    //           }
    //         });
    //       });
    //       return winners;
    //     }
    //   })
    //   .catch((error) => console.log(error));

    console.log("detailgame game:", game, gameTypes);

    let gameTypeName = null;
    if (gameTypes && gameTypes.length > 0) {
      gameTypes.forEach((item) => {
        if (Number(item.price) === Number(formatEther(game.cardPrice))) {
          gameTypeName = item.name;
        }
      });
    }

    const contractGame = await unSigner.contract.games(game.gameId);
    const reGame = {
      ...game,
      totalPlayerCount: contractGame.totalPlayerCount,
      totalCardsSold: contractGame.totalCardsSold,
      gameTypeName,
    };
    console.log("detailgame reGame:", reGame);
    setGameDetail([reGame]);
    setgameHashDetailLoading(false);
  };

  // TOTAL JAMMY - JAMS
  const setJamsJammys = async (eLogs, gameIds) => {
    let filterPrizeWon = [];

    eLogs.forEach((event) => {
      if (event.eventName === "PrizeWon") filterPrizeWon.push(event);
    });

    const jams = filterPrizeWon.filter(
      ({ args }) =>
        gameIds.find((id) => id === Number(args.gameId)) &&
        args.winner.toLowerCase() === wallet.accounts[0].toLowerCase() &&
        args.prizeIndex > 0
    );
    const jammys = filterPrizeWon.filter(
      ({ args }) =>
        gameIds.find((id) => id === Number(args.gameId)) &&
        args.winner.toLowerCase() === wallet.accounts[0].toLowerCase() &&
        Number(args.prizeIndex) === 0
    );

    // console.log("jams", jams);
    // console.log("jammys", jammys);
    setTotalJams(jams.length);
    setTotalJammys(jammys.length);
    return jams.concat(jammys); //all events
  };

  // TOTAL CARD PLAYED - WİNNİNG PERCANTAGE
  const setCardsPlayed_WinPer = async (eLogs, jamJammyEvents, gameIds) => {
    let filterPlayerJoined = [];
    let winGames = [];
    for (let i = 0; i < jamJammyEvents.length; i++) {
      let value = Number(jamJammyEvents[i].args.gameId);
      if (winGames.indexOf(value) === -1) winGames.push(value); //benzer idler temizlenir
    }
    // console.log("winGames", winGames, winGames.length);

    eLogs.forEach((event) => {
      if (event.eventName === "PlayerJoined") filterPlayerJoined.push(event);
    });
    let playedtotalCards = 0;

    const joinedGames = filterPlayerJoined.filter(
      ({ args }) =>
        gameIds.find((id) => id === Number(args.gameId)) &&
        args.player.toLowerCase() === wallet.accounts[0].toLowerCase()
    );
    // console.log("joinedGames", joinedGames, joinedGames.length);

    joinedGames.forEach((event) => {
      playedtotalCards += Number(event.args.cardsCount);
    });

    setCardsPlayed(playedtotalCards);
    setWinningPerC(
      joinedGames.length > 0
        ? (winGames.length / joinedGames.length).toFixed(2)
        : 0
    );
  };

  //TODO: Fonksiyon tamamlanacak
  const totalWonNative = async (eLogs, jamJammyEvents) => {
    /**
     * https://ethereum.stackexchange.com/questions/3417/how-to-get-contract-internal-transactions
     * https://github.com/ethers-io/ethers.js/issues/167
     * https://docs.alchemy.com/reference/debug-tracetransaction
     * TODO: (Araştırılacak): alchemy ve arb,bsc de destekleniyor. Farklı sağlayıcılar veya networklerde desteklenmeyebilir.
     */

    let totalValues = [];
    let _totalWon = 0;
    let filterPrizeCollected = [];
    eLogs.forEach((event) => {
      if (event.eventName === "PrizeCollected")
        filterPrizeCollected.push(event);
    });

    for (const i in filterPrizeCollected) {
      const item = filterPrizeCollected[i];
      const internalTxn = await unSigner.provider.send(
        "debug_traceTransaction",
        [item.transactionHash, { tracer: "callTracer" }]
      );
      // console.log(
      //   i,
      //   item.args.gameId,
      //   item.args.totalAmount,
      //   internalTxn.calls
      // );

      let _totalCallValue = 0;
      internalTxn.calls.forEach((call) => {
        if (call.to.toLowerCase() === wallet.accounts[0]) {
          console.log(
            call.from,
            ">",
            call.to,
            ":",
            Number(call.value),
            getBigInt(call.value),
            formatEther(call.value)
          );
          _totalCallValue = _totalCallValue + Number(call.value);
        }
      });
      totalValues.push({
        gameId: Number(item.args.gameId),
        totalWon: _totalCallValue,
      });
      _totalWon = _totalWon + _totalCallValue;
    }
    // console.log(totalValues, _totalWon);
    setTotalWon(_totalWon)
    rankSettings(testRanksList, _totalWon);
  };

  // rankSettings
  const rankSettings = async (testRanksList, totalWon) => {
    //TODO: kullanıcı leveli kazanılan ödüle göre nasıl belirleneceği kesinleştirilecek.
    totalWon = Math.floor(totalWon / 1000000000000).toFixed(0);
    // console.log(totalWon);
    
    if (
      Number(totalWon) >= testRanksList[0].minLevel &&
      Number(totalWon) <= testRanksList[0].maxLevel
    ) {
      setWallet({ ...wallet, rank: testRanksList[0].name });
    }
    if (
      Number(totalWon) >= testRanksList[1].minLevel &&
      Number(totalWon) <= testRanksList[1].maxLevel
    ) {
      setWallet({ ...wallet, rank: testRanksList[1].name });
    }
    if (
      Number(totalWon) >= testRanksList[2].minLevel &&
      Number(totalWon) <= testRanksList[2].maxLevel
    ) {
      setWallet({ ...wallet, rank: testRanksList[2].name });
    }
    if (
      Number(totalWon) >= testRanksList[3].minLevel &&
      Number(totalWon) <= testRanksList[3].maxLevel
    ) {
      setWallet({ ...wallet, rank: testRanksList[3].name });
    }
    if (
      Number(totalWon) >= testRanksList[4].minLevel &&
      Number(totalWon) <= testRanksList[4].maxLevel
    ) {
      setWallet({ ...wallet, rank: testRanksList[4].name });
    }
    if (
      Number(totalWon) >= testRanksList[5].minLevel &&
      Number(totalWon) <= testRanksList[5].maxLevel
    ) {
      setWallet({ ...wallet, rank: testRanksList[5].name });
    }
    if (
      Number(totalWon) >= testRanksList[6].minLevel &&
      Number(totalWon) <= testRanksList[6].maxLevel
    ) {
      setWallet({ ...wallet, rank: testRanksList[6].name });
    }
    if (
      Number(totalWon) >= testRanksList[7].minLevel &&
      Number(totalWon) <= testRanksList[7].maxLevel
    ) {
      setWallet({ ...wallet, rank: testRanksList[7].name });
    }
  };

  useEffect(() => {
    if (gameHashList.length > 0) {
      fetchGameDetail(gameHashList[0]);
    }
  }, [gameHashList]);

  useEffect(() => {
    if (!unSigner.contract) return;
    if (!wallet.accounts.length > 0) return;

    async function getStatistics() {
      let gameIds = [];

      const filters = [
        // await getFragment("event", "GameEnds")?.topicHash,
        // await getFragment("event", "PrizeWon")?.topicHash,
        // await getFragment("event", "PlayerJoined")?.topicHash,
        // await getFragment("event", "PrizeCollected")?.topicHash
        "GameEnds",
        "PrizeWon",
        "PlayerJoined",
        "PrizeCollected",
      ];
      const res = (
        await dispatch(
          getEventLogs({ unSigner, currentChain, filters: [filters] })
        )
      ).payload;
      // console.log(res);

      if (!res.eventLogs.errors) {
        res.eventLogs.forEach((event) => {
          if (event.eventName === "GameEnds")
            gameIds.push(Number(event.args.gameId));
        });
        // console.log({ gameIds });

        const jamJammyEvents = await setJamsJammys(res.eventLogs, gameIds);
        setCardsPlayed_WinPer(res.eventLogs, jamJammyEvents, gameIds);
        totalWonNative(res.eventLogs, jamJammyEvents);
      }
    }

    getStatistics();
    // if (wallet.totalWon) {
    //   rankSettings(testRanksList, wallet.totalWon);
    // }
  }, [unSigner.contract, wallet.accounts]);

  return (
    <>
      {/* CONNECT WALLET */}
      <div className="join-user-info col-4 col-xl-3">
        {apiCurrentUser && apiCurrentUser.status === 200 ? (
          <img
            className="profile-img"
            src={apiCurrentUser.avatar}
            alt={apiCurrentUser.userName}
          />
        ) : signer.isDeployer ? (
          <img className="profile-img" src={deployerAvatar} alt="Deployer" />
        ) : signer.isAdmin ? (
          <img className="profile-img" src={adminAvatar} alt="Admin" />
        ) : signer.isHost ? (
          <img className="profile-img" src={hostAvatar} alt="Host" />
        ) : (
          <h1
            className="profile-img"
            style={{ textAlign: "center", marginTop: "2px" }}
          >
            ?
          </h1>
        )}
        <img src={profileBgImg} className="bg" alt="" />

        {wallet.accounts.length < 1 && (
          <div
            className="text"
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => connect()}
          >
            <div className="sub-title">Connect Wallet</div>
          </div>
        )}

        {isConnected && wallet.accounts.length > 0 && (
          <>
            <div className="text" onClick={() => setMenuOpen(!menuOpen)}>
              <>
                {apiCurrentUser && apiCurrentUser.status === 200 ? (
                  <div className="sub-title">{apiCurrentUser.userName}</div>
                ) : signer.isDeployer ? (
                  <div className="sub-title" style={{ color: "black" }}>
                    DEPLOYER
                  </div>
                ) : signer.isAdmin ? (
                  <div className="sub-title" style={{ color: "blue" }}>
                    ADMIN
                  </div>
                ) : signer.isHost ? (
                  <div className="sub-title" style={{ color: "yellow" }}>
                    HOST
                  </div>
                ) : (
                  <div className="sub-title" style={{ color: "red" }}>
                    Please sign up!
                  </div>
                )}
              </>
              <div className="wallet-id" id="disconn">
                {formatAddress(wallet.accounts[0])}
              </div>
            </div>
            <div
              className="current-network"
              onClick={() => open({ view: "Networks" })}
              title="Show other networks"
            >
              {!menuOpen && `Network: ${currentChain.name}`}
            </div>
            <div className="wallet-arrow">
              <img
                className="arrow"
                style={menuOpen ? { rotate: "-180deg" } : { rotate: "0deg" }}
                alt=""
                src={menuArrowImg}
                onMouseOver={(e) => (e.currentTarget.src = menuArrowHoverImg)}
                onMouseOut={(e) => (e.currentTarget.src = menuArrowImg)}
                onClick={() => setMenuOpen(!menuOpen)}
              />
            </div>
            <div
              className="wallet-menu"
              style={
                menuOpen
                  ? { visibility: "visible", opacity: 1 }
                  : { visibility: "hidden", opacity: 0 }
              }
            >
              <img className="menu-bg" alt="" src={menuBgImg} />
              <div className="menu-in">
                <img
                  className="el"
                  alt=""
                  src={profileDetailsImg}
                  onMouseOver={(e) =>
                    (e.currentTarget.src = profileDetailsHoverImg)
                  }
                  onMouseOut={(e) => (e.currentTarget.src = profileDetailsImg)}
                  onClick={() =>
                    apiCurrentUser && apiCurrentUser.status === 200
                      ? setProfileOpen(true)
                      : (window.location.href = "/")
                  } //TODO: daha sonra açılacak
                />
                {/* TODO : Disconnect olayı metamaske yazılacak onClick eventi olarak eklenecek! */}
                <img
                  className="el"
                  alt=""
                  src={disconnectImg}
                  onMouseOver={(e) =>
                    (e.currentTarget.src = disconnectHoverImg)
                  }
                  onMouseOut={(e) => (e.currentTarget.src = disconnectImg)}
                  onClick={() => disConnect()}
                />
                {/* TODO : social linkler güncellenecek! */}
                <div className="menu-social-area">
                  <img
                    className="pad3"
                    alt=""
                    src={webImg}
                    onMouseOver={(e) => (e.currentTarget.src = webHoverImg)}
                    onMouseOut={(e) => (e.currentTarget.src = webImg)}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://google.com", "_blank");
                    }}
                  />
                  <img alt="" src={seperatorImg} />
                  <img
                    className="pad3"
                    alt=""
                    src={twitterImg}
                    onMouseOver={(e) => (e.currentTarget.src = twitterHoverImg)}
                    onMouseOut={(e) => (e.currentTarget.src = twitterImg)}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://twitter.com", "_blank");
                    }}
                  />
                  <img alt="" src={seperatorImg} />
                  <img
                    className="pad3"
                    alt=""
                    src={discordImg}
                    onMouseOver={(e) => (e.currentTarget.src = discordHoverImg)}
                    onMouseOut={(e) => (e.currentTarget.src = discordImg)}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open("https://discord.com", "_blank");
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {profileOpen && (
        <div className="popup-profile popup">
          <CloseButton
            onClose={() => setProfileOpen(!profileOpen)}
            to={search}
          />
          <div className="in">
            {/* TOP MENU */}
            <div className="top-links">
              {topLinks.map((link, index) => {
                return (
                  <Link
                    to={search}
                    key={index}
                    onClick={() => {
                      setActiveLink(index);
                      if (
                        index === 2 &&
                        gameHashList.length === 0 &&
                        fragments
                      ) {
                        fetchUserGameList();
                      }
                    }}
                    className={activeLink === index ? "active" : ""}
                  >
                    <img src={btnBgImg} alt="" />
                    <img className="activeBg" src={btnBgActiveImg} alt="" />
                    <span>{link}</span>
                  </Link>
                  // )
                );
              })}
            </div>
            <img src={profilePopupImg} className="w-100" alt="" />

            {/* PROFILE */}
            <div
              className="box-inner"
              style={
                activeLink === 0 ? { display: "block" } : { display: "none" }
              }
            >
              <div className="profil-image">
                <button
                  className="select"
                  // onClick={() => setAvatarSelectOpen(true)}
                >
                  SELECT
                </button>
                <img
                  src={
                    apiCurrentUser && apiCurrentUser.status === 200
                      ? apiCurrentUser.avatar
                      : defaultAvatar
                  }
                  alt={
                    apiCurrentUser && apiCurrentUser.status === 200
                      ? apiCurrentUser.userName
                      : "defaultAvatar"
                  }
                />
                {/* <div className="profile-change-buttons">
                  <Link to={search} className="prev-btn">
                    <img src={prevImg} alt="" />
                  </Link>
                  <Link to={search} className="next-btn">
                    <img src={nextImg} alt="" />
                  </Link>
                </div> */}
              </div>
              <div className="text-area">
                <div className="item-row">
                  <div className="col-name">USERNAME:</div>
                  <div className="col-right">
                    {apiCurrentUser &&
                      apiCurrentUser.status === 200 &&
                      apiCurrentUser.userName}
                  </div>
                </div>
                <div className="item-row">
                  <div className="col-name">WALLET ID:</div>
                  <div className="col-right">
                    {formatAddress(wallet.accounts[0])}
                  </div>
                </div>
                <div className="item-row">
                  <div className="col-name">MEMBERS SINCE:</div>
                  <div className="col-right">
                    {apiCurrentUser &&
                      apiCurrentUser.status === 200 &&
                      new Date(apiCurrentUser.createdAt).toLocaleString(
                        "en-US"
                      )}
                  </div>
                </div>
                <div className="item-row">
                  <div className="col-name">HOLDINGS:</div>
                  <div className="col-right">
                    {wallet?.balance} {currentChain?.nativeCurrency.symbol}
                  </div>
                </div>
                <div className="item-row">
                  <div className="col-name">RANK:</div>
                  <div className="col-right">{wallet?.rank ? wallet?.rank : "[Calculating]"}</div>
                </div>
                <div className="item-row">
                  <div className="col-name">REF CODE:</div>
                  <div className="col-right">
                    {apiCurrentUser &&
                      apiCurrentUser.status === 200 &&
                      apiCurrentUser.refCode}
                  </div>
                </div>
                <div className="bottom-links">
                  <Link to={search}>
                    <img src={twitter2Img} alt="" /> <span>FOLLOW US</span>
                  </Link>
                  <Link to={search}>
                    <img src={joinBtn2Img} alt="" /> <span>JOIN US</span>
                  </Link>
                  <Link to={search}>
                    <img src={visitBtnImg} alt="" /> <span>VISIT US</span>
                  </Link>
                  <Link to={search}>
                    <img src={inviteImg} width="60" alt="" />
                    <span>INVITE FRIENDS</span>
                    <small className="tooltip-content">
                      <img src={tooltipBgImg} alt="" />
                      <p>ref code copied to clipboard</p>
                    </small>
                  </Link>
                </div>
              </div>
            </div>

            {/* STATISTICS */}
            <div
              className="box-inner"
              style={
                activeLink === 1 ? { display: "block" } : { display: "none" }
              }
            >
              <div className="container-fluid statics">
                <div className="statics-shape">
                  <img src={staticsImg} alt="" />
                </div>
                <div className="row justify-content-center  d-flex">
                  <div className="col-3 item">
                    <img src={totalJams1Img} alt="" />
                    <span>TOTAL JAMS</span>
                    <small>{totalJams}</small>
                  </div>
                  <div className="col-3 item">
                    <img src={totalJams2Img} alt="" />
                    <span>TOTAL JAMMYS</span>
                    <small>{totalJammys}</small>
                  </div>
                  <div className="col-3 item">
                    <img src={cardImg} alt="" />
                    <span>CARDS PLAYED</span>
                    <small>{cardsPlayed}</small>
                  </div>
                  <div className="col-3 item">
                    <img src={maticWonImg} alt="" />
                    <span>{currentChain?.nativeCurrency.symbol} WON</span>
                    <small>{formatEther(totalWon)}</small>
                  </div>
                  <div className="col-3 item">
                    <img src={winnigImg} alt="" />
                    <span>WINNIG PERCENTAGE</span>
                    <small>{winningPerC}</small>
                  </div>
                  {/* <div className="col-3 item">
                    <img src={completedImg} alt="" />
                    <span>COMPLETED QUESTS</span>
                    <small>2400</small>
                  </div>
                  <div className="col-3 item">
                    <img src={leaderboardImg} alt="" />
                    <span>TOP OF THE LEADERBOARD</span>
                    <small>2400</small>
                  </div> */}
                </div>
              </div>
            </div>

            {/* GAMEHASH */}
            <div
              className="box-inner"
              style={
                activeLink === 2 ? { display: "block" } : { display: "none" }
              }
            >
              <div className="container-fluid game-hash">
                <div className="game-shape">
                  <img src={gameShapeImg} alt="" />
                </div>
                <div className="items-container">
                  <OverlayScrollbarsComponent
                    className="items-area scrollbarCustom content"
                    options={{ scrollbars: { theme: "os-theme-light" } }}
                  >
                    {gameHashListLoading && <Loading text="Loading Games..." />}
                    {gameHashList.length > 0 ? (
                      gameHashList.map((game, index) => (
                        <div
                          className="item"
                          key={index}
                          style={
                            !game.isRefundsOrClaimed
                              ? { color: "red", cursor: "pointer" }
                              : { cursor: "pointer" }
                          }
                          onClick={() => fetchGameDetail(game)}
                        >
                          GAME #{game.gameId}{" "}
                          {!game.isRefundsOrClaimed ? (
                            <span
                              style={{
                                border: "1px solid #ddd",
                                borderRadius: "6px",
                                padding: "0px 4px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                game.gameStatus === "Ended"
                                  ? claimPrize(game.gameId)
                                  : claimRefund(game.gameId)
                              }
                            >
                              claim
                            </span>
                          ) : null}
                        </div>
                      ))
                    ) : (
                      <h3 style={{ textAlign: "center" }}>
                        There are no games you have joined
                      </h3>
                    )}
                  </OverlayScrollbarsComponent>
                  <div className="game-info d-flex flex-column">
                    {gameHashDetailLoading && (
                      <Loading text="Loading Details..." />
                    )}
                    <h3>
                      GAME #
                      {gameDetail[0] &&
                        `${gameDetail[0].gameId} (${gameDetail[0].gameStatus})`}
                    </h3>
                    <div className="game-hash-info-area">
                      <div className="left-area">
                        <div className="item-row">
                          <div className="icon">
                            <img src={createdImg} alt="" />
                          </div>
                          <div className="text-right">
                            <h4>CREATED</h4>
                            {/* <h5>08.03.2023 00:44</h5> */}
                            <h5>
                              {gameDetail[0] &&
                                new Date(
                                  Number(gameDetail[0].startDate) * 1000
                                ).toLocaleString("en-US")}
                            </h5>
                          </div>
                        </div>
                        <div className="item-row">
                          <div className="icon">
                            <img src={potImg} alt="" />
                          </div>
                          <div className="text-right">
                            <h4>POT</h4>
                            <h5>
                              {gameDetail[0] &&
                                `${formatEther(
                                  Number(gameDetail[0].cardPrice) *
                                    Number(gameDetail[0].totalCardsSold)
                                )} ${currentChain?.nativeCurrency.symbol}`}
                            </h5>
                          </div>
                        </div>
                        <div className="item-row">
                          <div className="icon">
                            <img src={peopleImg} alt="" />
                          </div>
                          <div className="text-right">
                            <h4>PEOPLE</h4>
                            <h5>
                              {gameDetail[0] && gameDetail[0].totalPlayerCount}
                            </h5>
                          </div>
                        </div>
                        <div className="item-row">
                          <div className="icon">
                            <img src={created2Img} alt="" />
                          </div>
                          <div className="text-right">
                            <h4>CREATED</h4>
                            <h5>
                              {gameDetail[0] &&
                                `${gameDetail[0].gameTypeName} (${formatEther(
                                  gameDetail[0].cardPrice
                                )} ${currentChain?.nativeCurrency.symbol})`}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="right-area">
                        <div className="icon">
                          <img src={starImg} alt="" />
                        </div>
                        <div className="text-area-right">
                          <h4>WINNERS</h4>
                          <OverlayScrollbarsComponent
                            className="items-area scrollbarCustom content"
                            options={{
                              scrollbars: { theme: "os-theme-light" },
                            }}
                          >
                            {gameHashWinners.length > 0 &&
                              gameHashWinners.map((item, index) => (
                                <p key={index}>
                                  <Link
                                    to={`${currentChain?.blockExplorers.default.url}/address/${item.winner}`}
                                    target="_blank"
                                    style={{ color: "white" }}
                                    title={formatAddress(item.winner)}
                                  >
                                    <img
                                      // TODO: Her adrese göre ilgili api user gelecek
                                      src={
                                        apiCurrentUser &&
                                        apiCurrentUser.status === 200
                                          ? apiCurrentUser.avatar
                                          : defaultAvatar
                                      }
                                      alt={
                                        apiCurrentUser &&
                                        apiCurrentUser.status === 200
                                          ? apiCurrentUser.userName
                                          : "defaultAvatar"
                                      }
                                      width={24}
                                      style={{
                                        margin: "4px",
                                        borderRadius: "50%",
                                        border: "1px solid #ddd",
                                      }}
                                    />
                                    <span>
                                      username (
                                      {item.prizeIndex === 0
                                        ? "JAMMY"
                                        : item.prizeIndex === 1
                                        ? "4th Jam"
                                        : item.prizeIndex === 2
                                        ? "3rd Jam"
                                        : item.prizeIndex === 3
                                        ? "2nd Jam"
                                        : item.prizeIndex === 4
                                        ? "1st Jam"
                                        : null}
                                      )
                                    </span>
                                  </Link>
                                </p>
                              ))}
                          </OverlayScrollbarsComponent>
                        </div>
                      </div>
                    </div>
                    <div className="hash-id">
                      <span>HASH: </span>
                      {gameDetail[0] && (
                        <small>
                          <Link
                            to={`${currentChain?.blockExplorers.default.url}/tx/${gameDetail[0].transactionHash}`}
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            {`${gameDetail[0].transactionHash.substring(
                              0,
                              14
                            )} . . . ${gameDetail[0].transactionHash.substring(
                              gameDetail[0].transactionHash.length - 12,
                              gameDetail[0].transactionHash.length
                            )}`}
                          </Link>
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* RANKS */}
            <div
              className="box-inner"
              style={
                activeLink === 3 ? { display: "block" } : { display: "none" }
              }
            >
              <div className="container-fluid ranks">
                <div className="ranks-shape">
                  <img
                    src={
                      wallet?.rank &&
                      (wallet?.rank === "NEWBY"
                        ? rankRightNewby
                        : wallet?.rank === "JUNIOR"
                        ? rankRightJunior
                        : wallet?.rank === "PRO"
                        ? rankRightPro
                        : wallet?.rank === "ELITE"
                        ? rankRightElite
                        : wallet?.rank === "SENIOR"
                        ? rankRightSenior
                        : wallet?.rank === "MASTER"
                        ? rankRightMaster
                        : wallet?.rank === "LEGENDARY"
                        ? rankRightLegendary
                        : wallet?.rank === "ACE"
                        ? rankRightAce
                        : null)
                    }
                    alt=""
                  />
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <h3 className="text-center mb-3 mt-3">
                      TO BE A TRUE JAMMY MASTER!
                    </h3>
                  </div>
                </div>
                <div className="row justify-content-center  d-flex">
                  {ranks.length > 0 &&
                    ranks.map((rank, index) => (
                      <div className="col-lg-3 item" key={index}>
                        <img
                          src={
                            rank.name === "NEWBY"
                              ? rankNewby
                              : rank.name === "JUNIOR"
                              ? rankJunior
                              : rank.name === "PRO"
                              ? rankPro
                              : rank.name === "ELITE"
                              ? rankElite
                              : rank.name === "SENIOR"
                              ? rankSenior
                              : rank.name === "MASTER"
                              ? rankMaster
                              : rank.name === "LEGENDARY"
                              ? rankLegendary
                              : rank.name === "ACE"
                              ? rankAce
                              : null
                          }
                          alt={rank.name}
                        />
                        <span>{rank.name}</span>
                        <small>
                          {rank.minLevel}{" "}
                          {rank.maxLevel !== 0 ? `- ${rank.maxLevel}` : "+"}
                        </small>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <AvatarSelect
        show={AvatarSelectOpen}
        onClose={() => setAvatarSelectOpen(!AvatarSelectOpen)}
      />
    </>
  );
};
export default UserProfile;
