import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useWalletConnection } from "../../utility/hooks/useWalletConnection";
import {
  urlParams,
  fetchGame,
  getLogsPrizeWon,
  checkGamePrizes,
  checkNumbers,
  allStorageClear,
} from "./store";
import {
  checkGameStatus,
  setStorage,
  getStorage,
  getInfo,
  getApiUser,
} from "../store";
import { formatAddress } from "../../utility/Utils";
import BottomButtons from "../../components/Game/BottomButtons/BottomButtons";
import Cards from "../../components/Game/Cards/Cards";
import TopInfo from "../../components/Game/TopInfo/TopInfo";
import RightLine from "../../components/Game/RightLine/RightLine";
import LeftLine from "../../components/Game/LeftLine/LeftLine";
import HeadLine from "../../components/HeadLine/HeadLine";
//popups
import CardsTxPopup from "../../components/Game/Popups/CardsTxPopup";
import PrizesPopup from "../../components/Game/Popups/PrizesPopup";
import WinnerLoserPopup from "../../components/Game/Popups/WinnerLoserPopup";
import CancelledExpiredPopup from "../../components/Game/Popups/CancelledExpiredPopup";
//css
import "./index.css";

function Game() {
  const { search } = useLocation();
  const [searchParams] = useState(new URLSearchParams(search));
  const {
    isConnected,
    currentChain,
    connectedWalletInfo,
    address,
    chainId,
    wallet,
    signer,
    unSigner,
    contractAbi,
    error,
    errorMessage,
    clearError,
    open,
    connect,
    disConnect,
    fragments,
    getFragment,
    toastArgs,
    setToastArgs,
  } = useWalletConnection();
  const dispatch = useDispatch();
  const gameId = useSelector((state) => state.gameStore.gameId);
  const gameStatus = useSelector((state) => state.rootStore.gameStatus);

  const [joinTx, setJoinTx] = useState(null);
  const [drawnNumbers, setDrawnNumbers] = useState([]);
  const passiveNumbers = Array.from(Array(76).keys()).slice(1);
  // const [manuelCloseToggle, setManuelCloseToggle] = useState(false); //default > false: auto | true: manuel

  const [cardChangePopup, setCardChangePopup] = useState(false);
  const initialPrizesPopupParams = { prizeIndex: -1, winners: [] };
  const [prizesPopupParams, setPrizesPopupParams] = useState(
    initialPrizesPopupParams
  );
  const [showWinnerLoserPopup, setShowWinnerLoserPopup] = useState(false);
  const [isCancelExpired, setIsCancelExpired] = useState(null);

  const chechRefundStatus = async (gameId, user) => {
    const result = await unSigner.contract
      .playerCards(gameId, user, 0)
      .then(async () => {
        const refunds = await unSigner.contract.refunds(gameId, user);
        console.log("refunds:", refunds);
        return refunds;
      })
      .catch((err) => {
        console.log("refunds: Not joined game!");
        return true;
      });

    return result;
  };

  const checkLocalContractJams = async () => {
    dispatch(
      getLogsPrizeWon({
        dispatch,
        unSigner,
        currentChain,
        gameId,
      })
    )
      .then(async (result) => {
        if (!result.payload.errors) {
          // console.log(result.payload.eventLogs);
          let showTimer = 0;

          for (let prizeIndex = 4; prizeIndex > 0; prizeIndex--) {
            const prizeLogs = result.payload.eventLogs.filter(
              (eLog) =>
                eLog.eventName === "PrizeWon" &&
                Number(eLog.args.gameId) === gameId &&
                Number(eLog.args.prizeIndex) === prizeIndex
            );

            const localWinners =
              (await dispatch(
                getStorage({
                  key: `prizes:${gameId}:${prizeIndex}`,
                  type: "local",
                  currentChain,
                })
              ).then((result) => {
                if (result.payload) {
                  return result.payload;
                }
              })) || [];

            let unshownWinners = [];

            if (
              prizeLogs.length > 0 &&
              prizeLogs.length !== localWinners.length
            ) {
              // console.log(prizeIndex, "açılmayan prize popup var");

              prizeLogs.forEach((logItem) => {
                if (localWinners.length > 0) {
                  if (
                    !localWinners.find(
                      (item) => item.player === logItem.args.winner
                    )
                  ) {
                    unshownWinners.push({
                      player: logItem.args.winner,
                      card: logItem.args.winnerCard,
                      hash: logItem.transactionHash,
                    });
                  }
                } else {
                  unshownWinners.push({
                    player: logItem.args.winner,
                    card: logItem.args.winnerCard,
                    hash: logItem.transactionHash,
                  });
                }
              });

              setTimeout(function () {
                console.log("show-popup", prizeIndex, unshownWinners);
                setPrizesPopupParams({
                  prizeIndex,
                  winners: unshownWinners,
                });
              }, showTimer);
              showTimer = showTimer + 5000;

              setTimeout(function () {
                console.log("close-popup", prizeIndex, unshownWinners);
                setPrizesPopupParams(initialPrizesPopupParams);
              }, showTimer);
              showTimer = showTimer + 500;
            }
          } //for end
        }
      })
      .catch((e) => console.log(e));
  };

  // game && console.log("Game:", game);

  useEffect(() => {
    if (!gameId && unSigner.contract && wallet.accounts.length > 0) {
      dispatch(
        urlParams({
          dispatch,
          unSigner,
          user: wallet.accounts[0].toLowerCase(),
          searchParams,
        })
      );
    }

    try {
      if (!gameId > 0) return;
      dispatch(checkGameStatus({ gameId, unSigner }));

      if (!signer) return;
      if (!unSigner.contract) return;
      if (!wallet.accounts.length > 0) return;
      if (!currentChain) return;
      if (!gameStatus > 0) return;

      //cardTx popup açılışı
      dispatch(
        getStorage({
          key: `join:${gameId}:${wallet.accounts[0].toLowerCase()}`,
          type: "local",
          currentChain,
        })
      ).then((result) => {
        if (result.payload) {
          console.log(result.payload);
          setJoinTx(result.payload.split("-")[1]);
        }
      });

      dispatch(fetchGame({ dispatch, unSigner, gameId }));

      if (gameStatus === 1) {
        console.log(">>> GAME CREATED");
      } else if (gameStatus === 2) {
        console.log(">>> GAME HAS NOT STARTED YET");
      } else if (gameStatus === 3) {
        console.log(">>> GAME STARTED");
        dispatch(
          checkNumbers({
            dispatch,
            gameId,
            unSigner,
            gameStatus,
            currentChain,
          })
        ).then((result) => {
          if (result.payload) {
            setDrawnNumbers(result.payload);
          }
        });

        if (!signer.isHost) {
          dispatch(
            checkGamePrizes({
              dispatch,
              unSigner,
              gameId,
              user: wallet.accounts[0].toLowerCase(),
            })
          ).then((result) => {
            if (result.payload) {
              checkLocalContractJams(result.payload); // açılmayan popuplar için kontrol edilir.
            }
          });
        }
      } else if (gameStatus === 4) {
        console.log(">>> GAME ENDED");
      } else if (gameStatus === 5) {
        console.log(">>> GAME EXPIRED");
        chechRefundStatus(gameId, wallet.accounts[0].toLowerCase()).then(
          (result) => {
            if (!result) {
              setIsCancelExpired("expired");
            }
          }
        );
      } else if (gameStatus === 6) {
        console.log(">>> GAME CANCELED");
        chechRefundStatus(gameId, wallet.accounts[0].toLowerCase()).then(
          (result) => {
            if (!result) {
              setIsCancelExpired("cancelled");
            }
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    signer,
    unSigner.contract,
    wallet.accounts,
    currentChain,
    gameId,
    gameStatus,
  ]);

  //Event listeners
  useEffect(() => {
    if (!unSigner.contract) return;
    if (!wallet.accounts.length > 0) return;
    if (!currentChain) return;
    if (!gameId > 0) return;
    if (!gameStatus > 0) return;

    //*** wininghistory test ***//
    // dispatch(
    //   checkGamePrizes({
    //     dispatch,
    //     unSigner,
    //     gameId,
    //     user: wallet.accounts[0].toLowerCase(),
    //   })
    // );

    //*** prizepopup test ***//
    // checkLocalContractJams();

    // dispatch(
    //   getLogsPrizeWon({
    //     dispatch,
    //     unSigner,
    //     currentChain,
    //     gameId,
    //   })
    // )
    //   .then(async (result) => {
    //     if (!result.payload.errors) {
    //       console.log(result.payload.eventLogs);
    //       const prizes = [];
    //       result.payload.eventLogs.forEach((ev) => {
    //         prizes.push({
    //           gameId: ev.args.gameId,
    //           prizeIndex: ev.args.prizeIndex,
    //           winner: ev.args.winner,
    //           winnerCard: ev.args.winnerCard,
    //           hash: ev.transactionHash,
    //         });
    //       });

    //       const logIndex = 5;
    //       Number(prizes[logIndex].prizeIndex) !== 0 &&
    //         setPrizesPopupParams({
    //           prizeIndex: prizes[logIndex].prizeIndex,
    //           winners: [
    //             {
    //               player: prizes[logIndex].winner,
    //               card: prizes[logIndex].winnerCard,
    //               hash: prizes[logIndex].hash,
    //             },
    //           ],
    //         });
    //     }
    //   })
    //   .catch((e) => console.log(e));

    // setPrizesPopupParams({ prizeIndex: 0 });

    const listenerCardsAdded = () => {
      console.log("#CardsAdded (game) event was emmited");
      dispatch(getInfo({ unSigner }));
    };
    const listenerCardsUpdated = () => {
      console.log("#CardsUpdated (game) event was emmited");
      dispatch(getInfo({ unSigner }));
    };
    const listenerPlayerJoined = async (
      joinGameId,
      player,
      cardsCount,
      event
    ) => {
      if (Number(joinGameId) !== gameId) return;
      console.log("#PlayerJoined (game) event was emmited");

      const apiUser = await dispatch(getApiUser({ address: player })).then(
        (result) => {
          return result.payload;
        }
      );

      setToastArgs({
        msgType: "ok",
        msg: `${
          apiUser?.status === 200 ? apiUser?.userName : "Unknown user"
        } (${formatAddress(player)}) joined with ${Number(cardsCount)} cards`,
        isShow: true,
      });

      dispatch(checkGameStatus({ gameId, unSigner }));
      dispatch(fetchGame({ dispatch, unSigner, gameId }));
    };
    const listenerRequestFulfilled = async (
      requestId,
      reqType,
      player,
      numberOfWords,
      event
    ) => {
      const reqResult = await unSigner.contract.randomRequests(requestId);
      if (Number(reqResult.gameId) !== gameId) return;
      if (Number(reqType) !== 3) return;
      console.log("#RequestFulfilled (game) event was emmited");

      dispatch(fetchGame({ dispatch, unSigner, gameId }));
      dispatch(checkGameStatus({ gameId, unSigner }));
    };
    const listenerGameStarted = (startedGameId, event) => {
      if (Number(startedGameId) !== gameId) return;
      console.log("#GameStarted (game) event was emmited");
      const sbKey = `sbn:${currentChain.contracts.jammy.address}:${currentChain.id}:${gameId}`;
      sessionStorage.setItem(sbKey, Number(event.blockNumber) || 0);

      dispatch(checkGameStatus({ gameId, unSigner }));
      const networkKey = `${currentChain.contracts.jammy.address}:${currentChain.id}`;
      localStorage.removeItem(
        `${networkKey}:join:${gameId}:${wallet.accounts[0].toLowerCase()}`
      );
      localStorage.removeItem(
        `${networkKey}:redraw:${gameId}:${wallet.accounts[0].toLowerCase()}:0`
      );
      localStorage.removeItem(
        `${networkKey}:redraw:${gameId}:${wallet.accounts[0].toLowerCase()}:1`
      );
      localStorage.removeItem(
        `${networkKey}:redraw:${gameId}:${wallet.accounts[0].toLowerCase()}:2`
      );
      localStorage.removeItem(
        `${networkKey}:redraw:${gameId}:${wallet.accounts[0].toLowerCase()}:3`
      );
    };
    const listenerNumberRevealed = async (nrGameId, revealedNum, event) => {
      if (Number(nrGameId) !== gameId) return;
      console.log("#NumberRevealed (game) event was emmited");

      let localRN = await dispatch(
        getStorage({
          key: `revealNumbers:${gameId}`,
          type: "local",
          currentChain,
        })
      ).then((result) => {
        return result.payload || [];
      });

      if (!localRN.find(({ number }) => number === Number(revealedNum))) {
        const newRevealedNum = {
          datetime: (await event.log.getBlock()).timestamp,
          number: Number(revealedNum),
          transaction: event.log.transactionHash,
        };

        localRN.push(newRevealedNum);
        setDrawnNumbers((oldDrawnNumbers) => [
          ...oldDrawnNumbers,
          !oldDrawnNumbers.find(
            ({ number }) => number === Number(revealedNum)
          ) && newRevealedNum,
        ]);

        //set local numbers
        dispatch(
          setStorage({
            key: `revealNumbers:${gameId}`,
            value: localRN, //yeni sayının eklenmiş hali
            type: "local",
            currentChain,
          })
        );

        const info = await dispatch(
          getInfo({
            unSigner,
            gameId,
            user: wallet.accounts[0].toLowerCase(),
          })
        )
          .then((result) => {
            if (result.payload) {
              return result.payload;
            }
          })
          .catch((error) => console.log(error));

        console.log(
          "localRN:",
          localRN.length,
          "contractRN:",
          Number(info.revealedNumberLength)
        );

        // sync numbers
        if (info && localRN.length !== Number(info.revealedNumberLength)) {
          console.log(">>> FE and Contract out of sync");
          dispatch(
            checkNumbers({
              dispatch,
              gameId,
              unSigner,
              gameStatus,
              currentChain,
            })
          )
            .then((result) => {
              if (result.payload) {
                setDrawnNumbers(result.payload);
              }
            })
            .catch((error) => console.log(error));
        } else {
          console.log(">>> FE and Contract synchronized");
        }

        if (!signer.isHost) {
          dispatch(
            checkGamePrizes({
              dispatch,
              unSigner,
              gameId,
              user: wallet.accounts[0].toLowerCase(),
            })
          )
            .then((result) => {
              if (result.payload) {
                checkLocalContractJams(result.payload); // açılmayan popuplar için kontrol edilir.
              }
            })
            .catch((error) => console.log(error));
        }
      }
    };
    const listenerPrizeWon = (
      prizeWonGameId,
      prizeIndex,
      winner,
      winnerCard,
      event
    ) => {
      if (Number(prizeWonGameId) !== gameId) return;
      console.log("#PrizeWon (game) event was emmited");
      // console.log("winnerCard (bigint):", winnerCard);
      // console.log("winnerCard (hex):", toBeHex(winnerCard));
      // console.log("winnerCard (array):", hexToArray(toBeHex(winnerCard)));

      dispatch(checkGameStatus({ gameId, unSigner }));

      if (!signer.isHost) {
        if (Number(prizeIndex) !== 0) {
          setPrizesPopupParams(initialPrizesPopupParams);
          setTimeout(function () {
            setPrizesPopupParams({
              prizeIndex: Number(prizeIndex),
              winners: [
                {
                  player: winner,
                  card: winnerCard,
                  hash: event.log.transactionHash,
                },
              ],
            });
          }, 500);
        }
      } else {
        if (
          Number(prizeIndex) === 0 &&
          !localStorage.getItem(
            `${currentChain.contracts.jammy.address}:${
              currentChain.id
            }:jammy:${Number(prizeWonGameId)}`
          )
        ) {
          dispatch(
            setStorage({
              key: `jammy:${Number(prizeWonGameId)}`,
              value: true,
              type: "local",
              currentChain,
            })
          );
        }
      }
    };
    const listenerGameEnds = async (endsGameId, event) => {
      if (Number(endsGameId) !== gameId) return;
      console.log("#GameEnds (game) event was emmited");

      dispatch(
        checkGamePrizes({
          dispatch,
          unSigner,
          gameId,
          user: wallet.accounts[0].toLowerCase(),
        })
      );

      dispatch(checkGameStatus({ gameId, unSigner }));

      setPrizesPopupParams({ prizeIndex: 0 });
    };
    const listenerPrizeCollected = (
      prizeCollectedGameId,
      totalAmount,
      event
    ) => {
      if (Number(prizeCollectedGameId) !== gameId) return;
      console.log("#PrizeCollected (game) event was emmited");

      if (!signer.isHost) {
        dispatch(
          allStorageClear({
            gameId,
            user: wallet.accounts[0].toLowerCase(),
            currentChain,
          })
        );
      }

      if (!showWinnerLoserPopup) {
        setPrizesPopupParams(initialPrizesPopupParams);
        setShowWinnerLoserPopup(true);
      }
    };
    const listenerGameCancelled = (cancelledGameId, event) => {
      if (Number(cancelledGameId) !== gameId) return;
      console.log("#GameCancelled (game) event was emmited");

      dispatch(checkGameStatus({ gameId, unSigner }));

      chechRefundStatus(gameId, wallet.accounts[0].toLowerCase())
        .then((result) => {
          if (!result) {
            setIsCancelExpired("cancelled");
          }
        })
        .catch((error) => console.log(error));
    };

    unSigner.contract?.on("CardsAdded", listenerCardsAdded);
    unSigner.contract?.on("CardsUpdated", listenerCardsUpdated);
    if (gameStatus === 1) {
      unSigner.contract?.on("PlayerJoined", listenerPlayerJoined);
      unSigner.contract?.on("GameCancelled", listenerGameCancelled);
    }
    if (gameStatus === 1 || gameStatus === 3) {
      unSigner.contract?.on("RequestFulfilled", listenerRequestFulfilled);
    }
    if (gameStatus === 2 || gameStatus === 3) {
      unSigner.contract?.on("GameStarted", listenerGameStarted);
    }
    if (gameStatus === 3) {
      unSigner.contract?.on("NumberRevealed", listenerNumberRevealed);
      unSigner.contract?.on("PrizeWon", listenerPrizeWon);
    }
    if (gameStatus === 3 || gameStatus === 4) {
      unSigner.contract?.on("GameEnds", listenerGameEnds);
    }
    if (gameStatus === 4) {
      unSigner.contract?.on("PrizeCollected", listenerPrizeCollected);
    }
    console.log("+>>> On useEffect (game event listeners)");
    return () => {
      unSigner.contract?.off("CardsAdded", listenerCardsAdded);
      unSigner.contract?.off("CardsUpdated", listenerCardsUpdated);
      if (gameStatus === 1) {
        unSigner.contract?.off("PlayerJoined", listenerPlayerJoined);
        unSigner.contract?.off("GameCancelled", listenerGameCancelled);
      }
      if (gameStatus === 1 || gameStatus === 3) {
        unSigner.contract?.off("RequestFulfilled", listenerRequestFulfilled);
      }
      if (gameStatus === 2 || gameStatus === 3) {
        unSigner.contract?.off("GameStarted", listenerGameStarted);
      }
      if (gameStatus === 3) {
        unSigner.contract?.off("NumberRevealed", listenerNumberRevealed);
        unSigner.contract?.off("PrizeWon", listenerPrizeWon);
      }
      if (gameStatus === 3 || gameStatus === 4) {
        unSigner.contract?.off("GameEnds", listenerGameEnds);
      }
      if (gameStatus === 4) {
        unSigner.contract?.off("PrizeCollected", listenerPrizeCollected);
      }
      console.log("->>> returned useEffect (game event listeners)");
    };
  }, [unSigner.contract, wallet.accounts, currentChain, gameId, gameStatus]);

  return (
    <>
      <HeadLine />
      <div className="wrapper">
        <LeftLine
          drawnNumbers={drawnNumbers}
          passiveNumbers={passiveNumbers.sort(function (a, b) {
            return a - b;
          })}
        />
        <div className="item-center col-7 col-xl-6">
          <TopInfo />
          <Cards
            drawnNumbers={drawnNumbers}
            // manuelCloseToggle={manuelCloseToggle}
            cardChangePopup={cardChangePopup}
            setCardChangePopup={setCardChangePopup}
          />
          <BottomButtons
            drawnNumbers={drawnNumbers}
            setCardChangePopup={setCardChangePopup}
            // manuelCloseToggle={manuelCloseToggle}
            // setManuelCloseToggle={setManuelCloseToggle}
          />
        </div>
        <RightLine drawnNumbers={drawnNumbers} />
      </div>

      {/* POPUPS: CardsTxPopup */}
      {joinTx && (
        <CardsTxPopup
          hash={joinTx}
          onClose={() => {
            localStorage.removeItem(
              `${currentChain.contracts.jammy.address}:${
                currentChain.id
              }:join:${gameId}:${wallet.accounts[0].toLowerCase()}`
            );
            setJoinTx(null);
          }}
        />
      )}

      {/* POPUPS: PrizesPopup */}
      {Number(prizesPopupParams.prizeIndex) >= 0 && !signer.isHost && (
        <PrizesPopup
          prizesPopupParams={prizesPopupParams}
          setShowWinnerLoserPopup={setShowWinnerLoserPopup}
          onClose={() => setPrizesPopupParams(initialPrizesPopupParams)}
        />
      )}

      {/* POPUPS: WinnerLoserPopup */}
      {showWinnerLoserPopup && !signer.isHost && (
        <WinnerLoserPopup onClose={() => setShowWinnerLoserPopup(false)} />
      )}

      {/* POPUPS: CancelledExpiredPopup */}
      {(isCancelExpired === "cancelled" || isCancelExpired === "expired") &&
        !signer.isHost && (
          <CancelledExpiredPopup
            isCancelExpired={isCancelExpired}
            onClose={() => setIsCancelExpired(null)}
          />
        )}
    </>
  );
}
export default Game;
