import {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import {
  useAppKit,
  useDisconnect,
  useAppKitNetwork,
  useAppKitAccount,
  useAppKitProvider,
  useWalletInfo,
} from "@reown/appkit/react";
import {
  WebSocketProvider,
  JsonRpcProvider,
  BrowserProvider,
  JsonRpcSigner,
  Contract,
  formatEther,
} from "ethers";
import { networks } from "../../configs/reown";

import Bingo from "../../abis/artifacts/contracts/Jammy.sol/Jammy.json";

const disconnectedState = { accounts: [], isConnected: false, chainId: "", balance: 0, rank: null };

const initialSigner = {
  provider: null,
  signer: null,
  contract: null,
  isDeployer: false,
  isAdmin: false,
  isHost: false,
};

const initialUnSigner = {
  provider: null,
  contract: null,
  socketProvider: null,
  socketSubId: null,
};

const WalletContext = createContext();

export const WalletContextProvider = ({ children }) => {
  const { open } = useAppKit();
  const { disconnect } = useDisconnect();
  const { chainId, switchNetwork } = useAppKitNetwork();
  const { isConnected, address } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider("eip155");
  const walletInfo = useWalletInfo();

  const [currentChain, setCurrentChain] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const clearError = () => setErrorMessage("");

  const [wallet, setWallet] = useState(disconnectedState);
  const [signer, setSigner] = useState(initialSigner);
  const [unSigner, setUnSigner] = useState(initialUnSigner);
  const [fragments, setFragments] = useState([]);

  const [toastArgs, setToastArgs] = useState({
    msgType: null,
    msg: null,
    isShow: false,
  });

  const connectContract = async (_currentChain) => {
    //TODO: fragmentlar burda olmayacak hataya sebep olabilir burası.
    unSigner.contract?.interface.forEachEvent((item) =>
      setFragments((oldValues) => [...oldValues, item])
    );
    unSigner.contract?.interface.forEachError((item) =>
      setFragments((oldValues) => [...oldValues, item])
    );
    unSigner.contract?.interface.forEachFunction((item) =>
      setFragments((oldValues) => [...oldValues, item])
    );

    try {
      let _socketProvider = null;
      let _subscriptionId = null;
      // if (_currentChain.rpcUrls.extra.wss.length > 0) {
      //   _socketProvider = new WebSocketProvider(
      //     `${_currentChain.rpcUrls.extra.wss[0]}`
      //   );
      //   _subscriptionId = await _socketProvider.send("eth_subscribe", [
      //     "logs",
      //     {
      //       address: _currentChain.contracts.jammy.address,
      //       // "topics": ["0x56a24d85fda2978a12415e4db13d8170a2e3c40e8864de29ebc210f6428569c5"]
      //     },
      //   ]);
      //   console.log("Subscribed with ID:", _subscriptionId);
      // }
      
      const jsonRpcProvider = new JsonRpcProvider(
        _currentChain.rpcUrls.extra.http[0]
      );
      const _unSignerContract = new Contract(
        _currentChain.contracts.jammy.address,
        Bingo.abi,
        jsonRpcProvider
      );

      if (_unSignerContract) {
        setUnSigner({
          provider: jsonRpcProvider,
          contract: _unSignerContract,
          socketProvider: _socketProvider,
          socketSubId: _subscriptionId,
        });
      }

      // set Contract
      if (isConnected && address) {
        const provider = new BrowserProvider(walletProvider, chainId);
        const signer = new JsonRpcSigner(provider, address);
        const _bingoContract = new Contract(
          _currentChain.contracts.jammy.address,
          Bingo.abi,
          signer
        );

        const balance = Number(Number(formatEther(await provider.getBalance(address, 'latest'))).toFixed(6));
        setWallet({ accounts: [address.toLowerCase()], isConnected, chainId, balance, rank: null });

        if (signer && _bingoContract) {
          setSigner({
            provider,
            signer,
            contract: _bingoContract,
            isDeployer: (await _bingoContract.deployer()).toLowerCase() === address.toLowerCase(),
            isAdmin: Boolean(await _bingoContract.admins(address)),
            isHost: Boolean(await _bingoContract.hosts(address)),
          });
        }
      } else {
        setWallet(disconnectedState);
      }

      clearError();
    } catch (err) {
      console.log("useWalletConnection:", err);
      setErrorMessage(err);
    }
  };

  const _loadWallet = useCallback(async () => {
    if (typeof window.ethereum === "undefined") return;
    if (!isConnected) connectContract(networks[0]); //default network
    if (chainId) {
      const _currentChain = networks.find((chain) => chain.id === chainId);
      if (_currentChain) {
        // console.log("currentChain:", _currentChain);
        setCurrentChain(_currentChain);
        connectContract(_currentChain);
      } else {
        console.log("unsupport network:", chainId);
        open({ view: "Networks" });
      }
    }
  }, [isConnected, address, chainId]);

  const connect = async () => {
    clearError();
    open();
  };

  const disConnect = async () => {
    clearError();
    disconnect();
    setSigner(initialSigner);
    setWallet(disconnectedState);
  };

  const getFragment = (type, selector) => {
    const res = fragments.find(
      (item) => item.type === type && item.selector === selector
    );
    if (res) return res;
  };

  useEffect(() => {
    // if (error) {
    //   console.log("useWeb3ModalError:", error);
    //   setErrorMessage(`useWeb3ModalError: ${error}`);
    // }
    _loadWallet();
  }, [_loadWallet]);

  return (
    <WalletContext.Provider
      value={{
        isConnected,
        currentChain,
        connectedWalletInfo: walletInfo !== undefined && walletInfo.name,
        address,
        chainId,
        wallet,
        setWallet,
        signer,
        unSigner,
        contractAbi: Bingo,
        error: !!errorMessage,
        errorMessage,
        clearError,
        open,
        connect,
        disConnect,
        fragments,
        getFragment,
        toastArgs,
        setToastArgs,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWalletConnection = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error(
      'useWalletConnection must be used within a "WalletContextProvider"'
    );
  }
  return context;
};
