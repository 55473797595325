import React, { useState, useEffect } from "react";
import "./PopupsGameStyle.css";
import CloseButton from "../../CloseButton/CloseButton";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useWalletConnection } from "../../../utility/hooks/useWalletConnection";
import { allStorageClear, getLogsPrizeWon } from "../../../pages/Game/store";
import { formatEther, toBeHex } from "ethers";
import { hexToArray } from "../../../utility/Utils";
import Spinner from "react-bootstrap/Spinner";
import Loading from "../../Loading/Loading";
//images
import facebookImg from "../../../assets/img/facebook.png";
import twitterImg from "../../../assets/img/twitter.png";
import invite2Img from "../../../assets/img/invite2.png";
import popupBgImg from "../../../assets/img/popup-bg.png";
import logo from "../../../assets/img/logo.svg";
import defaultAvatar from "../../../assets/img/user-icon.svg";

const WinnerLoserPopup = ({ onClose }) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const {
    isConnected,
    currentChain,
    connectedWalletInfo,
    address,
    chainId,
    wallet,
    signer,
    unSigner,
    contractAbi,
    error,
    errorMessage,
    clearError,
    open,
    connect,
    disConnect,
    fragments,
    getFragment,
    toastArgs,
    setToastArgs,
  } = useWalletConnection();

  const gameId = useSelector((state) => state.gameStore.gameId);
  const game = useSelector((state) => state.gameStore.game);
  const apiCurrentUser = useSelector((state) => state.rootStore.apiCurrentUser);

  const [isLoading, setIsLoading] = useState(true);
  const [biggestPrize, setBiggestPrize] = useState(null);
  const [titleImg, setTitleImg] = useState(null);
  const [prizeWonAmounts, setprizeWonAmounts] = useState([]);

  const winnersImages = require.context(
    "../../../assets/img/winpopupheaders",
    true
  );

  const winnersImageList = winnersImages
    .keys()
    .map((image) => winnersImages(image));

  const losersImages = require.context(
    "../../../assets/img/losepopupheaders",
    true
  );

  const losersImageList = losersImages
    .keys()
    .map((image) => losersImages(image));

  const generate_random_number = (max) => Math.floor(Math.random() * max);

  const getPrizeAmount = async (
    totalPot,
    precisionBasis,
    prizeIndex,
    winnerCount
  ) => {
    const share = Number(
      (await unSigner.contract?.gamePrizes(gameId, prizeIndex))[0]
    );
    console.log({ totalPot, precisionBasis, prizeIndex, winnerCount, share });
    return (totalPot * share) / precisionBasis / winnerCount;
  };

  useEffect(() => {
    if (!signer) return;
    if (!unSigner.contract) return;
    if (!wallet.accounts.length > 0) return;
    if (!currentChain) return;
    if (!gameId) return;
    if (!game) return;

    dispatch(
      getLogsPrizeWon({
        dispatch,
        unSigner,
        currentChain,
        gameId,
      })
    )
      .then(async (result) => {
        if (!result.payload.errors) {
          const allCards = await unSigner.contract.getCards();

          const filteredLogs = result.payload.eventLogs.filter(
            ({ args }) =>
              args.winner.toLowerCase() === wallet.accounts[0].toLowerCase()
          );

          if (filteredLogs.length > 0) {
            //kazanılan en büyük ödül bulunur.
            const _biggestPrize = filteredLogs.sort(
              (a, b) => Number(a.args.prizeIndex) - Number(b.args.prizeIndex)
            )[0];
            let cardIndex = null;
            allCards.forEach((card, index) => {
              if (card === _biggestPrize.args.winnerCard) {
                cardIndex = index;
              }
            });
            const arrayCard = hexToArray(toBeHex(_biggestPrize.args.winnerCard));
            console.log({ cardIndex, arrayCard });

            const precisionBasis = Number(
              await unSigner.contract?.PRECISION_BASIS()
            );
            const totalPot =
              Number(game?.cardPrice) * Number(game?.totalCardsSold);

            let prizeIndexes = [];
            filteredLogs.forEach((event) => {
              prizeIndexes.push({
                index: Number(event.args.prizeIndex),
                hash: event.transactionHash,
              });
            });
            prizeIndexes.forEach(async (item) => {
              const winners = result.payload.eventLogs.filter(
                ({ args }) => Number(args.prizeIndex) === item.index
              );

              await getPrizeAmount(
                totalPot,
                precisionBasis,
                item.index,
                winners.length
              ).then((prizeAmount) => {
                setprizeWonAmounts((oldArry) => [
                  ...oldArry,
                  {
                    prizeIndex: item.index,
                    prizeAmount,
                    hash: item.hash,
                  },
                ]);
              });
            });

            setBiggestPrize({
              isWon: true,
              cardIndex,
              card: arrayCard,
              prizeIndex: Number(_biggestPrize.args.prizeIndex),
              hash: _biggestPrize.transactionHash,
            });

            setTitleImg(
              winnersImageList[generate_random_number(winnersImageList.length)]
            );
          } else {
            //player lose (hiçbir ödül kazanılmamıştır)
            setBiggestPrize({
              isWon: false,
            });

            setTitleImg(
              losersImageList[generate_random_number(losersImageList.length)]
            );
          }
          setIsLoading(false);
        }
      })
      .catch((e) => console.log(e));

    if (!signer.isHost) {
      dispatch(
        allStorageClear({ gameId, user: wallet.accounts[0].toLowerCase(), currentChain })
      );
    }
  }, [signer, unSigner.contract, wallet.accounts, currentChain, gameId, game]);

  return (
    <>
      <div className="popup-wrapper">
        <CloseButton
          onClose={onClose}
          to={search}
          extraAction={{
            type: "winnerLoser",
            action: () => (window.location.href = "/"), //cacheleri önlemek için reload
          }}
        />
        <div className="in">
          {!isLoading && titleImg && (
            <div className="text-top-area">
              <img src={titleImg} alt="" />
            </div>
          )}
          <div className="card-bottom-area" style={{ top: "36%" }}>
            <div className="card-container">
              {!isLoading && biggestPrize ? (
                biggestPrize.isWon === true ? (
                  <div className="item-card" style={{ marginRight: "15px", padding: "22px 34px 22px 26px" }}>
                    <span>{biggestPrize.cardIndex}</span>
                    <div className="in" style={{ marginTop: "10px" }}>
                      {biggestPrize.card.map((numbers, numberindex) => (
                        <div
                          className={numbers === 0 ? "item revealed" : "item"}
                          key={numberindex}
                        >
                          {numbers !== 0 ? numbers : null}
                        </div>
                      ))}
                    </div>

                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: "-12px",
                        marginTop: "12px",
                      }}
                    >
                      <Link
                        to={`${currentChain?.blockExplorers.default.url}/tx/${biggestPrize.hash}`}
                        target="blank"
                        style={{
                          textShadow:
                            "2px 2px 0 white, -1px -1px 0 white, 2px -1px 0 white, -1px 2px 0 white, 2px 2px 0 white",
                        }}
                        title={`View Txn on ${currentChain?.blockExplorers.default.name}`}
                      >
                        {biggestPrize.prizeIndex === 0 && "JAMMY"}
                        {biggestPrize.prizeIndex === 1 && "4th JAM"}
                        {biggestPrize.prizeIndex === 2 && "3rd JAM"}
                        {biggestPrize.prizeIndex === 3 && "2nd JAM"}
                        {biggestPrize.prizeIndex === 4 && "1st JAM"}
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div
                    className="item-card"
                    style={{ marginRight: "15px", padding: "90px" }}
                  >
                    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                      <img src={logo} alt="" />
                      <span>You lose :(</span>
                    </div>
                  </div>
                )
              ) : (
                // prizesWon.length > 0 ? (
                //   prizesWon[0] === 0 ? (
                //     <div className="item-card">
                //       <span>{jammy.length > 0 && jammy[0]}</span>
                //       <div className="in" style={{ margin: "25px 0" }}>
                //         {jammy.length > 0 &&
                //           jammy[1].map((numbers, numberindex) => (
                //             <div
                //               className={
                //                 numbers === 0 ? "item revealed" : "item"
                //               }
                //               key={numberindex}
                //             >
                //               {numbers !== 0 ? numbers : null}
                //             </div>
                //           ))}
                //       </div>
                //     </div>
                //   ) : (
                //     <div className="item-card">
                //       <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                //         <img src={logo} alt="" />
                //         <span>{prizesWon[0]}</span>
                //         <span>
                //           {prizesWon[0] === 1 && "Biggest prize won is 4th JAM"}
                //           {prizesWon[0] === 2 && "Biggest prize won is 3rd JAM"}
                //           {prizesWon[0] === 3 && "Biggest prize won is 2nd JAM"}
                //           {prizesWon[0] === 4 && "Biggest prize won is 1st JAM"}
                //         </span>
                //       </div>
                //     </div>
                //   )
                // ) : (
                //   <div className="item-card">
                //     <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                //       <img src={logo} alt="" />
                //       <span>You lose :(</span>
                //     </div>
                //   </div>
                // )
                <div
                  className="item-card"
                  style={{ marginRight: "15px", padding: "90px" }}
                >
                  <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                    <img src={logo} alt="" />
                    <Loading text="Loading card..." />
                  </div>
                </div>
              )}
              <div className="right-area">
                <div className="info-box-wrapper">
                  <div className="profile-info-box">
                    <div className="img">
                      {apiCurrentUser && apiCurrentUser.status === 200 ? (
                        <img
                          src={apiCurrentUser.avatar}
                          alt={apiCurrentUser.userName}
                        />
                      ) : (
                        <img src={defaultAvatar} alt="defaultAvatar" />
                      )}
                    </div>
                    <Link
                      className="right"
                      target="_blank"
                      to={`${currentChain?.blockExplorers.default.url}/address/${wallet.accounts[0]}`}
                      title={`View Your Wallet on ${currentChain?.blockExplorers.default.name}`}
                    >
                      <span>
                        {apiCurrentUser && apiCurrentUser.status === 200
                          ? apiCurrentUser.userName
                          : "User not registered"}
                      </span>
                      <small>{wallet.accounts[0]}</small>
                    </Link>
                  </div>
                </div>
                <div className="buttons">
                  <Link to={search}>
                    <img src={facebookImg} alt="" />
                    <span>Share</span>
                  </Link>
                  <Link to={search}>
                    <img src={twitterImg} alt="" />
                    <span>Tweet</span>
                  </Link>
                  <Link to={search}>
                    <img src={invite2Img} alt="" />
                    <span>Invite</span>
                  </Link>
                </div>
              </div>
              <ul
                style={{
                  display: "flex",
                  padding: "0px",
                  textShadow:
                    "1px 1px 0 white, -1px -1px 0 white, 2px -1px 0 white, -1px 2px 0 white, 1px 1px 0 white",
                }}
              >
                {!isLoading ? (
                  prizeWonAmounts.length > 0 &&
                  prizeWonAmounts.map((prize, index) => (
                    <li style={{ padding: "0px 6px" }} key={index}>
                      <Link
                        target="_blank"
                        to={`${currentChain?.blockExplorers.default.url}/tx/${prize.hash}`}
                        title={`View ${
                          prize.prizeIndex === 4
                            ? "1st Jam"
                            : prize.prizeIndex === 3
                            ? "2nd Jam"
                            : prize.prizeIndex === 2
                            ? "3rd Jam"
                            : prize.prizeIndex === 1
                            ? "4th Jam"
                            : prize.prizeIndex === 0 && "Jammy"
                        } Txn on ${currentChain?.blockExplorers.default.name}`}
                      >
                        {prize.prizeIndex === 4 && "1st Jam: "}
                        {prize.prizeIndex === 3 && "2nd Jam: "}
                        {prize.prizeIndex === 2 && "3rd Jam: "}
                        {prize.prizeIndex === 1 && "4th Jam: "}
                        {prize.prizeIndex === 0 && "JAMMY: "}
                        {formatEther(prize.prizeAmount.toString())}{" "}
                        {currentChain?.nativeCurrency.symbol}
                      </Link>
                    </li>
                  ))
                ) : (
                  <li style={{ padding: "0px 6px" }}>
                    <Spinner animation="border"></Spinner>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <img src={popupBgImg} className="popup-bg" alt="" />
        </div>
      </div>
    </>
  );
};

export default WinnerLoserPopup;
