import React, { Suspense } from "react";
// ** Router Import
// import MetaMaskError from "./components/MetaMaskError/MetaMaskError";
import { Route, Routes } from "react-router-dom";
import Register from "./components/Register/Register";
import Lobby from "./pages/Lobby";
import Join from "./pages/Join";
import Game from "./pages/Game";
import Panel from "./pages/Panel";
// import Test from "./pages/Test";
//css
import "./App.css";
import ToastMessage from "./components/ToastMessage/ToastMessage";

function App() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/" element={<Lobby />} />

        <Route exact path="/join" element={<Join />} />
        <Route exact path="/game" element={<Game />} />
        <Route exact path="/panel" element={<Panel />} />
        {/* <Route exact path="/test" element={<Test />} /> */}
        <Route path="*" navigate={<Lobby />} />
      </Routes>

      <Register />
      <ToastMessage />
      {/* <MetaMaskError /> */}
    </Suspense>
  );
}

export default App;
