// ** Redux Imports
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hexToArray } from "../../utility/Utils";
import { formatTimestampToAMPM } from "../../utility/Utils";
import { AES, enc } from "crypto-js";
import {
  formatEther,
  toBeHex,
  toBigInt,
  ZeroAddress,
  Interface,
  isError,
  getBigInt,
} from "ethers";

export const avatarImages = require.context("../../assets/img/avatars");

// const formatingGame = (
//   gameId,
//   game,
//   timestampStartDate,
//   gameTypeName,
//   cardsLength,
//   playerCardsLength,
//   isPrizesClaimed
// ) => {
//   return {
//     gameId,
//     host: game.host,
//     startDate: Number(game.startDate),
//     startDateAMPM: formatTimestampToAMPM(timestampStartDate).time,
//     maxCardsPerPlayer: Number(game.maxCardsPerPlayer),
//     startedPrice: Number(game.cardPrice),
//     gameTypeName,
//     totalCardsSold: Number(game.totalCardsSold),
//     houseShare: Number(game.houseShare),
//     seed: Number(game.seed),
//     cancelled: game.cancelled,
//     gameTransaction: game.txHash || null,
//     totalPlayerCount: Number(game.totalPlayerCount), //TODO: contratta verilmeli
//     playerCardsLength,
//     pot: Number(game.totalCardsSold) * Number(game.cardPrice),
//     isSoldOut:
//       cardsLength &&
//       Number(game.totalCardsSold) + 1 > Math.floor(Number(cardsLength) / 2)
//         ? true
//         : false,
//     maxBuyCardCount: cardsLength
//       ? Math.floor(Number(cardsLength) / 2) - Number(game.totalCardsSold)
//       : 0,
//     isJoined: playerCardsLength && playerCardsLength > 0 ? true : false, //true or false
//     isRefunds: true,
//     gameStatus: null,
//     isPrizesClaimed,
//   };
// };

const formatGame = (
  item,
  gameType,
  cardsLength,
  totalCardsSold,
  totalPlayerCount,
  isJoined,
  gameStatus
) => {
  return {
    gameId: Number(item.args.gameId),
    host: item.args.host,
    startDate: Number(item.args.startDate),
    startDateAMPM: formatTimestampToAMPM(Number(item.args.startDate)).time,
    maxCardsPerPlayer: 4, //Number(item.args.maxCardsPerPlayer), //buna gerek olmayabilir
    startedPrice: Number(item.args.cardPrice),
    // gameTypeName: gameType?.name,
    gameType,
    totalCardsSold,
    gameTransaction: item.transactionHash,
    totalPlayerCount, //: joinedGames.length,
    isSoldOut:
      cardsLength && totalCardsSold + 1 > Math.floor(Number(cardsLength) / 2)
        ? true
        : false,
    maxBuyCardCount: cardsLength
      ? Math.floor(Number(cardsLength) / 2) - totalCardsSold
      : 0,
    isJoined, //: _isJoined ? true : false,
    gameStatus,
  };
};

export const register = createAsyncThunk(
  "register",
  async ({ data }, { dispatch, getState }) => {
    return axios.post(`${process.env.REACT_APP_API}auth/register`, data);
  }
);

export const getApiUser = createAsyncThunk(
  "getApiUser",
  async ({ address }) => {
    const user = await axios
      .get(`${process.env.REACT_APP_API}users/find-by-wallet/${address}`)
      .then((res) => {
        if (res.status === 200) {
          let avatar = null;

          avatarImages.keys().map((image, index) => {
            image
              .substr(2)
              .split(".")[0]
              .toLowerCase()
              .search(res.data.avatar.toLowerCase()) > -1 &&
              (avatar = avatarImages(image));
          });

          return {
            status: res.status,
            refCode: res.data.refCode,
            createdAt: res.data.createdAt,
            userName: res.data.username,
            avatarName: res.data.avatar,
            avatar,
          };
        } else {
          return { status: res.status };
        }
      })
      .catch((err) => {
        return { status: err.status };
      });

    // console.log({ user });
    return user;
  }
);

export const getApiCurrentUser = createAsyncThunk(
  "getApiCurrentUser",
  async ({ address }) => {
    const user = await axios
      .get(`${process.env.REACT_APP_API}users/find-by-wallet/${address}`)
      .then((res) => {
        if (res.status === 200) {
          let avatar = null;

          avatarImages.keys().map((image, index) => {
            image
              .substr(2)
              .split(".")[0]
              .toLowerCase()
              .search(res.data.avatar.toLowerCase()) > -1 &&
              (avatar = avatarImages(image));
          });

          return {
            status: res.status,
            refCode: res.data.refCode,
            createdAt: res.data.createdAt,
            userName: res.data.username,
            avatarName: res.data.avatar,
            avatar,
          };
        } else {
          return { status: res.status };
        }
      })
      .catch((err) => {
        return { status: err.status };
      });

    // console.log({ user });
    return user;
  }
);

export const getEventLogs = createAsyncThunk(
  "getEventLogs",
  async ({ unSigner, currentChain, filters }) => {
    let errors = null;
    let eventLogs = [];
    try {
      const blockRange = currentChain?.rpcUrls.extra.blockRange;
      const blockCreated = currentChain?.contracts.jammy.blockCreated;
      let latestBlock = await unSigner.provider.getBlockNumber();
      const rangeCount =
        Math.round((latestBlock - blockCreated) / blockRange) + 1; //fazladan +1 range daha bakılır garantilenir.
      // console.log({ blockRange, blockCreated, latestBlock, rangeCount });

      for (let i = 0; i < rangeCount; i++) {
        if (latestBlock > blockCreated) {
          const blockRangeEvents = await unSigner.contract?.queryFilter(
            filters, //array (Topic-sets: https://docs.ethers.org/v5/concepts/events/#events--filters)
            latestBlock - blockRange, //fromBlock
            latestBlock //toBlock (latest)
          );
          // console.log({ i, fromBlock: latestBlock - blockRange, toBlock: latestBlock, blockRangeEvents });

          if (blockRangeEvents.length > 0) {
            blockRangeEvents.forEach((event) => {
              eventLogs.push(event);
            });
          }
          latestBlock = latestBlock - blockRange;
        }
      }
    } catch (err) {
      errors = err;
    }

    return { eventLogs, errors };
  }
);

export const getTxFee = createAsyncThunk(
  "getTxFee",
  async ({ unSigner, currentChain, wallet, gasLimitFunction, txValue }) => {
    // joinGame ve redrawCard payable functionlar
    let txFee = null;
    let errors = null;
    let initialError = null;

    const gasLimit = await gasLimitFunction
      .then((res) => {
        return res;
      })
      .catch((err) => {
        initialError = err;
        console.log("GasLimit Error (store:getTxFee):", err);
      });

    if (gasLimit) {
      const feeData = await unSigner.provider
        .getFeeData() //metamask desteklemiyor
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log("feeData Error (store:getTxFee):", err);
        });

      if (feeData) {
        const txVal = txValue ? getBigInt(txValue) : getBigInt(0);
        const transactionFee = Number(
          formatEther(gasLimit * feeData?.gasPrice + txVal)
        );

        console.log(
          "Estimated txFee:",
          transactionFee,
          "= fee:",
          formatEther(gasLimit * feeData?.gasPrice),
          "+ value:",
          formatEther(txVal)
        );
        console.log("User balance:", wallet.balance);
        console.log("isCan:", wallet.balance > transactionFee);

        txFee = transactionFee;
        if (wallet.balance > transactionFee) {
          errors = null;
        } else {
          errors = `Insufficient wallet balance (${wallet?.balance} ${currentChain?.nativeCurrency.symbol})`;
        }
      } else {
        errors = `FeeData could not be retrieved (maybe unSigner.provider error)`;
      }
    } else {
      if (initialError.code === "CALL_EXCEPTION" && !initialError.data) {
        errors = `Gaslimit not calculated. Maybe insufficient wallet balance (${wallet?.balance} ${currentChain?.nativeCurrency.symbol})`;
      } else {
        errors = initialError;
      }
    }
    return { txFee, errors };
  }
);

export const getCreatedGames = createAsyncThunk(
  "getCreatedGames",
  async ({ dispatch, unSigner, host, user, currentChain, contractAbi }) => {
    let createdGames = [];

    const filters = ["GameCreated", "GameCancelled", "PlayerJoined"];
    let errors = null;
    let eventLogs = [];

    const blockRange = currentChain?.rpcUrls.extra.blockRange;
    // const blockRange = 5000;
    const last1Day = 24 * 3600;

    let latestBlockNum = await unSigner.provider.getBlockNumber();
    let getLatestBlock = await unSigner.provider.getBlock(latestBlockNum);

    let fromBlockNum = latestBlockNum - blockRange;
    let getFromBlock = await unSigner.provider.getBlock(fromBlockNum);

    const startLatestBlockTimestamp = getLatestBlock.timestamp;

    do {
      //koşul sağlanmasada bir kere dönmesini istiyorum blockrange den dolayı
      const blockRangeEvents = await unSigner.contract?.queryFilter(
        [filters],
        fromBlockNum, //fromBlock
        latestBlockNum //toBlock (latest)
      );

      if (blockRangeEvents.length > 0) {
        blockRangeEvents.forEach((event) => {
          eventLogs.push(event);
        });
      }
      latestBlockNum = fromBlockNum; //bir sonraki block aralıgına taşınır.
      getLatestBlock = getFromBlock; //bir sonraki block aralıgına taşınır.
      fromBlockNum = latestBlockNum - blockRange;
      getFromBlock = await unSigner.provider.getBlock(fromBlockNum);
    } while (startLatestBlockTimestamp - getFromBlock.timestamp < last1Day);

    const cancelledGameLogs = eventLogs.filter(
      (item) => item.eventName === "GameCancelled"
    );
    const createdGameLogs = eventLogs.filter(
      (item) => item.eventName === "GameCreated"
    );
    const joinedGameLogs = eventLogs.filter(
      (item) => item.eventName === "PlayerJoined"
    );

    let gameListLogs = createdGameLogs.filter(
      (createdItem) =>
        Date.parse(Date()) <
          Date.parse(new Date(Number(createdItem.args.startDate) * 1000)) &&
        !cancelledGameLogs.find(
          (cancelledItem) =>
            Number(cancelledItem.args.gameId) ===
            Number(createdItem.args.gameId)
        )
    );

    // Host için filtreleme
    if (host) {
      gameListLogs = gameListLogs.filter(
        ({ args }) => args.host.toLowerCase() === host
      );
    }

    // User join status için filtreleme
    // const _isJoinedGenaral = null;
    // if (user) {
    //   _isJoinedGenaral = gameListLogs.filter((gameItem) =>
    //     joinedGameLogs.find(
    //       (joinedItem) =>
    //         joinedItem.args.player.toLowerCase() === user &&
    //         Number(joinedItem.args.gameId) === Number(gameItem.args.gameId)
    //     )
    //   ).length;
    // }

    console.log("created gameListLogs:", gameListLogs);

    const gameTypes = await dispatch(getGameTypes({ dispatch, currentChain }))
      .then((result) => {
        if (!result.payload.error) {
          return result.payload.gameTypes;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const cardsLength = await dispatch(getInfo({ unSigner }))
      .then((result) => {
        if (result.payload) {
          return result.payload.cardsLength;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    gameListLogs.forEach((item) => {
      const gameType = gameTypes.find(
        (typeItem) => typeItem.price === formatEther(item.args.cardPrice)
      );

      const joinedGames = joinedGameLogs.filter(
        (joinedItem) =>
          Number(joinedItem.args.gameId) === Number(item.args.gameId)
      );

      const _isJoined = joinedGames.find(
        ({ args }) => args.player.toLowerCase() === user
      );

      let totalCardsSold = 0;
      joinedGames.forEach((g) => {
        totalCardsSold += Number(g.args.cardsCount);
      });

      createdGames.push(
        formatGame(
          item,
          gameType,
          cardsLength,
          totalCardsSold,
          joinedGames.length, //totalPlayerCount
          _isJoined ? true : false, //isJoined
          1 //gameStatus
        )
      );
    });

    // let gameCounter = Number(await unSigner.contract.gameCounter());

    // for (gameCounter; gameCounter >= 1; gameCounter--) {
    //   const dateTimeNow = Date.parse(Date()) / 1000;
    //   const game = await unSigner.contract.games(gameCounter);
    //   const timestampStartDate = Number(game.startDate);

    //   if (
    //     new Date(timestampStartDate * 1000).getDate() <=
    //     new Date(dateTimeNow * 1000).getDate()
    //   ) {
    //     let gameTypeName = null;
    //     gameTypes.forEach((type) => {
    //       if (type.price === formatEther(game.cardPrice)) {
    //         gameTypeName = type.name;
    //       }
    //     });

    //     const info = await dispatch(
    //       getInfo({ unSigner, gameId: gameCounter, user })
    //     )
    //       .then((result) => {
    //         if (result.payload) {
    //           return result.payload;
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });

    //     const cancelled = Number(game.cancelled);
    //     if (!host || (host && host.toLowerCase() === game.host.toLowerCase())) {
    //       if (!cancelled && dateTimeNow < timestampStartDate) {
    //         createdGames.push(
    //           formatingGame(
    //             gameCounter,
    //             game,
    //             timestampStartDate,
    //             gameTypeName,
    //             info.cardsLength,
    //             info.playerCardsLength
    //           )
    //         );
    //       }
    //     }
    //   } else {
    //     break;
    //   }
    // }
    return { createdGames, error: null };
  }
);

export const getReadyStartedGames = createAsyncThunk(
  "getReadyStartedGames",
  async ({ dispatch, unSigner, host, user, currentChain }) => {
    let readyGames = [];
    let startedGames = [];

    const filters = [
      "GameCreated",
      "GameCancelled",
      "PlayerJoined",
      "GameStarted",
      "GameEnds",
    ];
    let errors = null;
    let eventLogs = [];

    const blockRange = currentChain?.rpcUrls.extra.blockRange;
    // const blockRange = 5000;
    const last1Day = 27 * 3600;

    let latestBlockNum = await unSigner.provider.getBlockNumber();
    let getLatestBlock = await unSigner.provider.getBlock(latestBlockNum);

    let fromBlockNum = latestBlockNum - blockRange;
    let getFromBlock = await unSigner.provider.getBlock(fromBlockNum);

    const startLatestBlockTimestamp = getLatestBlock.timestamp;

    do {
      //koşul sağlanmasada bir kere dönmesini istiyorum blockrange den dolayı
      const blockRangeEvents = await unSigner.contract?.queryFilter(
        [filters],
        fromBlockNum, //fromBlock
        latestBlockNum //toBlock (latest)
      );

      if (blockRangeEvents.length > 0) {
        blockRangeEvents.forEach((event) => {
          eventLogs.push(event);
        });
      }
      latestBlockNum = fromBlockNum; //bir sonraki block aralıgına taşınır.
      getLatestBlock = getFromBlock; //bir sonraki block aralıgına taşınır.
      fromBlockNum = latestBlockNum - blockRange;
      getFromBlock = await unSigner.provider.getBlock(fromBlockNum);
    } while (startLatestBlockTimestamp - getFromBlock.timestamp < last1Day);

    const cancelledGameLogs = eventLogs.filter(
      (item) => item.eventName === "GameCancelled"
    );
    const createdGameLogs = eventLogs.filter(
      (item) => item.eventName === "GameCreated"
    );
    const joinedGameLogs = eventLogs.filter(
      (item) => item.eventName === "PlayerJoined"
    );
    const startedGameLogs = eventLogs.filter(
      (item) => item.eventName === "GameStarted"
    );
    const endedGameLogs = eventLogs.filter(
      (item) => item.eventName === "GameEnds"
    );

    const expDur = Number(await unSigner.contract.EXPIRATION_DURATION());

    let gameListLogs = createdGameLogs.filter(
      (createdItem) =>
        Date.parse(Date()) >=
          Date.parse(new Date(Number(createdItem.args.startDate) * 1000)) &&
        Date.parse(Date()) <
          Date.parse(new Date(Number(createdItem.args.startDate) * 1000)) +
            expDur * 1000 &&
        !cancelledGameLogs.find(
          (cancelledItem) =>
            Number(cancelledItem.args.gameId) ===
            Number(createdItem.args.gameId)
        )
    );

    // Host için filtreleme
    if (host) {
      gameListLogs = gameListLogs.filter(
        ({ args }) => args.host.toLowerCase() === host
      );
    }

    console.log("readystarted gameListLogs:", gameListLogs);

    const gameTypes = await dispatch(getGameTypes({ dispatch, currentChain }))
      .then((result) => {
        if (!result.payload.error) {
          return result.payload.gameTypes;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const cardsLength = await dispatch(getInfo({ unSigner }))
      .then((result) => {
        if (result.payload) {
          return result.payload.cardsLength;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    gameListLogs.forEach((item) => {
      const gameType = gameTypes.find(
        (typeItem) => typeItem.price === formatEther(item.args.cardPrice)
      );

      const joinedGames = joinedGameLogs.filter(
        (joinedItem) =>
          Number(joinedItem.args.gameId) === Number(item.args.gameId)
      );

      const _isJoined = joinedGames.find(
        ({ args }) => args.player.toLowerCase() === user
      );

      let totalCardsSold = 0;
      joinedGames.forEach((g) => {
        totalCardsSold += Number(g.args.cardsCount);
      });

      const _isStarted = startedGameLogs.find(
        ({ args }) => Number(args.gameId) === Number(item.args.gameId)
      );

      const _isEnded = endedGameLogs.find(
        ({ args }) => Number(args.gameId) === Number(item.args.gameId)
      );

      !_isStarted &&
        readyGames.push(
          formatGame(
            item,
            gameType,
            cardsLength,
            totalCardsSold,
            joinedGames.length, //totalPlayerCount
            _isJoined ? true : false, //isJoined
            2 //gameStatus (3=STARTED, 2=READY)
          )
        );
      _isStarted &&
        !_isEnded &&
        startedGames.push(
          formatGame(
            item,
            gameType,
            cardsLength,
            totalCardsSold,
            joinedGames.length, //totalPlayerCount
            _isJoined ? true : false, //isJoined
            3 //gameStatus (3=STARTED, 2=READY)
          )
        );
    });

    return { readyGames, startedGames, error: null };
  }
);

// export const getReadyGames = createAsyncThunk(
//   "getReadyGames",
//   async ({ dispatch, unSigner, host, user, currentChain }) => {
//     let readyGames = [];

//     const gameTypes = await dispatch(getGameTypes({ dispatch, currentChain }))
//       .then((result) => {
//         if (!result.payload.error) {
//           return result.payload.gameTypes;
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });

//     let gameCounter = Number(await unSigner.contract.gameCounter());

//     for (gameCounter; gameCounter >= 1; gameCounter--) {
//       const dateTimeNow = Date.parse(Date()) / 1000;
//       const game = await unSigner.contract.games(gameCounter);
//       const timestampStartDate = Number(game.startDate);

//       const gameStatus = await dispatch(
//         checkGameStatus({ unSigner, gameId: gameCounter })
//       )
//         .then((result) => {
//           return result.payload;
//         })
//         .catch((error) => {
//           console.log(error);
//         });

//       const expirationDuration = Number(
//         await unSigner.contract.EXPIRATION_DURATION()
//       );
//       if (
//         new Date(timestampStartDate * 1000).getDate() <=
//           new Date(dateTimeNow * 1000).getDate() ||
//         (new Date(timestampStartDate * 1000).getDate() >
//           new Date(dateTimeNow * 1000).getDate() &&
//           dateTimeNow < timestampStartDate + expirationDuration)
//       ) {
//         let gameTypeName = null;
//         gameTypes.forEach((type) => {
//           if (type.price === formatEther(game.cardPrice)) {
//             gameTypeName = type.name;
//           }
//         });

//         const info = await dispatch(
//           getInfo({ unSigner, gameId: gameCounter, user })
//         )
//           .then((result) => {
//             if (result.payload) {
//               return result.payload;
//             }
//           })
//           .catch((error) => {
//             console.log(error);
//           });

//         const cancelled = Number(game.cancelled);
//         if (!host || (host && host.toLowerCase() === game.host.toLowerCase())) {
//           if (!cancelled && gameStatus === 2) {
//             readyGames.push(
//               formatingGame(
//                 gameCounter,
//                 game,
//                 timestampStartDate,
//                 gameTypeName,
//                 info.cardsLength,
//                 info.playerCardsLength
//               )
//             );
//           }
//         }
//       } else {
//         break;
//       }
//     }
//     return { readyGames, error: null };
//   }
// );

// export const getStartedGames = createAsyncThunk(
//   "getStartedGames",
//   async ({ dispatch, unSigner, host, user, currentChain }) => {
//     let startedGames = [];

//     const gameTypes = await dispatch(getGameTypes({ dispatch, currentChain }))
//       .then((result) => {
//         if (!result.payload.error) {
//           return result.payload.gameTypes;
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });

//     let gameCounter = Number(await unSigner.contract.gameCounter());

//     for (gameCounter; gameCounter >= 1; gameCounter--) {
//       const dateTimeNow = Date.parse(Date()) / 1000;
//       const game = await unSigner.contract.games(gameCounter);
//       const gameSeed = game.seed;
//       const timestampStartDate = Number(game.startDate);

//       const gameStatus = await dispatch(
//         checkGameStatus({ unSigner, gameId: gameCounter })
//       )
//         .then((result) => {
//           return result.payload;
//         })
//         .catch((error) => {
//           console.log(error);
//         });

//       const expirationDuration = Number(
//         await unSigner.contract.EXPIRATION_DURATION()
//       );
//       if (
//         new Date(timestampStartDate * 1000).getDate() <=
//           new Date(dateTimeNow * 1000).getDate() ||
//         (new Date(timestampStartDate * 1000).getDate() >
//           new Date(dateTimeNow * 1000).getDate() &&
//           dateTimeNow < timestampStartDate + expirationDuration &&
//           gameSeed > 0)
//       ) {
//         let gameTypeName = null;
//         gameTypes.forEach((type) => {
//           if (type.price === formatEther(game.cardPrice)) {
//             gameTypeName = type.name;
//           }
//         });

//         const info = await dispatch(
//           getInfo({ unSigner, gameId: gameCounter, user })
//         )
//           .then((result) => {
//             if (result.payload) {
//               return result.payload;
//             }
//           })
//           .catch((error) => {
//             console.log(error);
//           });

//         const cancelled = Number(game.cancelled);
//         if (!host || (host && host.toLowerCase() === game.host.toLowerCase())) {
//           if (!cancelled && gameStatus === 3) {
//             startedGames.push(
//               formatingGame(
//                 gameCounter,
//                 game,
//                 timestampStartDate,
//                 gameTypeName,
//                 info.cardsLength,
//                 info.playerCardsLength
//               )
//             );
//           }
//         }
//       } else {
//         break;
//       }
//     }
//     return { startedGames, error: null };
//   }
// );

// export const getEndedGames = createAsyncThunk(
//   "getEndedGames",
//   async ({ dispatch, unSigner, host, user, currentChain }) => {
//     let endedGames = [];

//     const gameTypes = await dispatch(getGameTypes({ dispatch, currentChain }))
//       .then((result) => {
//         if (!result.payload.error) {
//           return result.payload.gameTypes;
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });

//     let gameCounter = Number(await unSigner.contract.gameCounter());

//     for (gameCounter; gameCounter >= 1; gameCounter--) {
//       const game = await unSigner.contract.games(gameCounter);
//       const timestampStartDate = Number(game.startDate);

//       const gameStatus = await dispatch(
//         checkGameStatus({ unSigner, gameId: gameCounter })
//       )
//         .then((result) => {
//           return result.payload;
//         })
//         .catch((error) => {
//           console.log(error);
//         });

//       let gameTypeName = null;
//       gameTypes.forEach((type) => {
//         if (type.price === formatEther(game.cardPrice)) {
//           gameTypeName = type.name;
//         }
//       });

//       const info = await dispatch(
//         getInfo({ unSigner, gameId: gameCounter, user })
//       )
//         .then((result) => {
//           if (result.payload) {
//             return result.payload;
//           }
//         })
//         .catch((error) => {
//           console.log(error);
//         });

//       const cancelled = Number(game.cancelled);
//       const isPrizesClaimed = await unSigner.contract.prizesClaimed(
//         gameCounter
//       );
//       if (!host || (host && host.toLowerCase() === game.host.toLowerCase())) {
//         if (!cancelled && gameStatus === 4) {
//           endedGames.push(
//             formatingGame(
//               gameCounter,
//               game,
//               timestampStartDate,
//               gameTypeName,
//               info.cardsLength,
//               info.playerCardsLength,
//               isPrizesClaimed
//             )
//           );
//         }
//       }
//     }
//     return { endedGames, error: null };
//   }
// );

// export const getExpiredGames = createAsyncThunk(
//   "getExpiredGames",
//   async ({ dispatch, unSigner, host, user, currentChain }) => {
//     let expiredGames = [];

//     const gameTypes = await dispatch(getGameTypes({ dispatch, currentChain }))
//       .then((result) => {
//         if (!result.payload.error) {
//           return result.payload.gameTypes;
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });

//     let gameCounter = Number(await unSigner.contract.gameCounter());

//     for (gameCounter; gameCounter >= 1; gameCounter--) {
//       const dateTimeNow = Date.parse(Date()) / 1000;
//       const game = await unSigner.contract.games(gameCounter);
//       const timestampStartDate = Number(game.startDate);

//       const gameStatus = await dispatch(
//         checkGameStatus({ unSigner, gameId: gameCounter })
//       )
//         .then((result) => {
//           return result.payload;
//         })
//         .catch((error) => {
//           console.log(error);
//         });

//       let gameTypeName = null;
//       gameTypes.forEach((type) => {
//         if (type.price === formatEther(game.cardPrice)) {
//           gameTypeName = type.name;
//         }
//       });

//       const info = await dispatch(
//         getInfo({ unSigner, gameId: gameCounter, user })
//       )
//         .then((result) => {
//           if (result.payload) {
//             return result.payload;
//           }
//         })
//         .catch((error) => {
//           console.log(error);
//         });

//       const cancelled = Number(game.cancelled);
//       if (!host || (host && host.toLowerCase() === game.host.toLowerCase())) {
//         if (!cancelled && gameStatus === 5) {
//           expiredGames.push(
//             formatingGame(
//               gameCounter,
//               game,
//               timestampStartDate,
//               gameTypeName,
//               info.cardsLength,
//               info.playerCardsLength
//             )
//           );
//         }
//       }
//     }
//     return { expiredGames, error: null };
//   }
// );

// export const getCancelledGames = createAsyncThunk(
//   "getCancelledGames",
//   async ({ dispatch, unSigner, host, user, currentChain }) => {
//     let cancelledGames = [];

//     const gameTypes = await dispatch(getGameTypes({ dispatch, currentChain }))
//       .then((result) => {
//         if (!result.payload.error) {
//           return result.payload.gameTypes;
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });

//     let gameCounter = Number(await unSigner.contract.gameCounter());

//     for (gameCounter; gameCounter >= 1; gameCounter--) {
//       const dateTimeNow = Date.parse(Date()) / 1000;
//       const game = await unSigner.contract.games(gameCounter);
//       const timestampStartDate = Number(game.startDate);

//       const gameStatus = await dispatch(
//         checkGameStatus({ unSigner, gameId: gameCounter })
//       )
//         .then((result) => {
//           return result.payload;
//         })
//         .catch((error) => {
//           console.log(error);
//         });

//       let gameTypeName = null;
//       gameTypes.forEach((type) => {
//         if (type.price === formatEther(game.cardPrice)) {
//           gameTypeName = type.name;
//         }
//       });

//       const info = await dispatch(
//         getInfo({ unSigner, gameId: gameCounter, user })
//       )
//         .then((result) => {
//           if (result.payload) {
//             return result.payload;
//           }
//         })
//         .catch((error) => {
//           console.log(error);
//         });

//       if (!host || (host && host.toLowerCase() === game.host.toLowerCase())) {
//         if (gameStatus === 6) {
//           cancelledGames.push(
//             formatingGame(
//               gameCounter,
//               game,
//               timestampStartDate,
//               gameTypeName,
//               info.cardsLength,
//               info.playerCardsLength
//             )
//           );
//         }
//       }
//     }
//     return { cancelledGames, error: null };
//   }
// );

// export const userGameDetails = createAsyncThunk(
//   "userGameDetails",
//   async ({ unSigner, gameId, user }) => {
//     const isRefunds = await unSigner.contract.refunds(gameId, user);
//     return { isRefunds };
//   }
// );

// Host Panel

export const createGameTimes = createAsyncThunk("createGameTimes", async () => {
  const nowtimestamp = Date.parse(Date()) / 1000;
  let selectOptions = [];
  for (let i = 5; ; i++) {
    if (
      new Date((nowtimestamp + 60 * i) * 1000).getDate() ===
      new Date(nowtimestamp * 1000).getDate()
    ) {
      //gün değişmedikçe döner
      const parseTime = formatTimestampToAMPM(nowtimestamp + 60 * i).time.split(
        "-"
      );
      selectOptions.push(`${parseTime[0]}:${parseTime[1]} ${parseTime[2]}`);
    } else {
      break;
    }
  }
  return selectOptions;
});

export const checkGameStatus = createAsyncThunk(
  "checkGameStatus",
  async ({ unSigner, gameId }) => {
    const gameStatus = Number(await unSigner.contract.gameStatus(gameId));
    return gameStatus;
    // const jammyWon = (await unSigner.contract.gamePrizes(gameId, 0))[1];
    // const game = await unSigner.contract.games(gameId);
    // const nowTimestamp = Math.floor(Date.now() / 1000);
    // const gameTimestamp = Number(game.startDate);
    // const gameCancelled = game.cancelled;
    // const gameSeed = game.seed;

    // if (gameTimestamp === 0) {
    //   //GameStatus.INVALID
    //   return 0;
    // }
    // if (jammyWon) {
    //   //GameStatus.ENDED
    //   return 4;
    // }
    // if (gameCancelled) {
    //   //GameStatus.CANCELLED
    //   return 6;
    // }
    // if (nowTimestamp < gameTimestamp) {
    //   //GameStatus.CREATED
    //   return 1;
    // }
    // const expirationDuration = Number(
    //   await unSigner.contract.EXPIRATION_DURATION()
    // );
    // if (
    //   nowTimestamp > gameTimestamp &&
    //   nowTimestamp < gameTimestamp + expirationDuration
    // ) {
    //   if (gameSeed > 0) {
    //     //GameStatus.STARTED
    //     return 3;
    //   }
    //   return 2; //GameStatus.READY
    // }
    // return 5; //GameStatus.EXPIRED
  }
);

export const getInfo = createAsyncThunk(
  "getInfo",
  async ({ unSigner, gameId, user, prizeIndex }) => {
    let info = null;
    let maxNumber = 0;
    if (unSigner) {
      maxNumber = Number(await unSigner.contract.MAX_NUMBER());
      info = await unSigner.contract.getGameInfo(
        gameId || 0,
        user || ZeroAddress,
        prizeIndex || 0
      );
    }

    return {
      _gameId: gameId || 0,
      _user: user || ZeroAddress,
      _prizeIndex: prizeIndex || 0,
      availableNumbersLength: Number(info.availableNumbersLength),
      gamePrizesLength: Number(info.gamePrizesLength),
      prizeWinnersLength: Number(info.prizeWinnersLength),
      numbersLength: Number(info.numbersLength),
      revealedNumberLength: maxNumber - Number(info.numbersLength),
      cardsLength: Number(info.cardsLength),
      playerCardsLength: Number(info.playerCardsLength), //isJoined
    };
  }
);

// export const setAllCards = createAsyncThunk(
//   "setAllCards",
//   async ({ dispatch, unSigner }) => {
//     sessionStorage.removeItem("allCards");
//     sessionStorage.setItem("syncAllCards", false);

//     let allCards = [];
//     const getCards = await unSigner.contract.getCards();
//     getCards.forEach((card, cardIndex) => {
//       allCards.push({
//         cardIndex,
//         bigIntCard: card,
//         hexCard: toBeHex(card),
//         arrayCard: hexToArray(toBeHex(card)),
//       });
//     });

//     sessionStorage.setItem("allCards", JSON.stringify(allCards));
//     sessionStorage.setItem("syncAllCards", true);
//     console.log("> Loaded all cards");
//     return allCards;

//     // const info = await dispatch(getInfo({ unSigner }))
//     //   .then((result) => {
//     //     if (result.payload) {
//     //       return result.payload;
//     //     }
//     //   })
//     //   .catch((error) => {
//     //     console.log(error);
//     //   });
//     // const cardCount = Number(info.cardsLength);

//     // sessionStorage.removeItem("allCards");
//     // sessionStorage.setItem("syncAllCards", false);
//     // let allCards = [];
//     // try {
//     //   for (let cardIndex = 0; cardIndex < cardCount; cardIndex++) {
//     //     const card = toBeHex(await unSigner.contract.cards(cardIndex));
//     //     allCards.push({ cardIndex, hexCard: card, arrayCard: hexToArray(card) });
//     //   }
//     //   sessionStorage.setItem("allCards", JSON.stringify(allCards));
//     //   sessionStorage.setItem("syncAllCards", true);
//     // } catch (err) {
//     //   console.error("setAllCards:", err);
//     // }
//     // return allCards;
//   }
// );

export const getGameTypes = createAsyncThunk(
  "getGameTypes",
  async ({ dispatch, currentChain }) => {
    const testGameTypes = [
      { id: 1, name: "BRONZE", price: "0.0002" },
      { id: 2, name: "GOLD", price: "0.0004" },
      { id: 3, name: "SILVER", price: "0.0006" },
      { id: 4, name: "DIAMOND", price: "0.0008" },
    ];
    if (
      !sessionStorage.getItem(
        `${currentChain.contracts.jammy.address}:${currentChain.id}:gameTypes`
      )
    ) {
      dispatch(
        setStorage({
          key: "gameTypes",
          value: testGameTypes,
          type: "session",
          currentChain,
        })
      );
    }
    return { gameTypes: testGameTypes, error: null };
  }
);

export const setStorage = createAsyncThunk(
  "setStorage",
  async ({ key, value, type, currentChain }) => {
    // Encrypt
    const encryptedValue = AES.encrypt(
      JSON.stringify(value, (_key, _val) =>
        typeof _val === "bigint" ? _val.toString() + "n" : _val
      ),
      process.env.REACT_APP_CRYPTOKEY
    );

    if (type === "local") {
      localStorage.setItem(
        `${currentChain.contracts.jammy.address}:${currentChain.id}:${key}`,
        encryptedValue.toString()
      );
    } else if (type === "session") {
      sessionStorage.setItem(
        `${currentChain.contracts.jammy.address}:${currentChain.id}:${key}`,
        encryptedValue.toString()
      );
    }

    return [{ result: true, encryptedValue }];
  }
);

export const getStorage = createAsyncThunk(
  "getStorage",
  async ({ key, type, currentChain }) => {
    let getValue = null;
    if (type === "local") {
      getValue = localStorage.getItem(
        `${currentChain.contracts.jammy.address}:${currentChain.id}:${key}`
      );
    } else if (type === "session") {
      getValue = sessionStorage.getItem(
        `${currentChain.contracts.jammy.address}:${currentChain.id}:${key}`
      );
    }

    if (getValue) {
      // Decrypt
      const decryptedValue = AES.decrypt(
        getValue,
        process.env.REACT_APP_CRYPTOKEY
      ).toString(enc.Utf8);

      return JSON.parse(decryptedValue, (_key, _val) => {
        if (typeof _val === "string" && /^\d+n$/.test(_val)) {
          return toBigInt(_val.slice(0, -1));
        }
        return _val;
      });
    } else {
      return null;
    }
  }
);

export const rootStore = createSlice({
  name: "rootStore",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getApiCurrentUser.fulfilled, (state, action) => {
      state.apiCurrentUser = action.payload;
    });
    builder.addCase(getGameTypes.fulfilled, (state, action) => {
      state.gameTypes = action.payload.gameTypes;
    });
    builder.addCase(checkGameStatus.fulfilled, (state, action) => {
      state.gameStatus = action.payload;
    });
    builder.addCase(getInfo.fulfilled, (state, action) => {
      state.contractInfo = action.payload;
    });
  },
});

export default rootStore.reducer;
