// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { formatEther, parseEther, toBeHex } from "ethers";
import { hexToArray } from "../../../utility/Utils";
import { setStorage, getInfo } from "../../store";

export const allStorageClear = createAsyncThunk(
  "allStorageClear",
  async ({ gameId, user, currentChain }) => {
    const networkKey = `${currentChain.contracts.jammy.address}:${currentChain.id}`;

    localStorage.removeItem(`${networkKey}:redraw:${gameId}:${user}:0`);
    localStorage.removeItem(`${networkKey}:redraw:${gameId}:${user}:1`);
    localStorage.removeItem(`${networkKey}:redraw:${gameId}:${user}:2`);
    localStorage.removeItem(`${networkKey}:redraw:${gameId}:${user}:3`);
    // localStorage.removeItem(`closeNumber-${gameId}-0`);
    // localStorage.removeItem(`closeNumber-${gameId}-1`);
    // localStorage.removeItem(`closeNumber-${gameId}-2`);
    // localStorage.removeItem(`closeNumber-${gameId}-3`);
    localStorage.removeItem(`${networkKey}:prizes:${gameId}:4`);
    localStorage.removeItem(`${networkKey}:prizes:${gameId}:3`);
    localStorage.removeItem(`${networkKey}:prizes:${gameId}:2`);
    localStorage.removeItem(`${networkKey}:prizes:${gameId}:1`);
    localStorage.removeItem(`${networkKey}:prizes:${gameId}:0`);
    localStorage.removeItem(`${networkKey}:join:${gameId}:${user}`);
    localStorage.removeItem(`${networkKey}:startgame:${gameId}`);
    localStorage.removeItem(`${networkKey}:revealNumbers:${gameId}`);
    localStorage.removeItem(`${networkKey}:jammy:${gameId}`);

    return true;
  }
);

export const urlParams = createAsyncThunk(
  "urlParams",
  async ({ dispatch, unSigner, user, searchParams }) => {
    const gameId = Number(searchParams.get("gameId"));

    const info = await dispatch(
      getInfo({ unSigner, user, gameId: Number(gameId) })
    )
      .then((result) => {
        if (result.payload) {
          return result.payload;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const cardCount = Number(info.playerCardsLength);
    return { gameId, cardCount };
  }
);

export const fetchGame = createAsyncThunk(
  "fetchGame",
  async ({ dispatch, unSigner, gameId }) => {
    const game = await unSigner.contract.games(gameId);
    return game;
    // const gameHost = games.host.toLowerCase();
    // const gametimestamp = Number(games.startDate);
    // const gamecardprice = Number(games.cardPrice); //TODO: Number dönüşümü olmayabilir bakılacak
    // const maxCardsPerPlayer = Number(games.maxCardsPerPlayer);
    // const totalCardsSold = Number(games.totalCardsSold);
    // const houseShare = Number(games.houseShare);
    // const gameSeed = Number(games.seed);
    // const cancelled = games.cancelled;
    // const totalPlayerCount = Number(games.totalPlayerCount);

    // const game = [
    //   gameId, //index 0
    //   gametimestamp, //index 1
    //   gamecardprice, //index 2
    //   maxCardsPerPlayer, //index 3
    //   totalCardsSold, //index 4
    //   gameSeed, //index 5
    //   houseShare, //index 6
    //   gameHost, //index 7
    //   cancelled, //index 8
    //   totalPlayerCount,
    // ];
    // return game;
  }
);

export const getCard = createAsyncThunk(
  "getCard",
  async ({ dispatch, gameId, unSigner, user, playerCardIndex }) => {
    let cardIndex = null;
    let playerCard = null;
    let errors = [];

    try {
      playerCard = await unSigner.contract.playerCards(
        gameId,
        user,
        playerCardIndex
      );

      if (Number(toBeHex(playerCard)) !== 1) {
        const allCards = await unSigner.contract.getCards();
        allCards.forEach((card, index) => {
          if (card === playerCard) {
            // console.log(index, card, playerCard);
            cardIndex = index;
          }
        });
        // localStorage.removeItem(`${currentChain.contracts.jammy.address}:${currentChain.id}:redraw:${gameId}:${user}:${playerCardIndex}`);

        return {
          cardIndex,
          cardNumbers: hexToArray(toBeHex(playerCard)),
          errors: null,
        };
      } else {
        errors.push("card not ready");
        return { cardIndex: null, cardNumbers: null, errors: errors };
      }
    } catch (error) {
      console.log(error);

      errors.push("card not found");
      return { cardIndex, cardNumbers: null, errors: errors };
    }
  }
);

export const redrawCard = createAsyncThunk(
  "redrawCard",
  async ({
    dispatch,
    gameId,
    signer,
    user,
    playerCardIndex,
    gameStatus,
    game,
    currentChain,
  }) => {
    let errors = [];
    let hash = null;

    if (gameStatus === 1) {
      try {
        //TODO: hata verebilir game.cardPrice
        const redrawValue = formatEther(
          (Number(game.cardPrice) / 2).toString()
        );
        console.log(redrawValue);
        const tx = await signer.contract.redrawCard(gameId, playerCardIndex, {
          value: parseEther(redrawValue),
        });
        dispatch(
          setStorage({
            key: `redraw:${gameId}:${user}:${playerCardIndex}`,
            value: "start-",
            type: "local",
            currentChain,
          })
        );
        const receipt = await tx.wait();
        console.log("receipt-redrawCard:", receipt);
        const eLog = receipt.logs.find(
          (eventLog) => eventLog.eventName === "RequestSent"
        );
        if (eLog) {
          hash = receipt.hash;
          dispatch(
            setStorage({
              key: `redraw:${gameId}:${user}:${playerCardIndex}`,
              value: `wait-${eLog.args.requestId}`,
              type: "local",
              currentChain,
            })
          );
          localStorage.removeItem(
            `${currentChain.contracts.jammy.address}:${currentChain.id}:card-${gameId}-${user}-${playerCardIndex}`
          );
        }
      } catch (err) {
        errors.push(err.reason || err.message);
        localStorage.removeItem(
          `${currentChain.contracts.jammy.address}:${currentChain.id}:redraw:${gameId}:${user}:${playerCardIndex}`
        );
      }
    } else {
      errors.push("redrawCards: wrongGameStatus");
    }
    return { hash, errors };
  }
);

export const getLogsStartedBlock = createAsyncThunk(
  "getLogsStartedBlock",
  async ({ unSigner, currentChain, gameId }) => {
    //startedBlock hiç set edilmediyse 0 döner
    const blockCreated = currentChain?.contracts.jammy.blockCreated;
    const sbKey = `sbn:${currentChain.contracts.jammy.address}:${currentChain.id}:${gameId}`;
    if (Number(sessionStorage.getItem(sbKey)) > blockCreated) {
      return {
        num: Number(sessionStorage.getItem(sbKey)),
        errors: null,
      };
    }

    let errors = null;
    let num = 0;
    try {
      let latestBlock = await unSigner.provider.getBlockNumber();
      const blockRange = currentChain?.rpcUrls.extra.blockRange;
      const rangeCount =
        Math.round((latestBlock - blockCreated) / blockRange) + 1;

      for (let i = 0; i < rangeCount; i++) {
        if (latestBlock > blockCreated) {
          const blockRangeEvents = await unSigner.contract?.queryFilter(
            "GameStarted",
            latestBlock - blockRange,
            latestBlock
          );

          // console.log(i, blockRangeEvents);

          if (blockRangeEvents.length > 0) {
            const eLog = blockRangeEvents.find(
              (event) =>
                event.eventName === "GameStarted" &&
                Number(event.args.gameId) === gameId
            );
            if (eLog) {
              num = Number(eLog.blockNumber);
              sessionStorage.setItem(sbKey, num);
              return { num, errors };
            }
          }
          latestBlock = latestBlock - blockRange;
        }
      }
    } catch (err) {
      errors = err;
      return { num, errors };
    }
    return { num, errors };
  }
);

//Winning, WinnerLoser history
export const getLogsPrizeWon = createAsyncThunk(
  "getLogsPrizeWon",
  async ({ dispatch, unSigner, currentChain, gameId }) => {
    let errors = null;
    let eventLogs = [];
    try {
      const startedBlockNum = await dispatch(
        getLogsStartedBlock({
          unSigner,
          currentChain,
          gameId,
        })
      ).then((result) => {
        return result.payload.num;
      });

      console.log("startedBlockNum:", startedBlockNum);

      const blockRange = currentChain?.rpcUrls.extra.blockRange;
      const blockCreated = currentChain?.contracts.jammy.blockCreated;
      let _allPrizeWon = [];

      if (startedBlockNum > blockCreated) {
        let latestBlock = await unSigner.provider.getBlockNumber();
        if (latestBlock - startedBlockNum > blockRange) {
          const rangeCount =
            Math.floor(
              //TODO: round olabilir test edilip bakılacak
              (latestBlock - startedBlockNum) / blockRange
            ) + 1;
          for (let i = 0; i < rangeCount; i++) {
            if (latestBlock > startedBlockNum) {
              const blockRangeEvents = await unSigner.contract?.queryFilter(
                "PrizeWon",
                latestBlock - blockRange,
                latestBlock
              );
              if (blockRangeEvents.length > 0) {
                blockRangeEvents.forEach((event) => {
                  _allPrizeWon.push(event);
                });
              }
              latestBlock = latestBlock - blockRange;
            }
          }
        } else {
          _allPrizeWon = await unSigner.contract?.queryFilter(
            "PrizeWon",
            startedBlockNum,
            "latest"
          );
        }
      } else {
        _allPrizeWon = await unSigner.contract?.queryFilter(
          "PrizeWon",
          -blockRange,
          "latest"
        );
      }

      const allPrizeWon = _allPrizeWon.filter(
        (event) =>
          event.eventName === "PrizeWon" && Number(event.args.gameId) === gameId
      );
      console.log("PrizeWon", { _allPrizeWon, allPrizeWon });
      eventLogs = allPrizeWon;
    } catch (err) {
      errors = err;
    }
    return { eventLogs, errors };
  }
);

export const checkGamePrizes = createAsyncThunk(
  "checkGamePrizes",
  async ({ dispatch, unSigner, gameId, user }) => {
    let prizesArray = [];

    for (let prizeIndex = 0; prizeIndex < 5; prizeIndex++) {
      //prizeIndex: 0=jammy, 1=jam4, 2=jam3, 3=jam2, 4=jam1
      const info = await dispatch(
        getInfo({ unSigner, gameId, user, prizeIndex })
      )
        .then((result) => {
          if (result.payload) {
            return result.payload;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      let winners = [];
      const isWon = (await unSigner.contract.gamePrizes(gameId, prizeIndex))
        .won;

      for (
        let winnerIndex = 0;
        winnerIndex < Number(info.prizeWinnersLength);
        winnerIndex++
      ) {
        const winner = await unSigner.contract.prizeWinners(
          gameId,
          prizeIndex,
          winnerIndex
        );
        winners.push(winner);
      }

      prizesArray.push({ prizeIndex, isWon, winners });
    }

    return prizesArray;
  }
);

// export const closeNumber = createAsyncThunk(
//   "closeNumber",
//   async ({
//     dispatch,
//     gameId,
//     unSigner,
//     gameStatus,
//     playerCardIndex,
//     number,
//     card0DrawnNumbers,
//     card1DrawnNumbers,
//     card2DrawnNumbers,
//     card3DrawnNumbers,
//   }) => {
//     let errors = [];
//     if (gameStatus === 3) {
//       let closedNumbers =
//         (await dispatch(
//           getStorage({
//             key: `closeNumber-${gameId}-${playerCardIndex}`,
//             type: "local",
//           })
//         )
//           .then((result) => {
//             if (result.payload) {
//               return result.payload;
//             }
//           })
//           .catch((error) => console.log(error))) || [];

//       const tx = await unSigner.contract.drawnNumbers(gameId, number); //kontrat tarafında sayının cekilip cekilmedigi kontrol ediliyor.
//       if (tx) {
//         if (
//           (card0DrawnNumbers && !card0DrawnNumbers.includes(number)) ||
//           (card1DrawnNumbers && !card1DrawnNumbers.includes(number)) ||
//           (card2DrawnNumbers && !card2DrawnNumbers.includes(number)) ||
//           (card3DrawnNumbers && !card3DrawnNumbers.includes(number))
//         ) {
//           closedNumbers.push(number);
//           await dispatch(
//             setStorage({
//               key: `closeNumber-${gameId}-${playerCardIndex}`,
//               value: closedNumbers,
//               type: "local",
//             })
//           );
//           return { closedNumbers, errors: null };
//         } else {
//           errors.push(`'${number}' is closed!`);
//           return { closedNumbers: null, errors: errors };
//         }
//       } else {
//         errors.push(`'${number}' is not drawn!`);
//         return { closedNumbers: null, errors: errors };
//       }
//     } else {
//       errors.push(`closeNumber: wrongGameStatus (${gameStatus})`);
//       return { closedNumbers: null, errors: errors };
//     }
//   }
// );

export const checkNumbers = createAsyncThunk(
  "checkNumbers",
  async ({ dispatch, unSigner, gameId, gameStatus, currentChain }) => {
    let drawnNumbers = [];

    if (gameStatus === 3) {
      const startedBlockNum = await dispatch(
        getLogsStartedBlock({
          unSigner,
          currentChain,
          gameId,
        })
      ).then((result) => {
        return result.payload.num;
      });

      localStorage.removeItem(
        `${currentChain.contracts.jammy.address}:${currentChain.id}:revealNumbers:${gameId}`
      );

      const blockRange = currentChain?.rpcUrls.extra.blockRange;
      const blockCreated = currentChain?.contracts.jammy.blockCreated;
      let _allDrawnNumbers = [];

      if (startedBlockNum > blockCreated) {
        let latestBlock = await unSigner.provider.getBlockNumber();
        if (latestBlock - startedBlockNum > blockRange) {
          const rangeCount =
            Math.floor(
              //TODO: round olabilir test edilip bakılacak
              (latestBlock - startedBlockNum) / blockRange
            ) + 1;
          for (let i = 0; i < rangeCount; i++) {
            if (latestBlock > startedBlockNum) {
              const blockRangeEvents = await unSigner.contract?.queryFilter(
                "NumberRevealed",
                latestBlock - blockRange,
                latestBlock
              );
              if (blockRangeEvents.length > 0) {
                blockRangeEvents.forEach((event) => {
                  _allDrawnNumbers.push(event);
                });
              }
              latestBlock = latestBlock - blockRange;
            }
          }
        } else {
          _allDrawnNumbers = await unSigner.contract?.queryFilter(
            "NumberRevealed",
            startedBlockNum,
            "latest"
          );
        }
      } else {
        _allDrawnNumbers = await unSigner.contract?.queryFilter(
          "NumberRevealed",
          -blockRange,
          "latest"
        );
      }

      const allDrawnNumbers = _allDrawnNumbers.filter(
        (event) =>
          event.eventName === "NumberRevealed" &&
          Number(event.args.gameId) === gameId
      );
      console.log("NumberRevealed", { _allDrawnNumbers, allDrawnNumbers });

      for (let i in allDrawnNumbers) {
        const blockTimestamp = (await allDrawnNumbers[i].getBlock()).timestamp;
        const tx = allDrawnNumbers[i].transactionHash;
        const revealedNum = Number(allDrawnNumbers[i].args.revealedNum);

        drawnNumbers.push({
          datetime: blockTimestamp,
          number: revealedNum,
          transaction: tx,
        });
      }

      dispatch(
        setStorage({
          key: `revealNumbers:${gameId}`,
          value: drawnNumbers,
          type: "local",
          currentChain,
        })
      );
    } else {
      console.log(`checkNumbers: wrongGameStatus (${gameStatus})`);
    }

    return drawnNumbers;
  }
);

export const checkWinCard = createAsyncThunk(
  "checkWinCard",
  async ({
    dispatch,
    unSigner,
    gameId,
    cardCount,
    user,
    prizeIndex,
    drawnNumbers,
    // isManuelCloseCard,
  }) => {
    let isFound = false;
    let pci = 0;
    let playerCard = null;
    let hexCard = null;
    let cardIndex = null;
    let arrayCard = null;
    // let localDrawnNumbers = null;

    for (pci; pci < cardCount; pci++) {
      // if (isManuelCloseCard) {
      //   localDrawnNumbers = await dispatch(
      //     getStorage({
      //       key: `closeNumber-${gameId}-${pci}`,
      //       type: "local",
      //     })
      //   )
      //     .then((result) => {
      //       if (result.payload) {
      //         console.log("localDrawnNumbers:", pci, result.payload);
      //         return result.payload;
      //       }
      //     })
      //     .catch((error) => console.log(error));
      // }

      try {
        playerCard = await unSigner.contract.playerCards(gameId, user, pci);
        hexCard = toBeHex(playerCard);
        arrayCard = hexToArray(hexCard);

        const rowsToMatch = 5 - prizeIndex;
        let matchedRows = 0;
        let seenNum = 0;
        let rowIndex = 0;
        let winRowIndex = 0;

        arrayCard.forEach((cardNum, cardNumIndex) => {
          if (cardNum === 0) seenNum++;
          // if (isManuelCloseCard) {
          //   if (localDrawnNumbers) {
          //     localDrawnNumbers.forEach((drawnNum) => {
          //       if (Number(cardNum) === Number(drawnNum)) {
          //         seenNum++;
          //       }
          //     });
          //   }
          // } else {
          drawnNumbers.forEach((drawnNum) => {
            if (Number(cardNum) === Number(drawnNum.number)) {
              seenNum++;
            }
          });
          // }

          if (cardNumIndex % 5 === 4) {
            if (seenNum === 5) {
              matchedRows++;
              winRowIndex = rowIndex;
            }
            seenNum = 0;
            rowIndex++;
          }
        });
        console.log(pci, hexCard, matchedRows, rowsToMatch);

        if (matchedRows >= rowsToMatch) {
          isFound = true;
          const allCards = await unSigner.contract.getCards();
          allCards.forEach((card, index) => {
            if (card === playerCard) {
              // console.log(index, card, playerCard);
              cardIndex = index;
            }
          });
          break; //TODO: burası doğru çalışmıyor olabilir kontrol edilecek.
        }
      } catch (error) {
        console.log(error.reason);
        break;
      }
    }

    return {
      isFound,
      user,
      avatar: "avatar6.png",
      username: "username",
      playerCardIndex: pci,
      cardIndex,
      arrayCard,
      hexCard,
      playerCard,
      drawnNumbers,
      // drawnNumbers: isManuelCloseCard ? localDrawnNumbers : drawnNumbers,
    };
  }
);

export const gameStore = createSlice({
  name: "gameStore",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(urlParams.fulfilled, (state, action) => {
      state.gameId = action.payload.gameId;
      state.cardCount = action.payload.cardCount;
    });
    builder.addCase(fetchGame.fulfilled, (state, action) => {
      state.game = action.payload;
    });
    builder.addCase(checkGamePrizes.fulfilled, (state, action) => {
      state.gamePrizes = action.payload;
    });
  },
});

export default gameStore.reducer;
