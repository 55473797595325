import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Wallet,
  Contract,
  JsonRpcProvider,
  Interface,
  isBytesLike,
} from "ethers";
import { useWalletConnection } from "../../../utility/hooks/useWalletConnection";
import { allStorageClear } from "../../../pages/Game/store";
import "./HostGame.css";
//images
import singleCardImg from "../../../assets/img/single-card.png";
import { getStorage, setStorage } from "../../../pages/store";

const HostRevealNumber = ({ drawnNumbers }) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const gameId = useSelector((state) => state.gameStore.gameId);
  const gameStatus = useSelector((state) => state.rootStore.gameStatus);

  const {
    isConnected,
    currentChain,
    connectedWalletInfo,
    address,
    chainId,
    wallet,
    signer,
    unSigner,
    contractAbi,
    error,
    errorMessage,
    clearError,
    open,
    connect,
    disConnect,
    fragments,
    getFragment,
    toastArgs,
    setToastArgs,
  } = useWalletConnection();
  const [seconds, setSeconds] = useState(0);
  const [lastRevealedNumber, setLastRevealedNumber] = useState(0);
  const hostDelay = 30; // saniye bekleme
  const [btnLoading, setBtnLoading] = useState(false);
  const [isEndGame, setIsEndGame] = useState(null);
  const privKeyRef = useRef();

  const revealNumber = async () => {
    if (typeof window.ethereum !== "undefined" && gameStatus === 3) {
      let privKey = null;
      try {
        if (privKeyRef.current.value !== "") {
          //**Cüzdan privatekey onaylı
          //https://ethereum.stackexchange.com/questions/120817/how-to-call-a-contract-function-method-using-ethersjs

          if (privKeyRef.current.value.search("0x") === -1) {
            privKey = "0x" + privKeyRef.current.value;
          } else {
            privKey = privKeyRef.current.value;
          }

          if (!isBytesLike(privKey)) {
            setToastArgs({
              msgType: "error",
              msg: "PrivKey: incorrect or missing",
              isShow: true,
            });
            return;
          }

          //d29f44faf3a0c1e74a609a8b8945e116cd0ebe6a49c149d75e33ad1b091f12c7 (0xa8f...aA6e2)
          //53771c0ad95d807958f660f2ed5cf3c73f2ddc54c696482de1ec0a19f61ea46c (0x3C8...922d6)

          const privSigner = new Wallet(privKey, unSigner.provider);
          if (privSigner.address?.toLowerCase() !== wallet.accounts[0]?.toLowerCase()) {
            setToastArgs({
              msgType: "error",
              msg: "PrivKey: does not belong to connected wallet",
              isShow: true,
            });
            return;
          }

          setBtnLoading(false);
          setSeconds(hostDelay);

          const _bingoContract = new Contract(
            currentChain.contracts.jammy.address,
            contractAbi.abi,
            privSigner
          );

          const estimatedGasLimit =
            await _bingoContract.revealNumber.estimateGas(gameId);
          const revealNumTxUnsigned =
            await _bingoContract.revealNumber.populateTransaction(gameId);
          revealNumTxUnsigned.chainId = currentChain.id;
          revealNumTxUnsigned.gasLimit = estimatedGasLimit;
          const tx = await privSigner.sendTransaction(revealNumTxUnsigned);
          const receipt = await tx.wait();
          console.log("rn-receipt:", receipt);
          dispatch(
            setStorage({
              key: `pkey`,
              value: privKey,
              type: "session",
              currentChain,
            })
          );
        } else {
          // **Cüzdan onaylı
          setBtnLoading(false);
          setSeconds(hostDelay);

          const tx = await signer.contract.revealNumber(gameId);
          const receipt = await tx.wait();
          console.log("rn-receipt:", receipt);
        }
      } catch (error) {
        setToastArgs({
          msgType: "error",
          msg: error,
          isShow: true,
        });
        setBtnLoading(false);
        setSeconds(0);
        console.log(error);
      }
    } else {
      console.log(`revealNumber: wrongGameStatus (${gameStatus})`);
    }
  };

  const backToLobby = async () => {
    await dispatch(
      allStorageClear({
        gameId,
        user: wallet.accounts[0].toLowerCase(),
        currentChain,
      })
    )
      .then((result) => {
        if (result.payload) {
          console.log("all storage clear");
          window.location.href = "/";
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds === 0) {
        clearInterval(countdown);
        return;
      }
      setSeconds(seconds - 1);
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [seconds]);

  useEffect(() => {
    if (gameStatus === 3 && drawnNumbers.length > 0) {
      if (lastRevealedNumber === 0) {
        setLastRevealedNumber(drawnNumbers[0].number);
      }
    }
  }, [gameStatus, drawnNumbers]);

  useEffect(() => {
    if (!currentChain) return;
    if (!gameId > 0) return;
    setIsEndGame(
      localStorage.getItem(
        `${currentChain.contracts.jammy.address}:${currentChain.id}:jammy:${gameId}`
      )
        ? true
        : false
    );

    dispatch(
      getStorage({
        key: `pkey`,
        type: "session",
        currentChain,
      })
    ).then((result) => {
      if (result.payload) {
        privKeyRef.current.value = result.payload;
      }
    });
  }, [currentChain, gameId]);

  //Event listeners
  useEffect(() => {
    if (!unSigner.contract) return;
    if (!gameId > 0) return;
    if (!gameStatus > 0) return;

    const listenerNumberRevealed = async (nrGameId, revealedNum, event) => {
      if (Number(nrGameId) !== gameId) return;
      setLastRevealedNumber(revealedNum);
      setBtnLoading(false);
      setSeconds(0);
    };

    gameStatus === 3 &&
      unSigner.contract?.on("NumberRevealed", listenerNumberRevealed);
    return () => {
      gameStatus === 3 &&
        unSigner.contract?.off("NumberRevealed", listenerNumberRevealed);
    };
  }, [unSigner.contract, gameId, gameStatus]);

  return (
    <div className="single-card-item number-card-item">
      <div className="in-box">
        <input
          type="text"
          placeholder="Privkey for auto wallet confirm"
          ref={privKeyRef}
          className="w-100 m-2 p-1"
          style={{
            color: "#1768f3",
            fontWeight: "normal",
            fontSize: "9pt",
            border: "1px solid #ca2d00",
            borderRadius: "8px",
          }}
        />
        {gameStatus === 6 && (
          <h3 style={{ margin: "20px 0" }}>Canceled game!</h3>
        )}
        {gameStatus === 5 && (
          <h3 style={{ margin: "20px 0" }}>Expired game!</h3>
        )}
        {gameStatus === 4 && <h3 style={{ margin: "20px 0" }}>Game Over</h3>}
        {gameStatus === 3 && (
          <span
            className={
              lastRevealedNumber > 0 && lastRevealedNumber <= 15
                ? "yellow"
                : lastRevealedNumber > 15 && lastRevealedNumber <= 30
                ? "red"
                : lastRevealedNumber > 30 && lastRevealedNumber <= 45
                ? "purple"
                : lastRevealedNumber > 45 && lastRevealedNumber <= 60
                ? "green"
                : "blue"
            }
            style={
              lastRevealedNumber !== 0
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <small>
              {lastRevealedNumber !== 0 ? lastRevealedNumber : null}
            </small>
          </span>
        )}
        {gameStatus === 3 ? (
          <>
            <div className="btns-bottom" style={{ marginBottom: "8px" }}>
              <Link
                to={search}
                onMouseOver={() => {
                  if (
                    !isEndGame &&
                    localStorage.getItem(
                      `${currentChain.contracts.jammy.address}:${currentChain.id}:jammy:${gameId}`
                    )
                  ) {
                    setIsEndGame(true);
                  }
                }}
                className={
                  btnLoading === false && seconds <= 0
                    ? "btn-sub"
                    : "btn-sub done"
                }
                onClick={() => revealNumber()}
              >
                {btnLoading === false && seconds <= 0
                  ? !isEndGame
                    ? "Draw a number"
                    : "End Game"
                  : btnLoading === true && seconds <= 0
                  ? `wait (Metamask)`
                  : `wait (${seconds} sec)`}
              </Link>
            </div>
            <p style={{ padding: 0, margin: 0 }}>75 of {drawnNumbers.length}</p>
          </>
        ) : (
          <div className="btns-bottom">
            <Link
              to={search}
              className={"btn-sub"}
              onClick={() => backToLobby()}
            >
              Back to Lobby
            </Link>
          </div>
        )}
      </div>
      <img src={singleCardImg} className="w-100" alt="" />
    </div>
  );
};

export default HostRevealNumber;
