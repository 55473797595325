import React from "react";
import { formatEther } from "ethers";
import { useSelector } from "react-redux";
import { useWalletConnection } from "../../../utility/hooks/useWalletConnection";

const TopInfo = () => {
  const {
    isConnected,
    currentChain,
    connectedWalletInfo,
    address,
    chainId,
    wallet,
    signer,
    unSigner,
    contractAbi,
    error,
    errorMessage,
    clearError,
    open,
    connect,
    disConnect,
    fragments,
    getFragment,
    toastArgs,
    setToastArgs,
  } = useWalletConnection();
  const game = useSelector((state) => state.gameStore.game);

  return (
    <>
      <div className="top-info">
        <div className="item">
          BET: {game ? formatEther(game.cardPrice) : "0"}{" "}
          {currentChain?.nativeCurrency.symbol}
        </div>
        <div className="item">
          POT:{" "}
          {game
            ? formatEther((Number(game.cardPrice) * Number(game.totalCardsSold)).toString())
            : "0"}{" "}
          {currentChain?.nativeCurrency.symbol}
        </div>
        <div className="item">
          JAMMY:{" "}
          {game
            ? formatEther((Number(game.cardPrice) * Number(game.totalCardsSold) * 0.4).toString())
            : "0"}{" "}
          {currentChain?.nativeCurrency.symbol}
        </div>
      </div>
    </>
  );
};

export default React.memo(TopInfo);
