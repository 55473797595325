import { useEffect, useState } from "react";
import { useWalletConnection } from "../../utility/hooks/useWalletConnection";
import { isError } from "ethers";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
//img
import okIcon from "../../assets/img/success.png";
import errorIcon from "../../assets/img/error.png";

// msgTypes: error, ok
const ToastMessage = () => {
  const {
    isConnected,
    currentChain,
    connectedWalletInfo,
    address,
    chainId,
    wallet,
    signer,
    unSigner,
    contractAbi,
    error,
    errorMessage,
    clearError,
    open,
    connect,
    disConnect,
    fragments,
    getFragment,
    toastArgs,
    setToastArgs,
  } = useWalletConnection();

  const [displayErrorType, setdisplayErrorType] = useState(null);
  const [displayErrorMethod, setdisplayErrorMethod] = useState(null);
  const [displayTitle, setDisplayTitle] = useState(null);
  const [displayFull, setDisplayFull] = useState(null);

  const errorParse = () => {
    console.log(toastArgs.msg);

    if (isError(toastArgs.msg, "CALL_EXCEPTION")) {
      // Kontrattan gelen hatalar (CALL_EXCEPTION)
      setdisplayErrorType(toastArgs.msg.code);
      setdisplayErrorMethod(toastArgs.msg.action);

      if (toastArgs.msg.data) {
        setDisplayTitle(
          `Execution reverted (${
            getFragment("error", toastArgs.msg.data).name
          })`
        );
      } else {
        setDisplayTitle(toastArgs.msg.shortMessage);
      }
    } else if (isError(toastArgs.msg, "ACTION_REJECTED")) {
      setdisplayErrorType(toastArgs.msg.code);
      setdisplayErrorMethod(toastArgs.msg.action);
      setDisplayTitle(toastArgs.msg.shortMessage);
    } else {
      setDisplayTitle(`${toastArgs.msg}`);
    }
  };

  useEffect(() => {
    if (toastArgs.msgType === "error") {
      errorParse();
    } else if (toastArgs.msgType === "ok") {
      setDisplayTitle(`${toastArgs.msg}`);
    }
  }, [toastArgs.msg]);

  return (
    <>
      <ToastContainer
        className="p-3"
        position="top-center"
        style={{ zIndex: 999999, position: "absolute" }}
      >
        <Toast
          className="d-flex"
          onClose={() =>
            setToastArgs({
              header: null,
              msgType: null,
              msg: null,
              isShow: false,
            })
          }
          animation={true}
          show={toastArgs.isShow}
          delay={777000}
          autohide
        >
          <div
            className="toast-body"
            style={{
              background: "#f6efe3",
              color: "black",
              width: "-webkit-fill-available",
            }}
          >
            <img
              src={toastArgs.msgType === "ok" ? okIcon : errorIcon}
              alt="type"
              width={20}
            />
            {displayTitle?.substring(0, 128)}
            {displayErrorType && (
              <>
                {!displayFull?.length > 0 ? (
                  <p style={{ margin: 0 }}>
                    <a
                      href="#"
                      onClick={() => setDisplayFull(toastArgs.msg.toString())}
                    >
                      show detail
                    </a>
                  </p>
                ) : (
                  <p style={{ margin: 0 }}>
                    <a href="#" onClick={() => setDisplayFull(null)}>
                      hide detail
                    </a>
                  </p>
                )}
              </>
            )}

            {displayFull && (
              <div
                style={{
                  color: "black",
                  overflowY: "scroll",
                  maxHeight: "200px",
                }}
              >
                <p style={{ margin: 0, padding: 0 }}>
                  <span
                    style={{
                      color: "red",
                      fontSize: "8pt",
                      margin: "0 4px",
                    }}
                  >
                    Type: {displayErrorType}
                  </span>
                  <span
                    style={{
                      color: "red",
                      fontSize: "8pt",
                      margin: "0 4px",
                    }}
                  >
                    Method: {displayErrorMethod}
                  </span>
                </p>
                <span style={{ color: "red" }}>Full Error Message: </span>
                {displayFull}
              </div>
            )}
          </div>
          <button
            type="button"
            className="btn-close me-2 m-auto"
            onClick={() =>
              setToastArgs({
                header: null,
                msgType: null,
                msg: null,
                isShow: false,
              })
            }
          />
        </Toast>
      </ToastContainer>
    </>
  );
};

export default ToastMessage;
