import React, { useState, useEffect } from "react";
import { useWalletConnection } from "../../../utility/hooks/useWalletConnection";
import { useDispatch, useSelector } from "react-redux";
import HostRevealNumber from "../../../components/Game/HostGame/HostRevealNumber";
import HostStartGame from "../../../components/Game/HostGame/HostStartGame";
// import { getCard } from "../../../pages/Game/store";
// import ShowCard from "./ShowCard";
// import NotCard from "./NotCard";
import Card0 from "./Card0";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
//Popups
import CardChangePopup from "../Popups/CardChangePopup";

const Cards = ({
  drawnNumbers,
  // manuelCloseToggle,
  cardChangePopup,
  setCardChangePopup,
}) => {
  const {
    isConnected,
    currentChain,
    connectedWalletInfo,
    address,
    chainId,
    wallet,
    signer,
    unSigner,
    contractAbi,
    error,
    errorMessage,
    clearError,
    open,
    connect,
    disConnect,
    fragments,
    getFragment,
    toastArgs,
    setToastArgs,
  } = useWalletConnection();
  const dispatch = useDispatch();
  const game = useSelector((state) => state.gameStore.game);
  const gameId = useSelector((state) => state.gameStore.gameId);
  const cardCount = useSelector((state) => state.gameStore.cardCount);

  // const [card0DrawnNumbers, setCard0DrawnNumbers] = useState([]);
  // const [card1DrawnNumbers, setCard1DrawnNumbers] = useState([]);
  // const [card2DrawnNumbers, setCard2DrawnNumbers] = useState([]);
  // const [card3DrawnNumbers, setCard3DrawnNumbers] = useState([]);

  // const [cards, setCards] = useState([]);

  // useEffect(() => {
  //   if (!unSigner.contract) return;
  //   if (!wallet.accounts.length > 0) return;
  //   if (!gameId > 0) return;

  //   for (let pci = 0; pci < cardCount; pci++) {
  //     dispatch(
  //       getCard({
  //         dispatch,
  //         gameId,
  //         unSigner,
  //         user: wallet.accounts[0].toLowerCase(),
  //         playerCardIndex: pci,
  //       })
  //     ).then((result) => {
  //       if (result.payload) {
  //         if (!result.payload.errors) {
  //           setCards((prevItems) => [
  //             ...prevItems,
  //             {
  //               playerCardIndex: pci,
  //               cardIndex: result.payload.cardIndex,
  //               cardNumbers: result.payload.cardNumbers,
  //             },
  //           ]);
  //         }
  //       }
  //     });
  //   }
  // }, [unSigner, wallet.accounts, gameId]);

  return (
    <>
      {cardChangePopup && (
        <CardChangePopup onClose={() => setCardChangePopup(false)} />
      )}

      <div className="card-container">
        {/* start game ekranı */}
        {signer.isHost && Number(game?.seed) < 2 && <HostStartGame />}
        {/* sayı çekme ekranı */}
        {signer.isHost && Number(game?.seed) > 1 && (
          <HostRevealNumber drawnNumbers={drawnNumbers} />
        )}

        {!signer.isHost ? (
          <div className="cards-area">
            <Card0
              inComponent={"Cards"}
              drawnNumbers={drawnNumbers}
              // card0DrawnNumbers={card0DrawnNumbers}
              // setCard0DrawnNumbers={setCard0DrawnNumbers}
              // manuelCloseToggle={manuelCloseToggle}
              cardChangePopup={cardChangePopup}
            />
            <Card1
              inComponent={"Cards"}
              drawnNumbers={drawnNumbers}
              // card1DrawnNumbers={card1DrawnNumbers}
              // setCard1DrawnNumbers={setCard1DrawnNumbers}
              // manuelCloseToggle={manuelCloseToggle}
              cardChangePopup={cardChangePopup}
            />
            <Card2
              inComponent={"Cards"}
              drawnNumbers={drawnNumbers}
              // card2DrawnNumbers={card2DrawnNumbers}
              // setCard2DrawnNumbers={setCard2DrawnNumbers}
              // manuelCloseToggle={manuelCloseToggle}
              cardChangePopup={cardChangePopup}
            />
            <Card3
              inComponent={"Cards"}
              drawnNumbers={drawnNumbers}
              // card3DrawnNumbers={card3DrawnNumbers}
              // setCard3DrawnNumbers={setCard3DrawnNumbers}
              // manuelCloseToggle={manuelCloseToggle}
              cardChangePopup={cardChangePopup}
            />

            {/* <>
              {cardCount > 0 && (
                <ShowCard
                  card={cards?.find(
                    ({ playerCardIndex }) => playerCardIndex === 0
                  )}
                  drawnNumbers={drawnNumbers}
                  // isLoadingText={"Waiting TX"}
                  // linkTo={"#linkTo"}
                  // linkTitle={"linkTitle"}
                  // linkOnClick={() => setCardChangePopup(true)}
                  // linkCursor={"not-allowed"} // default, wait, progress, not-allowed (def value: pointer)
                />
              )}
              {cardCount > 1 && (
                <ShowCard
                  card={cards?.find(
                    ({ playerCardIndex }) => playerCardIndex === 1
                  )}
                  drawnNumbers={drawnNumbers}
                  // isLoadingText={"Waiting TX"}
                  // linkTo={"#linkTo"}
                  // linkTitle={"linkTitle"}
                  // linkOnClick={() => setCardChangePopup(true)}
                  // linkCursor={"not-allowed"} // default, wait, progress, not-allowed (def value: pointer)
                />
              )}
              {cardCount > 2 && (
                <ShowCard
                  card={cards?.find(
                    ({ playerCardIndex }) => playerCardIndex === 2
                  )}
                  drawnNumbers={drawnNumbers}
                  // isLoadingText={"Waiting TX"}
                  // linkTo={"#linkTo"}
                  // linkTitle={"linkTitle"}
                  // linkOnClick={() => setCardChangePopup(true)}
                  // linkCursor={"not-allowed"} // default, wait, progress, not-allowed (def value: pointer)
                />
              )}
              {cardCount > 3 && (
                <ShowCard
                  card={cards?.find(
                    ({ playerCardIndex }) => playerCardIndex === 3
                  )}
                  drawnNumbers={drawnNumbers}
                  // isLoadingText={"Waiting TX"}
                  // linkTo={"#linkTo"}
                  // linkTitle={"linkTitle"}
                  // linkOnClick={() => setCardChangePopup(true)}
                  // linkCursor={"not-allowed"} // default, wait, progress, not-allowed (def value: pointer)
                />
              )}

              {cardCount === 1 && (
                <>
                  <NotCard />
                  <NotCard />
                  <NotCard />
                </>
              )}
              {cardCount === 2 && (
                <>
                  <NotCard />
                  <NotCard />
                </>
              )}
              {cardCount === 3 && (
                <>
                  <NotCard />
                </>
              )}
            </> */}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default React.memo(Cards);
