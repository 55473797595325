import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import CloseButton from "../../CloseButton/CloseButton";
import { useDispatch, useSelector } from "react-redux";
import { toBeHex } from "ethers";
import { useWalletConnection } from "../../../utility/hooks/useWalletConnection";
import { getApiUser } from "../../../pages/store";
import { getLogsPrizeWon } from "../../../pages/Game/store";
import { hexToArray } from "../../../utility/Utils";
//images
import purpleNeonImg from "../../../assets/img/Purple-Neon.png";
import Loading from "../../Loading/Loading";
import defaultAvatar from "../../../assets/img/user-icon.svg";

const WinningHistoryPopup = ({ prizeIndex, onClose }) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const gameId = useSelector((state) => state.gameStore.gameId);

  const {
    isConnected,
    currentChain,
    connectedWalletInfo,
    address,
    chainId,
    wallet,
    signer,
    unSigner,
    contractAbi,
    error,
    errorMessage,
    clearError,
    open,
    connect,
    disConnect,
    fragments,
    getFragment,
    toastArgs,
    setToastArgs,
  } = useWalletConnection();
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    if (!unSigner.contract) return;
    if (!wallet.accounts.length > 0) return;
    if (!currentChain) return;
    if (!gameId) return;

    dispatch(
      getLogsPrizeWon({
        dispatch,
        unSigner,
        currentChain,
        gameId,
      })
    )
      .then(async (result) => {
        console.log(result.payload);
        if (!result.payload.errors) {
          const allCards = await unSigner.contract.getCards();

          for (let i = 0; i < result.payload.eventLogs.length; i++) {
            if (
              Number(result.payload.eventLogs[i].args.prizeIndex) === prizeIndex
            ) {
              let cardIndex = null;
              allCards.forEach((card, index) => {
                if (card === result.payload.eventLogs[i].args.winnerCard) {
                  cardIndex = index;
                }
              });

              const apiUser = await dispatch(
                getApiUser({ address: result.payload.eventLogs[i].args.winner })
              ).then((result) => {
                return result.payload;
              });

              const arrayCard = hexToArray(
                toBeHex(result.payload.eventLogs[i].args.winnerCard)
              );
              setPlayers((oldArry) => [
                ...oldArry,
                {
                  avatar:
                    apiUser?.status === 200 ? apiUser?.avatar : defaultAvatar,
                  username:
                    apiUser?.status === 200
                      ? apiUser?.userName
                      : "User not registered",
                  winnerAddress: result.payload.eventLogs[i].args.winner,
                  prizeIndex,
                  cardIndex,
                  arrayCard,
                  hash: result.payload.eventLogs[i].transactionHash,
                },
              ]);
            }
          }
        }
      })
      .catch((e) => console.log(e));
  }, [unSigner.contract, wallet.accounts, currentChain, gameId]);

  return (
    <div className="popup-wrapper always-wrapper">
      {!players.length > 0 && <Loading text="Waiting winners..." />}
      <CloseButton onClose={onClose} to={search} />
      <div className="in">
        <div className="text-top-area always-popup">Winning History</div>
        <div className="title-sub-box-o">
          winners of{" "}
          {(prizeIndex === 4 && "1st Jam") ||
            (prizeIndex === 3 && "2nd Jam") ||
            (prizeIndex === 2 && "3rd Jam") ||
            (prizeIndex === 1 && "4th Jam") ||
            (prizeIndex === 0 && "Jammy")}{" "}
          prize
        </div>
        <div className="card-bottom-area">
          <div className="card-container">
            <Fragment>
              {players.length > 0 &&
                players.map((player, index) => (
                  <div className="col-3 " key={index}>
                    <Link
                      target="blank"
                      to={`${currentChain?.blockExplorers.default.url}/address/${player.winnerAddress}`}
                      title={`View ${player.username} on ${currentChain?.blockExplorers.default.name}`}
                    >
                      <div
                        className="position-relative d-inline-flex align-items-center justify-content-center gap-3 z-index-100"
                        style={{
                          fontSize: "calc(2px + 0.8vw + 0.4vh)",
                        }}
                      >
                        <img
                          style={{
                            width: "10%",
                            border: "2px solid #ddd",
                            borderRadius: "50%",
                          }}
                          src={player.avatar}
                          alt={player.username}
                        />
                        {player.username}
                      </div>
                    </Link>
                    <Link
                      to={`${currentChain?.blockExplorers.default.url}/tx/${player.hash}`}
                      target="blank"
                    >
                      <div
                        className="item-card w-100"
                        style={{ padding: "8px 28px" }}
                        key={index}
                      >
                        <span>{player.cardIndex}</span>
                        <div className="in" style={{ margin: "25px 0" }}>
                          {player.arrayCard.map((cardNum, cardNumIndex) => (
                            <div
                              className={
                                cardNum === 0 ? "item revealed" : "item"
                              }
                              key={cardNumIndex}
                            >
                              {cardNum !== 0 ? cardNum : null}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </Fragment>
          </div>
        </div>
        <img src={purpleNeonImg} className="popup-bg" alt="" />
      </div>
    </div>
  );
};

export default WinningHistoryPopup;
