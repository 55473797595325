const rndInRange = (max, min) => Math.floor(Math.random() * (max - min + 1)) + min;

export const create5x5 = () => {
  let nums = new Array(5);
  for (let i = 0; i < nums.length; i++) {
    nums[i] = new Array(5);
  }
  for (let i = 0; i < 5; i++) {
    const max = (i + 1) * 15;
    const min = i * 15 + 1;
    let seenNums = [];
    for (let k = 0; k < 5; k++) {
      if (i == 2 && k == 2) {
        nums[k][i] = 0;
        continue;
      }
      let n = rndInRange(max, min);
      while (seenNums.includes(n)) {
        n = rndInRange(max, min);
      }
      seenNums.push(n);
      nums[k][i] = n;
    }
  }
  return nums;
};

export const arrayToHex = (arr) => {
  let res = "";
  for (const row of arr) {
    res += row.reduce((a, b) => {
      const prefixed = b > 15 ? b.toString(16) : `0${b.toString(16)}`;
      return `${a}${prefixed}`;
    }, "");
  }
  return `0x${res}`;
};

export const hexToArray = (hex) => {
  let hexPart = hex.substring(2);
  let index = 0;
  let res = [];
  while(index < hexPart.length){
    res.push(parseInt(hexPart.slice(index, index + 2), 16));
    index = index + 2;
  }
  return res;
};

export const formatAddress = (addr) => {
  return `${addr.substring(0, 9)} . . . ${addr.substring(
    addr.length - 7,
    addr.length
  )}`;
};

export const formatTimestampToAMPM = (gametimestamp) => {
  const dateTime = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ap = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${hours.toString().padStart(1, "0")}-${minutes.toString().padStart(2, "0")}-${ap}`;
  };

  const dateFormatGame = new Date(gametimestamp * 1000);
  const hours = dateTime(dateFormatGame).split("-")[0];
  const ap = dateTime(dateFormatGame).split("-")[2];
  let initialSlideIndex = 0;
  switch (Number(hours)) {
    case 12:
      if (ap === "am") return { "time": dateTime(dateFormatGame), "room": "12-2-am", "initialSlideIndex" : 0 };
      if (ap === "pm") return { "time": dateTime(dateFormatGame), "room": "12-2-pm", "initialSlideIndex" : 4 };
      break;
    case 1:
      if (ap === "am") return { "time": dateTime(dateFormatGame), "room": "12-2-am", "initialSlideIndex" : 0 };
      if (ap === "pm") return { "time": dateTime(dateFormatGame), "room": "12-2-pm", "initialSlideIndex" : 4 };
      break;
    case 2:
      if (ap === "am") return { "time": dateTime(dateFormatGame), "room": "12-2-am", "initialSlideIndex" : 0 };
      if (ap === "pm") return { "time": dateTime(dateFormatGame), "room": "12-2-pm", "initialSlideIndex" : 4 };
      break;
    case 3:
      if (ap === "am") return { "time": dateTime(dateFormatGame), "room": "3-5-am", "initialSlideIndex" : 1 };
      if (ap === "pm") return { "time": dateTime(dateFormatGame), "room": "3-5-pm", "initialSlideIndex" : 5 };
      break;
    case 4:
      if (ap === "am") return { "time": dateTime(dateFormatGame), "room": "3-5-am", "initialSlideIndex" : 1 };
      if (ap === "pm") return { "time": dateTime(dateFormatGame), "room": "3-5-pm", "initialSlideIndex" : 5 };
      break;
    case 5:
      if (ap === "am") return { "time": dateTime(dateFormatGame), "room": "3-5-am", "initialSlideIndex" : 1 };
      if (ap === "pm") return { "time": dateTime(dateFormatGame), "room": "3-5-pm", "initialSlideIndex" : 5 };
      break;
    case 6:
      if (ap === "am") return { "time": dateTime(dateFormatGame), "room": "6-8-am", "initialSlideIndex" : 2 };
      if (ap === "pm") return { "time": dateTime(dateFormatGame), "room": "6-8-pm", "initialSlideIndex" : 6 };
      break;
    case 7:
      if (ap === "am") return { "time": dateTime(dateFormatGame), "room": "6-8-am", "initialSlideIndex" : 2 };
      if (ap === "pm") return { "time": dateTime(dateFormatGame), "room": "6-8-pm", "initialSlideIndex" : 6 };
      break;
    case 8:
      if (ap === "am") return { "time": dateTime(dateFormatGame), "room": "6-8-am", "initialSlideIndex" : 2 };
      if (ap === "pm") return { "time": dateTime(dateFormatGame), "room": "6-8-pm", "initialSlideIndex" : 6 };
      break;
    case 9:
      if (ap === "am") return { "time": dateTime(dateFormatGame), "room": "9-11-am", "initialSlideIndex" : 3 };
      if (ap === "pm") return { "time": dateTime(dateFormatGame), "room": "9-11-pm", "initialSlideIndex" : 7 };
      break;
    case 10:
      if (ap === "am") return { "time": dateTime(dateFormatGame), "room": "9-11-am", "initialSlideIndex" : 3 };
      if (ap === "pm") return { "time": dateTime(dateFormatGame), "room": "9-11-pm", "initialSlideIndex" : 7 };
      break;
    case 11:
      if (ap === "am") return { "time": dateTime(dateFormatGame), "room": "9-11-am", "initialSlideIndex" : 3 };
      if (ap === "pm") return { "time": dateTime(dateFormatGame), "room": "9-11-pm", "initialSlideIndex" : 7 };
      break;
    default:
      return "null";
  }
};