import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useWalletConnection } from "../../utility/hooks/useWalletConnection";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getApiCurrentUser } from "../../pages/store";
import CloseButton from "../CloseButton/CloseButton";
//css
import "./Register.css";
//images
import registerBg from "../../assets/img/registerBg.png";

const defaultValues = {
  wallet: "",
  username: "",
  referrerCode: undefined,
};

const Register = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const {
    isConnected,
    currentChain,
    connectedWalletInfo,
    address,
    chainId,
    wallet,
    signer,
    unSigner,
    contractAbi,
    error,
    errorMessage,
    clearError,
    open,
    connect,
    disConnect,
    fragments,
    getFragment,
    toastArgs,
    setToastArgs,
  } = useWalletConnection();
  const avatarImages = require.context("../../assets/img/avatars");

  const [isReg, setIsReg] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);

  // ** Vars
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  // ** Function to handle form submitt
  const onSubmit = async (data) => {
    setErrorMsg(null);
    if (
      Object.values(data).every(
        (field) => field === undefined || field.length > 0
      )
    ) {
      if (selectedAvatar !== null) {
        data["avatar"] = selectedAvatar;
      } else {
        return setErrorMsg(`please select avatar!`);
      }
    } else {
      for (const key in data) {
        if (data[key] !== undefined && data[key].length === 0) {
          return setErrorMsg(`${key} cannot be empty!`);
        }
      }
    }
    console.log("register data:", data);

    signer?.signer
      .signMessage(
        `CONFIRM REGISTRATION\nWallet:\n${data.wallet}\nName:\n${data.username}`
      )
      .then(async (sig) => {
        console.log("sign:", sig);
        //TODO: Güvenlik olmadan da kayıt yapıyor. Bilal ile bakılacak.
        await axios
          .post(`${process.env.REACT_APP_API}users/register`, data)
          .then((res) => {
            console.log("reg. res:", res);
            dispatch(getApiCurrentUser({ address: wallet.accounts[0] })).then(
              (result) => {
                if (result.payload.status === 200) {
                  setIsReg(true);
                } else {
                  setIsReg(false);
                }
              }
            );
          })
          .catch((err) => {
            console.log(err);
            if (err.status === 409) {
              return setErrorMsg(err.response.data.error);
            } else {
              return setErrorMsg(`Error status: ${err.status}`);
            }
          });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!signer.contract) return;
    if (!wallet.accounts.length > 0) return;
    if (signer.isDeployer) return;
    if (signer.isAdmin) return;
    if (signer.isHost) return;

    dispatch(getApiCurrentUser({ address: wallet.accounts[0] })).then(
      (result) => {
        // console.log(
        //   signer.isDeployer,
        //   signer.isAdmin,
        //   signer.isHost,
        //   result.payload
        // );
        if (result.payload.status === 200) {
          setIsReg(true);
        } else {
          //TODO: Yetkili girişinde reg açılıyor engellenecek
          setIsReg(false);
        }
      }
    );
  }, [signer.contract, wallet.accounts]);

  useEffect(() => {
    setValue("wallet", wallet.accounts[0]);
  }, [wallet, setValue]);

  return (
    <>
      {isReg === false && (
        <div className="popup-register popup">
          <CloseButton onClose={() => setIsReg(true)} to={search} />
          <div className="in">
            <img src={registerBg} className="w-100" alt="" />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="box-inner-area">
                <div className="form-left">
                  <div className="container">
                    <div className="col-lg-12 formItem">
                      <span>WALLET ADDRESS:</span>
                      <div className="input">
                        <Controller
                          name="wallet"
                          control={control}
                          render={({ field }) => (
                            <input
                              id="wallet"
                              disabled={true}
                              placeholder="0x00"
                              invalid={errors.wallet && true}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 formItem">
                      <span>USERNAME:</span>
                      <div className="input">
                        <Controller
                          name="username"
                          control={control}
                          render={({ field }) => (
                            <input
                              id="username"
                              placeholder="Write your username"
                              onKeyDown={(e) => {
                                const re = /[0-9a-z._]+/g;
                                if (!re.test(e.key)) {
                                  e.preventDefault();
                                  return setErrorMsg(
                                    `(${e.key})  invalid character!`
                                  );
                                } else {
                                  return setErrorMsg(false);
                                }
                              }}
                              invalid={errors.username && true}
                              {...field}
                            />
                          )}
                        />
                        <img className="icon-warning" alt="" />
                      </div>
                      <small className="note"></small>
                    </div>
                    <div className="col-lg-12 formItem">
                      <span>REFERENCE CODE:</span>
                      <div className="input">
                        <Controller
                          name="referrerCode"
                          control={control}
                          render={({ field }) => (
                            <input
                              disabled={true}
                              id="referrerCode"
                              placeholder="Reference code"
                              {...field}
                            />
                          )}
                        />
                      </div>
                      <small className="note">*optional</small>
                    </div>
                    <div className="col-lg-12 formItem">
                      <p style={{ color: "red", textAlign: "right" }}>
                        {errorMsg}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="box-right-avatar">
                  <h4>CHOOSE YOUR AVATAR</h4>
                  <div className="avatar-inner">
                    {avatarImages.keys().map((image, index) => (
                      <Link
                        to={`#${image.substr(2).split(".")[0].toLowerCase()}`}
                        className={
                          selectedAvatar ===
                          image.substr(2).split(".")[0].toLowerCase()
                            ? "active"
                            : ""
                        }
                        key={index}
                        onClick={() =>
                          setSelectedAvatar(
                            image.substr(2).split(".")[0].toLowerCase()
                          )
                        }
                      >
                        <img
                          src={`${avatarImages(image)}`}
                          alt={image.substr(2).split(".")[0].toLowerCase()}
                        />
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="btns-bottom">
                  <button type="submit" className="btn-sub">
                    Sign Up
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
