import React from "react";
import { createRoot } from "react-dom/client";
import { createAppKit } from "@reown/appkit/react";
import { networks, projectId, metadata, ethersAdapter } from "./configs/reown";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
// import { MetaMaskContextProvider } from "./utility/hooks/useMetaMask";
import { WalletContextProvider } from "./utility/hooks/useWalletConnection";
import App from "./App";
//css
import "overlayscrollbars/overlayscrollbars.css";

createAppKit({
  adapters: [ethersAdapter],
  networks,
  metadata,
  projectId,
  themeMode: "dark",
  features: {
    analytics: true, // Optional - defaults to your Cloud configuration
    socials: false,
    email: false,
    legalCheckbox: true,
    swaps: false,
    send: false,
    onramp: false
  },
  themeVariables: {
    "--w3m-accent": "#369",
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <WalletContextProvider>
        <App />
      </WalletContextProvider>
    </Provider>
  </BrowserRouter>
);
