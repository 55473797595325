import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Carousel from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useWalletConnection } from "../../utility/hooks/useWalletConnection";
import { formatTimestampToAMPM } from "../../utility/Utils";
import HeadLine from "../../components/HeadLine/HeadLine";
import BottomLine from "../../components/BottomLine/BottomLine";
import {
  getGameTypes,
  getCreatedGames,
  getReadyGames,
  getStartedGames,
  getInfo,
  getReadyStartedGames,
} from "../store";
//images
import NextArrowImg from "../../assets/img/next.png";
import PrevArrowImg from "../../assets/img/prev.png";
import cardBgImg from "../../assets/img/card-bg.png";
//css
import "./index.css";

const activeTimeBeetween = () => {
  const nowtimestamp = Date.parse(Date()) / 1000;
  return formatTimestampToAMPM(nowtimestamp).initialSlideIndex;
};

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <img className={className} src={NextArrowImg} onClick={onClick} alt="" />
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <img className={className} src={PrevArrowImg} onClick={onClick} alt="" />
  );
}

const Lobby = () => {
  // console.log(">>> Lobby rendered.");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiCurrentUser = useSelector((state) => state.rootStore.apiCurrentUser);

  const {
    isConnected,
    currentChain,
    connectedWalletInfo,
    address,
    chainId,
    wallet,
    signer,
    unSigner,
    contractAbi,
    error,
    errorMessage,
    clearError,
    open,
    connect,
    disConnect,
    fragments,
    getFragment,
    toastArgs,
    setToastArgs,
  } = useWalletConnection();
  const [activeGames, setActiveGames] = useState([]);
  const [jonedGameStatus, setJonedGameStatus] = useState(false);

  const handleClickRoom = async (room) => {
    if (wallet.accounts.length > 0) {
      if (!signer.isHost && apiCurrentUser.status !== 200)
        window.location.href = "/";

      if (Number(room.activeGameCount) > 0 || jonedGameStatus) {
        window.location.href = `join?room=${room.id}&start=${room.startTime}&end=${room.endTime}&ap=${room.timeZone}`;
        // navigate({
        //   pathname: "join",
        //   search: `?room=${room.id}&start=${room.startTime}&end=${room.endTime}&ap=${room.timeZone}`,
        // });
      }
    } else {
      connect();
    }
  };

  const setRooms = async (createdGamesList) => {
    // dispatch(getCreatedGames({ dispatch, unSigner, currentChain, contractAbi }))
    //   .then((result) => {
    //     // console.log("setRooms:", result.payload);
    //     //TODO: öğlen 12 lerde hata var 9-11 pm aktif geliyor. (mobilede)
        let rooms = [
          {
            id: 0,
            activeGameCount: 0,
            title: "12 - 2 AM",
            startTime: 0, //TODO: 12 mi 0 mı karışıyor bakılacak.
            endTime: 2,
            timeZone: "am",
          },
          {
            id: 1,
            activeGameCount: 0,
            title: "3 - 5 AM",
            startTime: 3,
            endTime: 5,
            timeZone: "am",
          },
          {
            id: 2,
            activeGameCount: 0,
            title: "6 - 8 AM",
            startTime: 6,
            endTime: 8,
            timeZone: "am",
          },
          {
            id: 3,
            activeGameCount: 0,
            title: "9 - 11 AM",
            startTime: 9,
            endTime: 11,
            timeZone: "am",
          },
          {
            id: 4,
            activeGameCount: 0,
            title: "12 - 2 PM",
            startTime: 0, //TODO: 12 mi 0 mı karışıyor bakılacak.
            endTime: 2,
            timeZone: "pm",
          },
          {
            id: 5,
            activeGameCount: 0,
            title: "3 - 5 PM",
            startTime: 3,
            endTime: 5,
            timeZone: "pm",
          },
          {
            id: 6,
            activeGameCount: 0,
            title: "6 - 8 PM",
            startTime: 6,
            endTime: 8,
            timeZone: "pm",
          },
          {
            id: 7,
            activeGameCount: 0,
            title: "9 - 11 PM",
            startTime: 9,
            endTime: 11,
            timeZone: "pm",
          },
        ];
        // result.payload.createdGames.forEach((game) => {
        createdGamesList.forEach((game) => {
          const gameSlideIndex = formatTimestampToAMPM(
            game.startDate
          ).initialSlideIndex;
          rooms[gameSlideIndex].activeGameCount += 1;
        });
        console.log(rooms);
        setActiveGames(rooms);
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
  };

  // // Set Rooms
  // useEffect(() => {
  //   document.title = "Lobby - Jammy";
  //   if (unSigner.contract && activeGames.length === 0) {
  //     setRooms();
  //     // console.log("allCards:", unSigner.contract.getCards());
  //   }
  // }, [unSigner.contract, activeGames]);

  // Set GameTypes & alert joined
  useEffect(() => {
    //TODO: burdaki değişiklik cüzdan bağlı değilken aktive game göstermesine engel oluyor.
    if (!unSigner.contract) return;
    if (!wallet.accounts.length > 0) return;
    if (!currentChain) return;
    if (activeGames.length !== 0) return;

    setJonedGameStatus(false);
    if (
      !sessionStorage.getItem(
        `${currentChain.contracts.jammy.address}:${currentChain.id}:gameTypes`
      )
    ) {
      dispatch(getGameTypes({ dispatch, currentChain }));
    }

    // dispatch(
    //   getStartedGames({
    //     dispatch,
    //     unSigner,
    //     user: wallet.accounts[0].toLowerCase(),
    //     currentChain
    //   })
    // )
    //   .then((result) => {
    //     if (result.payload) {
    //       console.log("getStartedGames:", result.payload.startedGames);
    //       result.payload.startedGames.forEach(async (game) => {
    //         if (game.isJoined) {
    //           setJonedGameStatus(true);
    //         }
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // dispatch(
    //   getReadyGames({
    //     dispatch,
    //     unSigner,
    //     user: wallet.accounts[0].toLowerCase(),
    //     currentChain
    //   })
    // )
    //   .then((result) => {
    //     if (result.payload) {
    //       console.log("getReadyGames:", result.payload.readyGames);
    //       result.payload.readyGames.forEach(async (game) => {
    //         if (game.isJoined) {
    //           setJonedGameStatus(true);
    //         }
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    dispatch(
      getReadyStartedGames({
        dispatch,
        unSigner,
        user: wallet.accounts[0].toLowerCase(),
        currentChain,
        contractAbi,
      })
    )
      .then((result) => {
        if (result.payload) {
          console.log("getReadyStartedGames:", result.payload);
          result.payload.startedGames.forEach(async (game) => {
            if (game.isJoined) {
              setJonedGameStatus(true);
            }
          });
          result.payload.readyGames.forEach(async (game) => {
            if (game.isJoined) {
              setJonedGameStatus(true);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch(
      getCreatedGames({
        dispatch,
        unSigner,
        user: wallet.accounts[0].toLowerCase(),
        currentChain,
        contractAbi,
      })
    )
      .then((result) => {
        if (result.payload) {
          console.log("getCreatedGames:", result.payload.createdGames);
          if (activeGames.length === 0) {
            setRooms(result.payload.createdGames);
          }
          
          result.payload.createdGames.forEach(async (game) => {
            if (game.isJoined) {
              setJonedGameStatus(true);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [unSigner.contract, wallet.accounts, currentChain, activeGames]);

  //Event listeners
  useEffect(() => {
    if (!unSigner.contract) return;
    if (!wallet.accounts.length > 0) return;
    if (!currentChain) return;

    const listenerCardsAdded = () => {
      console.log("#CardsAdded (lobby) event was emmited");
      dispatch(getInfo({ unSigner }));
    };
    const listenerCardsUpdated = () => {
      console.log("#CardsUpdated (lobby) event was emmited");
      dispatch(getInfo({ unSigner }));
    };
    const listenerGameCreated = () => {
      console.log("#GameCreated (lobby) event was emmited");
      dispatch(
        getCreatedGames({
          dispatch,
          unSigner,
          user: wallet.accounts[0].toLowerCase(),
          currentChain,
          contractAbi,
        })
      )
        .then((result) => {
          if (result.payload) {
            console.log("event-getCreatedGames:", result.payload.createdGames);
            setRooms(result.payload.createdGames);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const listenerGameCancelled = () => {
      console.log("#GameCancelled (lobby) event was emmited");
      dispatch(
        getCreatedGames({
          dispatch,
          unSigner,
          user: wallet.accounts[0].toLowerCase(),
          currentChain,
          contractAbi,
        })
      )
        .then((result) => {
          if (result.payload) {
            console.log("event-getCreatedGames:", result.payload.createdGames);
            setRooms(result.payload.createdGames);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    unSigner.contract?.on("CardsAdded", listenerCardsAdded);
    unSigner.contract?.on("CardsUpdated", listenerCardsUpdated);
    unSigner.contract?.on("GameCreated", listenerGameCreated);
    unSigner.contract?.on("GameCancelled", listenerGameCancelled);
    console.log("+>>> On useEffect (lobby event listeners)");
    return () => {
      unSigner.contract?.off("CardsAdded", listenerCardsAdded);
      unSigner.contract?.off("CardsUpdated", listenerCardsUpdated);
      unSigner.contract?.off("GameCreated", listenerGameCreated);
      unSigner.contract?.off("GameCancelled", listenerGameCancelled);
      console.log("->>> returned useEffect (lobby event listeners)");
    };
  }, [unSigner.contract, wallet.accounts, currentChain]);

  // //Event listeners (method 2)
  // useEffect(() => {
  //   if (!unSigner.provider) return;
  //   if (!unSigner.contract) return;

  //   const filters = [
  //     "CardsAdded",
  //     "CardsUpdated",
  //     "GameCreated",
  //     "GameCancelled",
  //   ];

  //   const listenerEvents = async (blockNumber) => {
  //     // console.log("#Mined new block.", blockNumber);

  //     const emitedEvents = await unSigner.contract?.queryFilter(
  //       [filters],
  //       blockNumber - 1, //fromBlock
  //       blockNumber //toBlock
  //     );

  //     if (emitedEvents.length > 0) {
  //       emitedEvents.forEach((event) => {
  //         if (Number(event.blockNumber) !== blockNumber) return;

  //         if (event.eventName === "CardsAdded") {
  //           console.log("#CardsAdded (lobby) event was emmited.", blockNumber);
  //           //args: amount, newCount

  //           // dispatch(setAllCards({ dispatch, unSigner }));
  //           dispatch(getInfo({ unSigner }));
  //         }
  //         if (event.eventName === "CardsUpdated") {
  //           console.log("#CardsUpdated (lobby) event was emmited.", blockNumber);
  //           //args: amount

  //           // dispatch(setAllCards({ dispatch, unSigner }));
  //           dispatch(getInfo({ unSigner }));
  //         }
  //         if (event.eventName === "GameCreated") {
  //           console.log("#GameCreated (lobby) event was emmited.", blockNumber);
  //           //args: host, gameId

  //           setRooms();
  //         }
  //         if (event.eventName === "GameCancelled") {
  //           console.log("#GameCancelled (lobby) event was emmited.", blockNumber);
  //           //args: gameId

  //           setRooms();
  //         }
  //       });
  //       console.log(emitedEvents);
  //     }
  //   };

  //   unSigner.provider?.on("block", listenerEvents);
  //   console.log(">>> On useEffect (lobby event listeners)");
  //   return () => {
  //     unSigner.provider?.off("block", listenerEvents);
  //     console.log(">>> returned useEffect (lobby event listeners)");
  //   };
  // }, [unSigner.provider, unSigner.contract]);

  return (
    <>
      <HeadLine />

      <div className="lobby-carousel-area">
        {jonedGameStatus && (
          <div className="isJoined">Joined Game Available</div>
        )}

        <Carousel
          centerMode={true}
          initialSlide={activeTimeBeetween()}
          infinite={true}
          slidesToShow={3} // Default olarak masaüstünde 3 göster
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          responsive={[
            {
              breakpoint: 768, // Mobil ekran boyutunda
              settings: {
                slidesToShow: 1, // Mobilde tek slide göster
              },
            },
          ]}
        >
          {activeGames &&
            activeGames.map((item, index) => (
              <div className="item" key={index}>
                <span className="time">{item.title}</span>
                <span className="game-number">
                  {item.activeGameCount} Active Games
                </span>
                <img
                  onClick={() => handleClickRoom(item)}
                  src={cardBgImg}
                  alt=""
                />
              </div>
            ))}
        </Carousel>
      </div>

      <BottomLine />
    </>
  );
};

export default Lobby;
