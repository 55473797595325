import React, { useEffect, useRef, useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { formatEther, getBigInt, parseEther } from "ethers";
import { Link, useLocation } from "react-router-dom";
import CloseButton from "../CloseButton/CloseButton";
import { useDispatch } from "react-redux";
import DateTimePicker from "react-datetime-picker";
import niceSelect from "../../utility/niceSelect/niceSelectModule";
import {
  createGameTimes,
  getCreatedGames,
  getReadyStartedGames,
  getReadyGames,
  getStartedGames,
  getStorage,
  getTxFee,
} from "../../pages/store";
import { useWalletConnection } from "../../utility/hooks/useWalletConnection";
//css
import "./HostPanel.css";
import "react-datetime-picker/dist/DateTimePicker.css";
//images
import hostPanelBg from "../../assets/img/hostpanel-bg.png";

const HostPanel = (props) => {
  // console.log(">>> HostPanel rendered.");

  const { search } = useLocation();
  const dispatch = useDispatch();
  const {
    isConnected,
    currentChain,
    connectedWalletInfo,
    address,
    chainId,
    wallet,
    signer,
    unSigner,
    contractAbi,
    error,
    errorMessage,
    clearError,
    open,
    connect,
    disConnect,
    fragments,
    getFragment,
    toastArgs,
    setToastArgs,
  } = useWalletConnection();

  const [hostCreatedGames, setHostCreatedGames] = useState([]);
  const [hostReadyGames, setHostReadyGames] = useState([]);
  const [hostStartedGames, setHostStartedGames] = useState([]);
  const [createTimes, setCreateTimes] = useState([]);
  const [gameTypes, setGameTypes] = useState([]);
  const [dateTimePickerValue, setDateTimePickerValue] = useState(new Date());

  const createGameAPTimeRef = useRef();
  const createGameCardPriceRef = useRef();
  const initialButtons = {
    create: false,
    cancel: false,
    start: false,
  };
  const [buttonsLoading, setButtonsLoading] = useState(initialButtons);

  const createGame = async () => {
    // console.log(`selected card price: ${parseEther(createGameCardPriceRef.current.value)} (${createGameCardPriceRef.current.value})`);
    setButtonsLoading({ create: true, cancel: false, start: false });
    try {
      //TODO: Geçici kapatıldı
      // const selectedTime = createGameAPTimeRef.current.value;
      // const hoursminutes = selectedTime.split("-")[0];
      // const ap = selectedTime.split("-")[1];
      // const today = new Date().toLocaleDateString();

      // const timestamp =
      //   Date.parse(
      //     `${today.split(".")[1]}/${today.split(".")[0]}/${
      //       today.split(".")[2]
      //     } ${hoursminutes}:00 ${ap}`,
      //     `yyy-dd-mm HH:mm:ss`
      //   ) / 1000;

      // console.log({ selectedTime, hoursminutes, ap, today, timestamp });

      const dtPicker =
        dateTimePickerValue && Math.floor(dateTimePickerValue.getTime() / 1000);
      // console.log({dateTimePickerValue, dtPicker});
      if (!dtPicker) {
        setToastArgs({
          msgType: "error",
          msg: "Please select a valid date",
          isShow: true,
        });
        setButtonsLoading(initialButtons);
        return;
      }

      if (dtPicker < Math.floor(new Date().getTime() / 1000) + 5 * 60) {
        setToastArgs({
          msgType: "error",
          msg: "İleri tarihli (en az +5 dakika) oyun oluşturulabilir",
          isShow: true,
        });
        setButtonsLoading(initialButtons);
        return;
      }

      const newGame = {
        startDate: dtPicker,
        maxCardsPerPlayer: 4,
        cardPrice: parseEther(createGameCardPriceRef.current.value),
        houseShare: 1000, // 10%
        prizes: [
          4000, // 40% (0. index > jammy)
          2000, // 20% (1. index > jams4)
          1500, // 15% (2. index > jams3)
          1000, // 10% (3. index > jams2)
          500, // 5% (4. index > jams1)
        ],
      };
      console.log("newGame:", newGame);

      const gasLimitFunction = signer.contract.createGame.estimateGas(
        newGame.startDate,
        newGame.maxCardsPerPlayer,
        newGame.cardPrice,
        newGame.houseShare,
        newGame.prizes
      );

      const _getTxFee = await dispatch(
        getTxFee({
          unSigner,
          currentChain,
          wallet,
          gasLimitFunction,
        })
      ).then((result) => {
        console.log("getTxFee:", result.payload);
        return result.payload;
      });

      if (_getTxFee.errors !== null) {
        setToastArgs({
          msgType: "error",
          msg: _getTxFee.errors,
          isShow: true,
        });
        setButtonsLoading(initialButtons);
        return;
      }

      const tx = await signer.contract.createGame(
        newGame.startDate,
        newGame.maxCardsPerPlayer,
        newGame.cardPrice,
        newGame.houseShare,
        newGame.prizes
      );
      const receipt = await tx.wait();
      console.log("create-receipt:", receipt);

      if (
        receipt.logs.find((eventLog) => eventLog.eventName === "GameCreated")
      ) {
        wallet.accounts[0] &&
          dispatch(
            getCreatedGames({
              dispatch,
              unSigner,
              host: wallet.accounts[0].toLowerCase(),
              currentChain,
            })
          )
            .then((result) => {
              if (result.payload) {
                setHostCreatedGames(result.payload.createdGames);
              }
            })
            .catch((error) => {
              console.log(error);
            });
      }
      setButtonsLoading(initialButtons);
    } catch (error) {
      setToastArgs({
        msgType: "error",
        msg: error,
        isShow: true,
      });
      setButtonsLoading(initialButtons);
    }
  };

  const cancelGame = async (cancelledgameId) => {
    setButtonsLoading({ create: false, cancel: true, start: false });
    try {
      const gasLimitFunction = signer.contract.cancelGame.estimateGas(cancelledgameId);

      const _getTxFee = await dispatch(
        getTxFee({
          unSigner,
          currentChain,
          wallet,
          gasLimitFunction,
        })
      ).then((result) => {
        console.log("getTxFee:", result.payload);
        return result.payload;
      });

      if (_getTxFee.errors !== null) {
        setToastArgs({
          msgType: "error",
          msg: _getTxFee.errors,
          isShow: true,
        });
        setButtonsLoading(initialButtons);
        return;
      }

      const tx = await signer.contract.cancelGame(cancelledgameId);
      const receipt = await tx.wait();
      console.log("cancel-receipt:", receipt);

      if (
        receipt.logs.find((eventLog) => eventLog.eventName === "GameCancelled")
      ) {
        wallet.accounts[0] &&
          (await dispatch(
            getCreatedGames({
              dispatch,
              unSigner,
              host: wallet.accounts[0].toLowerCase(),
              currentChain,
            })
          )
            .then((result) => {
              if (result.payload) {
                setHostCreatedGames(result.payload.createdGames);
              }
            })
            .catch((error) => {
              console.log(error);
            }));
      }
      setButtonsLoading(initialButtons);
    } catch (error) {
      setToastArgs({
        msgType: "error",
        msg: error,
        isShow: true,
      });
      setButtonsLoading(initialButtons);
    }
  };

  useEffect(() => {
    if (props.show) {
      dispatch(getStorage({ key: `gameTypes`, type: "session", currentChain }))
        .then((result) => {
          if (result.payload && gameTypes.length === 0) {
            setGameTypes(result.payload);
            return true;
          } else {
            return false;
          }
        })
        .then((result) => {
          if (!result) {
            niceSelect(createGameCardPriceRef);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.show, gameTypes]);

  useEffect(() => {
    if (props.show) {
      dispatch(createGameTimes())
        .then((result) => {
          if (createTimes.length === 0) {
            setCreateTimes(result.payload);
            return true;
          } else {
            return false;
          }
        })
        .then((result) => {
          if (result) {
            //TODO: Geçici kapatıldı
            // niceSelect(createGameAPTimeRef);
          }
        })
        .catch((error) => console.log(error));

      if (unSigner.contract && wallet.accounts.length > 0) {
        dispatch(
          getCreatedGames({
            dispatch,
            unSigner,
            host: wallet.accounts[0].toLowerCase(),
            currentChain,
            contractAbi,
          })
        )
          .then((result) => {
            if (result.payload) {
              setHostCreatedGames(result.payload.createdGames);
            }
          })
          .catch((error) => console.log(error));

        dispatch(
          getReadyStartedGames({
            dispatch,
            unSigner,
            host: wallet.accounts[0].toLowerCase(),
            currentChain,
            contractAbi,
          })
        )
          .then((result) => {
            if (result.payload) {
              console.log(result.payload);
              setHostReadyGames(result.payload.readyGames);
              setHostStartedGames(result.payload.startedGames);
            }
          })
          .catch((error) => console.log(error));

        // dispatch(
        //   getReadyGames({
        //     dispatch,
        //     unSigner,
        //     host: wallet.accounts[0].toLowerCase(),
        //     currentChain
        //   })
        // )
        //   .then((result) => {
        //     if (result.payload) {
        //       setHostReadyGames(result.payload.readyGames);
        //     }
        //   })
        //   .catch((error) => console.log(error));

        // dispatch(
        //   getStartedGames({
        //     dispatch,
        //     unSigner,
        //     host: wallet.accounts[0].toLowerCase(),
        //     currentChain
        //   })
        // )
        //   .then((result) => {
        //     if (result.payload) {
        //       setHostStartedGames(result.payload.startedGames);
        //     }
        //   })
        //   .catch((error) => console.log(error));
      } else {
        connect();
      }
    }

    if (!props.show) {
      setCreateTimes([]);
    }
  }, [props.show, unSigner.contract, wallet.accounts, gameTypes]);

  return (
    <>
      {props.show && (
        <div className="popup-host-panel popup">
          <CloseButton onClose={props.onClose} to={search} />
          <div className="in">
            <img src={hostPanelBg} className="w-100" alt="" />
            <div className="box-in">
              <div className="left-colm">
                <h3>Create a New Game</h3>
                <h4>Create a New Game</h4>
                <p>
                  Specify the time <br />
                  you want the game to start.
                </p>
                <div className="selects-area">
                  <div style={{ marginBottom: "20%" }}>
                    <DateTimePicker
                      onChange={setDateTimePickerValue}
                      value={dateTimePickerValue}
                      minDate={new Date()}
                    />
                  </div>
                  {/* TODO: Geçici kapatıldı */}
                  {/* <select ref={createGameAPTimeRef} className="nice">
                    {createTimes.length > 0 &&
                      createTimes.map((time, index) => (
                        <option value={time.replace(" ", "-")} key={index}>
                          {time}
                        </option>
                      ))}
                  </select> */}

                  <select ref={createGameCardPriceRef} className="nice">
                    {gameTypes.map((gametype, index) => {
                      return (
                        <option key={index} value={gametype.price}>
                          {gametype.name}
                        </option>
                      );
                    })}
                  </select>

                  <div className="btns-bottom justify-content-start">
                    <Link
                      onClick={() => createGame()}
                      className={
                        !buttonsLoading.create
                          ? "btn-sub"
                          : "btn-sub btnloading"
                      }
                      style={{ marginLeft: 0 }}
                    >
                      Create
                    </Link>
                  </div>
                </div>
              </div>
              <div className="right-colm ">
                <h3>
                  Game Schedule <span>Game Schedule</span>
                </h3>
                <div className="right-in ">
                  <OverlayScrollbarsComponent
                    className="item-list-wrapper scrollbarCustom content"
                    options={{ scrollbars: { theme: "os-theme-dark" } }}
                  >
                    {hostCreatedGames.length > 0 &&
                      hostCreatedGames.map((gameItems, index) => (
                        <div key={index} className="item-row">
                          <div className="time">
                            {gameItems.startDateAMPM.split("-")[0]}:
                            {gameItems.startDateAMPM.split("-")[1]}{" "}
                            {gameItems.startDateAMPM.split("-")[2]}
                          </div>
                          <div className="name">{gameItems.gameType?.name}</div>
                          <div className="btns-area">
                            <Link
                              href="#"
                              className={
                                !buttonsLoading.cancel
                                  ? "cancel"
                                  : "cancel btnloading"
                              }
                              onClick={() => cancelGame(gameItems.gameId)}
                            >
                              Cancel
                            </Link>
                            <Link
                              to={`/game?gameId=${gameItems.gameId}`}
                              className="start"
                            >
                              Go
                            </Link>
                          </div>
                        </div>
                      ))}

                    {hostReadyGames.length > 0 &&
                      hostReadyGames.map((gameItems, index) => (
                        <div key={index} className="item-row">
                          <div className="time">
                            {gameItems.startDateAMPM.split("-")[0]}:
                            {gameItems.startDateAMPM.split("-")[1]}{" "}
                            {gameItems.startDateAMPM.split("-")[2]}
                          </div>
                          <div className="name">{gameItems.gameType?.name}</div>
                          <div className="btns-area">
                            <Link
                              to={`/game?gameId=${gameItems.gameId}`}
                              className="start"
                            >
                              Start
                            </Link>
                          </div>
                        </div>
                      ))}

                    {hostStartedGames.length > 0 &&
                      hostStartedGames.map((gameItems, index) => (
                        <div key={index} className="item-row">
                          <div className="time">
                            {gameItems.startDateAMPM.split("-")[0]}:
                            {gameItems.startDateAMPM.split("-")[1]}{" "}
                            {gameItems.startDateAMPM.split("-")[2]}
                          </div>
                          <div className="name">{gameItems.gameType?.name}</div>
                          <div className="btns-area">
                            <Link
                              to={`/game?gameId=${gameItems.gameId}`}
                              className="start"
                            >
                              Continue
                            </Link>
                          </div>
                        </div>
                      ))}
                  </OverlayScrollbarsComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HostPanel;
